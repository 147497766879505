import moment from "moment";
import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import ConnectWallet from "../ConnectWallet";
import { useParams } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import { toFixed } from "../../store/numberFormat";
import React, { useState, useEffect } from "react";
import { validate } from "wallet-address-validator";
import modalcloseicon from "../../images/close.png";
import uploadicon from "../../images/uploadicon.png";
import infocircle from "../../images/infocircle.svg";
import daogenesisicon from "../../images/dao-genesis.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import votedfillgreen from "../../images/voted-fill-green.png";
import rejectedfillred from "../../images/rejected-fill-red.png";
import microeconomyicon from "../../images/microeconomy-icon.png";
import { networkId, CiFiDAOABI, CiFiDAOAddress, CIFI } from "../../store/config";
import { web3 } from '../../store/web3';
import { setLoader, getCiFiProposals, addCiFiProposal, getCiFiDAO, setBalance, setGrootBalance, sendEmailCIFIDao } from "../../store/actions/Auth";


const CircularityDao = (props, { mode }) => {

  let [dEnd, setdEnd] = useState("");
  let [daoData, setDaoData] = useState({});
  let [daoAddress, setDaoAddress] = useState(CiFiDAOAddress);
  let [proposalObj, setProposalObj] = useState({});
  let [proposalInfo, setProposalInfo] = useState({});
  let [proposalTitle, setproposalTitle] = useState("");
  let [proposalDetails, setproposalDetails] = useState([]);
  let [proposalDocument, setproposalDocument] = useState("");
  let [proposalDescription, setproposalDescription] = useState("");
  let [createProposalModal, setCreateProposalModal] = useState(false);
  let [executeProposalModal, setExecuteProposalModal] = useState(false);
  let [proposalInfoModal, setProposalInfoModal] = useState(false);
  let [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));
  let [modalData, setModalData] = useState({});

  const [model, setModel] = useState(true);

  // let { daoAddress } = useParams();

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  useEffect(() => {
    if(daoAddress){
      props.getCiFiDAO();
      props.getCiFiProposals({ daoAddress: daoAddress });
    }
  }, [daoAddress]);

  useEffect(()=>{
    if(CiFiDAOAddress && web3){
      setDaoAddress(CiFiDAOAddress)
    }
  },[CiFiDAOAddress,web3,networkId]);

  useEffect(() => {
    // Update timestamp every second
    const intervalId = setInterval(() => {
      setCurrentTime(Math.floor(Date.now() / 1000));
    }, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  async function setDaoList() {
    const filtered = await Promise.all(
      props.daoListCIFI.filter(item => item['daoAddress'].toLowerCase() == daoAddress.toLowerCase()).map(async (item) => {
        return item;
      })
    );
    if (filtered.length > 0) {
      let stakeholders = filtered[0]['stakeholders'].map(item => item.toLowerCase());
      let contract = new web3.eth.Contract(CiFiDAOABI, daoAddress);
      let onlyOwner = await contract.methods.owner().call({ from: props.publicAddress });
      setDaoData({ ...filtered[0], stakeholders, onlyOwner });
    }
  }

  useEffect(() => {
    if (props.publicAddress == null || props.publicAddress == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    } else {
      if (props.daoListCIFI.length > 0 && web3) {
        setDaoList();
      }
    }
  }, [props.daoListCIFI, props.selectDefaultNetwok, web3]);

  useEffect(() => {
    if (props.publicAddress  && web3) {
      proposalsPopulate();
    } else {
      return;
    }
  }, [web3]);

  useEffect(() => {
    if (props.publicAddress && web3 && props.proposalListCIFI) {
      proposalsPopulate();
    } else {
      return;
    }
  }, [props.proposalListCIFI, props.publicAddress, web3]);

  async function proposalsPopulate() {
    let { proposalListCIFI } = props;
    let contract = new web3.eth.Contract(CiFiDAOABI, daoAddress);
    let result1 = await contract.methods.numProposals().call({ from: props.publicAddress });
    if (parseInt(result1) !== 0) {
      for (let i = 0; i < result1; i++) {
        let result2 = await contract.methods.proposals(i).call({ from: props.publicAddress });
        const updatedCollections = await Promise.all(
          proposalListCIFI.filter(item => item['proposalTitle'].toLowerCase() == result2.title.toLowerCase()).map(async (item) => {
            return {...item, ...result2};
          })
        );
        setproposalDetails(updatedCollections);
      }
    }
  }

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "proposalTitle") {
      setproposalTitle(e.target.value);
    } else if ([e.target.name] == "proposalDescription") {
      setproposalDescription(e.target.value);
    } else if ([e.target.name] == "proposalDocument") {
      setproposalDocument(e.target.files[0]);
    } else if ([e.target.name] == "dEnd") {
      setdEnd(e.target.value);
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function createProposal(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (proposalTitle == "") {
        EventBus.publish("error", `Please enter proposal title`);
        return;
      }

      if (!proposalTitle.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter proposal title`);
        return;
      }

      if (!proposalTitle.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Proposal title must contain alphabets`);
        return;
      }

      if (proposalDescription == "") {
        EventBus.publish("error", `Please enter proposal description`);
        return;
      }

      if (!proposalDescription.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter proposal description`);
        return;
      }

      if (!proposalDescription.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Proposal description must contain alphabets`);
        return;
      }

      if (proposalDocument == undefined) {
        EventBus.publish("error", `Please upload proposal document again or refresh the page`);
        return;
      }

      if (dEnd == "") {
        EventBus.publish("error", `Please enter proposal deadline`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let contract = new web3.eth.Contract(CiFiDAOABI, daoAddress);
      let deadline = parseInt((new Date(dEnd).getTime()) / 1000);
      console.log("****************** contract",contract)
      console.log("****************** daoAddress",daoAddress)
      console.log("****************** proposalTitle",proposalTitle)
      console.log("****************** CiFiDAOABI",CiFiDAOABI)
      console.log("****************** proposalDescription",proposalDescription)
      console.log("****************** deadline",deadline)

      props.setLoader({
        message: "Adding Proposal...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: daoAddress,
          gas: 5000000,
          data: contract.methods
            .createProposal(proposalTitle, proposalDescription, deadline)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {

          let data = new FormData();

          data.append('proposalTitle', proposalTitle);
          data.append('proposalDescription', proposalDescription);
          data.append('deadline', deadline);
          data.append('proposalDocument', proposalDocument);
          data.append('daoAddress', daoAddress);

          await props.addCiFiProposal(data);

          props.sendEmailCIFIDao({
            daoAddress: daoAddress, emailSubject: `New Proposal Created in ${daoData['title']}`, proposalTitle: "Dear User",
            proposalDescription: `A new proposal ${proposalTitle} has been created within ${daoData['title']}. Proposal details are as following:<br><br/>
            Title: ${proposalTitle}<br><br/>Description: ${proposalDescription}<br><br/>We encourage your active participation in the decision-making process.<br><br/>
            Please head to the DAO platform to review the details and cast your vote. Your input is instrumental in shaping the direction of DAO, and we value your engagement.` });

          refreshBalance();
          props.getCiFiProposals({ daoAddress: daoAddress });
          proposalsPopulate();
          setDaoList();
          props.setLoader({ status: false });

          EventBus.publish("success", `Proposal Created Successfully!`);
          await waitFor(2500);
          setdEnd("");
          setproposalTitle("");
          setproposalDocument("");
          setproposalDescription("");
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To add proposal`);
    }
  }

  async function vote(index) {
    try {

      let deployer = (await web3.currentProvider.enable())[0];

      let contract = new web3.eth.Contract(CiFiDAOABI, daoAddress);

      props.setLoader({
        message: "Voting...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: daoAddress,
          gas: 5000000,
          data: contract.methods
            .voteOnProposal(index, true)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({ status: false });
          proposalsPopulate();
          EventBus.publish("success", `Voted Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Could not vote...",
        status: false,
      });
      EventBus.publish("error", `Could not vote`);
    }
  }

  async function against(index) {
    try {

      let deployer = (await web3.currentProvider.enable())[0];

      let contract = new web3.eth.Contract(CiFiDAOABI, daoAddress);

      props.setLoader({
        message: "Voting...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: daoAddress,
          gas: 5000000,
          data: contract.methods
            .voteOnProposal(index, false)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({ status: false });
          proposalsPopulate();
          EventBus.publish("success", `Voted Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Could not vote...",
        status: false,
      });
      EventBus.publish("error", `Could not vote`);
    }
  }

  async function execute(index) {
    try {

      await setDaoList();

      props.setLoader({
        message: "Loading...",
        status: true,
      });

      setProposalObj({ ...props.proposalListCIFI[index], ...proposalDetails[index] });
      setModalData({ ...props.proposalListCIFI[index], ...proposalDetails[index] });

      props.setLoader({
        message: "Loading...",
        status: false,
      });

      setExecuteProposalModal(true);

    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Loading...",
        status: false,
      });
      EventBus.publish("error", `Could not execute`);
    }
  }

  async function executeProposal() {
    try {

      let deployer = (await web3.currentProvider.enable())[0];

      props.setLoader({
        message: "Executing...",
        status: true,
      });

      let contract1 = new web3.eth.Contract(CiFiDAOABI, daoAddress);
      let index = parseInt(modalData['id']) - 1;

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: daoAddress,
          gas: 5000000,
          data: contract1.methods
            .executeProposal(index, true)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({ status: false });
          props.sendEmailCIFIDao({
            daoAddress: daoAddress, emailSubject: `Congratulations! Proposal Executed Successfully`, proposalTitle: "Dear User",
            proposalDescription: `We're pleased to inform you that the recent proposal ${proposalObj['proposalTitle']} in your DAO has been successfully 
              executed. Your involvement and contribution played a significant role in shaping the outcome.<br><br/>Thank you for actively participating in 
              the governance of DAO. We appreciate your ongoing dedication to our community.` });
          proposalsPopulate()
          setExecuteProposalModal(false);
          EventBus.publish("success", `Executed Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Loading...",
        status: false,
      });
      EventBus.publish("error", `Could not execute`);
    }
  }

  async function rejectProposal() {
    try {

      let deployer = (await web3.currentProvider.enable())[0];

      props.setLoader({
        message: "Transfering Funds...",
        status: true,
      });

      props.setLoader({
        message: "Executing...",
        status: true,
      });

      let contract1 = new web3.eth.Contract(CiFiDAOABI, daoAddress);
      let index = parseInt(modalData['id']) - 1;

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: daoAddress,
          gas: 5000000,
          data: contract1.methods
            .executeProposal(index, true)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({ status: false });
          proposalsPopulate();
          setExecuteProposalModal(false);
          EventBus.publish("success", `Executed Successfully!`);
        });

    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Loading...",
        status: false,
      });
      EventBus.publish("error", `Could not execute`);
    }
  }

  async function callFilter(value) {
    let filterValue = props.proposalListCIFI.filter((a) => {
      if (value) {
        return a.proposalTitle?.toString().includes(value)
      }
      else {
        return a
      }
    });
    setproposalDetails(filterValue);
  }

  async function proposalInformation(item) {
    setProposalInfo(item);
    setProposalInfoModal(true);
  }
  

  return (
    <div className="microeconomy-wrapper dao-genesis view-all submit-proposal circularity-dao">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={daogenesisicon} alt="" />
          <span>Circularity DAO</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {(dEnd !== "" || proposalTitle !== "" || proposalDescription !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="microeconomy-tool">
        <h2>Search Proposals</h2>

        <div className="detail-row">
          <div className="filters">
            <div className="search-field">
              <input type="search" placeholder="Type proposal title..." onChange={e => callFilter(e.target.value)} />
            </div>
            <div className="btns">
              <button onClick={() => setCreateProposalModal(true)}>Create a proposal</button>
            </div>
          </div>

          <h2>All Proposals</h2>

          <div className="table-wrapper proposals-table">
            <table responsive="md">
              <thead>
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Expiry date</th>
                  <th>Status</th>
                  <th>Vote count</th>
                  <th>Vote/Reject</th>
                  {
                    Object.keys(daoData).length > 0 && daoData['stakeholders'] && daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) ?
                      <th>Action</th> :
                      <></>
                  }
                </tr>
              </thead>
              <tbody>
                {
                  proposalDetails?.length > 0 &&
                  proposalDetails?.map((item, index) => (
                    <tr>
                      <td><img onClick={() => proposalInformation(item)} className="info-edit" src={infocircle} alt="" /></td>
                      <td>{item?.title ? item?.title : item?.proposalTitle}</td>
                      <td>{moment(Number(item.deadline) * 1000).format('L hh:mm:ss A')}</td>
                      {
                        (currentTime) < parseInt(item?.deadline)
                          ?
                          <td className="status green">Active</td>
                          :
                          <td className="status">Expired</td>
                      }
                      <td className="vote-count">
                        <p>
                          <img src={votedfillgreen} alt="" />
                          {
                            parseInt(item?.voteCount) > 0 ?
                              toFixed(((parseInt(item?.yayVotes) / parseInt(item?.voteCount)) * 100), 'price')
                              : 0
                          }% voted
                        </p>

                        <p>
                          <img src={rejectedfillred} alt="" />
                          {
                            parseInt(item?.voteCount) > 0 ?
                              toFixed(((parseInt(item?.nayVotes) / parseInt(item?.voteCount)) * 100), 'price')
                              : 0
                          }% rejected
                        </p>
                      </td>
                      <td className="vote-reject">
                        <p className="vote">
                          {
                            (currentTime) < parseInt(item?.deadline) ?
                              <button onClick={() => vote(index)}>Vote</button> :
                              <button disabled>Vote</button>
                          }
                        </p>

                        <p className="reject">
                          {
                            (currentTime) < parseInt(item?.deadline) ?
                              <button onClick={() => against(index)}>Reject</button> :
                              <button disabled>Reject</button>
                          }
                        </p>
                      </td>
                      {
                        (currentTime) > parseInt(item?.deadline) && Object.keys(daoData).length > 0 && daoData['stakeholders'] && daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) ?
                          <td className="action">
                            <button onClick={() => execute(index)} className="btns">Execute</button>
                          </td> :
                          <></>
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        open={createProposalModal}
        onClose={() => setCreateProposalModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal create-proposal-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setCreateProposalModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="head">
              <h3>New Proposal
              </h3>
            </div>

            <form onSubmit={createProposal}>
              <div className="form-group">
                <input
                  type="text"
                  name='proposalTitle'
                  value={proposalTitle}
                  onChange={handleOnInput}
                  placeholder="Proposal Title*"
                />
              </div>

              <div className="form-group">
                <textarea
                  as="textarea"
                  rows={6}
                  name='proposalDescription'
                  value={proposalDescription}
                  onChange={handleOnInput}
                  placeholder="Proposal detail"
                />
              </div>

              <div className="form-group filewrap">
                <span>{proposalDocument ? (proposalDocument['name'] && proposalDocument['name'].substring(0, 10) + '...') : "Proposal document"}</span>
                <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload PDF File</div>
                <input
                  type="file"
                  name='proposalDocument'
                  placeholder={proposalDocument ? (proposalDocument['name'] && proposalDocument['name'].substring(0, 10) + '...') : "Upload PDF File*"}
                  accept=".pdf"
                  onChange={handleOnInput}
                />
              </div>

              <div className="form-group date-wrap">
                <label>Expiry Time</label>
                <input
                  type="datetime-local"
                  placeholder="End date"
                  name="dEnd"
                  value={dEnd}
                  onInput={handleOnInput}
                />
              </div>

              <div className="btns dao-btn">
                <button type="submit">Submit Proposal</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={executeProposalModal}
        onClose={() => setExecuteProposalModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal execute-proposal-modal dao`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setExecuteProposalModal(false)}
          />
        </button>

        <div className="modal-body">
          {
            Object.keys(modalData).length > 0 &&
            <div className="form-wrap">
              <div className="head">
                <h3 className="text-center mb-5">{modalData?.['title']}</h3>
                <p className="text-center mb-2">{modalData?.['description']}</p>
              </div>
              <form>
                {
                  modalData['executed'] == false ?
                    parseInt(modalData['yayVotes']) !== 0 || parseInt(modalData['nayVotes']) !== 0 ?
                      daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) && parseInt(modalData['yayVotes']) > parseInt(modalData['nayVotes']) ?
                        <button onClick={(event) => {
                          event.preventDefault();
                          executeProposal();
                        }}>Execute proposal</button>
                        :
                        <button onClick={(event) => {
                          event.preventDefault();
                          rejectProposal();
                        }}>Reject proposal</button>
                      :
                      <>
                        {
                          daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) && modalData['executed'] == false &&
                          <button onClick={(event) => {
                            event.preventDefault();
                            rejectProposal();
                          }}>Conclude proposal</button>
                        }
                        <br />
                        <br />
                        <button disabled>
                          No votes submitted
                        </button>
                      </>
                    :
                    <button disabled>
                      Decision Made
                    </button>
                }
              </form>
            </div>
          }
        </div>
      </Modal>

      <Modal
        open={proposalInfoModal}
        onClose={() => setProposalInfoModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal proposal-info-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setProposalInfoModal(false)}
          />
        </button>

        <div className="modal-body">

          <h3>{proposalInfo['proposalTitle']}</h3>
          <p>{proposalInfo['proposalDescription']}</p>
          {
            proposalInfo['proposalDocument'] && proposalInfo['proposalDocument'] !== "" &&
            <p>
              <a href={proposalInfo['proposalDocument']} target="_blank">View Document</a>
            </p>
          }
          <span>{moment(Number(proposalInfo.deadline) * 1000).format('L hh:mm:ss A')}</span>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  getCiFiProposals,
  addCiFiProposal,
  getCiFiDAO,
  setBalance,
  setGrootBalance,
  sendEmailCIFIDao
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, daoListCIFI, proposalListCIFI, currentMode, isLoader } = Auth;
  return { publicAddress, daoListCIFI, proposalListCIFI, currentMode, isLoader }
};

export default connect(mapStateToProps, mapDispatchToProps)(CircularityDao);