import EventBus from "eventing-bus";
import { connect } from "react-redux";
import "react-responsive-modal/styles.css";
import copy from "../../images/copy.png";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import { Form, Button } from 'react-bootstrap';
import goarrow from "../../images/go-arrow.png";
import toprated from "../../images/top-rated.png";
import React, { useState, useEffect } from "react";
import tickcircle from "../../images/tickcircle.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import alternate from "../../images/alternate.jpg";
import { toFixed } from "../../store/numberFormat";
import modalcloseicon from "../../images/close.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import mintstepinfo from "../../images/mint-step-info.png";
import editicon from "../../images/edit.svg";
import { web3 } from "../../store/web3";
import collectionbackground from "../../images/collection-background.png";
import collectionimagestepinfo from "../../images/collection-image-step-info.png";
import { getCollection, getUriData, setLoader, setBalance, getKycStatus, setGrootBalance, update721Description } from "../../store/actions/Auth"
import { networkId, XRC721ABI, XRC20ABI, CIFI, CiFiAccessNFTAddress, CiFiAccessNFTABI, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";


function ExploreNft(props) {

  let [txHash, setTxHash] = useState("");
  let [nftData, setnftData] = useState([]);
  let [BSymbol, setBSymbol] = useState("");
  let [chain, setChain] = useState("");
  let [description, setdescription] = useState("");
  let [tokenName, settokenName] = useState("");
  let [tokenPrice, settokenPrice] = useState("0");
  let [tokenSupply, settokenSupply] = useState(0);
  let [tokenBanner, settokenBanner] = useState("");
  let [tokenSymbol, settokenSymbol] = useState("");
  let [tokenOwner, settokenOwner] = useState("");
  let [paymentToken, setpaymentToken] = useState("");
  let [paymentTokenAddress, setpaymentTokenAddress] = useState("");
  let [tokenNetwork, settokenNetwork] = useState(51);
  let [createdAt, setcreatedAt] = useState("");
  let [tokenDescription, settokenDescription] = useState("");
  let [data, setData] = useState({});
  let [count, setCount] = useState(0);
  let [price, setPrice] = useState(0);
  let [minted, setMinted] = useState(0);

  let [isNFT, setIsNFT] = useState();
  let [isWhitelist, setIsWhitelist] = useState(false);

  const { collectionAddress } = useParams();
  const [stepModal1, setStepModal1] = useState(true);
  const [stepModal2, setStepModal2] = useState(false);
  const [editDescriptionModal, setEditDescriptionModal] = useState(false);
  const [microeconomyMintModal, setMicroeconomyMintModal] = useState(false);
  const [microeconomySuccessModal, setMicroeconomySuccessModal] = useState(false);

  const navigate = useNavigate()


  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');

    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  useEffect(() => {
    props.getCollection({ address: collectionAddress });
    if (networkId == 80002 || networkId == 137) {
      setBSymbol("MATIC");
      setChain("Polygon");
    } else if (networkId == 97 || networkId == 56) {
      setBSymbol("BNB");
      setChain("Binance");
    } else if (networkId == 51 || networkId == 50) {
      setBSymbol("XDC");
      setChain("XDC");
    }
  }, [networkId]);

  const getNFTs = async () => {
    props.getKycStatus({ publicAddress: props.publicAddress })
  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      getNFTs();
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    if (props.collectionData && web3) {
      sortData();
    }
  }, [props.collectionData, web3]);

  async function sortData() {
    settokenName(props?.collectionData?.[0]?.['name']);
    settokenSymbol(props?.collectionData?.[0]?.['symbol']);
    settokenDescription(props?.collectionData?.[0]?.['description']);
    settokenBanner(props?.collectionData?.[0]?.['banner']);
    settokenNetwork(props?.collectionData?.[0]?.['network']);
    let pAddress = props?.collectionData?.[0]?.['paymentToken'];
    setpaymentTokenAddress(pAddress);
    let rate = 0;
    if (props && props.collectionData.length > 0 && props.collectionData[0]['price']) {
      rate = props?.collectionData?.[0]?.['price'];
      settokenPrice(rate);
      settokenSupply(props?.collectionData?.[0]?.['supply']);
      if (props?.collectionData?.[0]?.['paymentToken'] !== "0x0000000000000000000000000000000000000000") {
        let contract = new web3.eth.Contract(XRC721ABI, pAddress);
        let symbol = await contract.methods.symbol().call();
        setpaymentToken(symbol);
      }
      settokenOwner(props?.collectionData?.[0]?.['owner']);
      let dateString = props?.collectionData?.[0]?.['createdAt'];
      let date = new Date(dateString);
      let monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
      ];
      let formattedDate = `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
      setcreatedAt(formattedDate)
      let contract = new web3.eth.Contract(XRC721ABI, collectionAddress);
      let mint = await contract.methods.totalSupply().call();
      let cName = await contract.methods.name().call();
      let cSymbol = await contract.methods.symbol().call();
      let cPrice = await contract.methods.price().call();
      cPrice = web3.utils.fromWei(cPrice, 'ether');
      setMinted(parseInt(mint));
      setPrice(parseFloat(cPrice));
      if (parseInt(mint) > 0) {
        props.setLoader({
          message: "Loading Data ...",
          status: true,
        });
      }
      let array1 = [];
      let list = [];
      for (let i = 1; i <= parseInt(mint); i++) {
        let data = await contract.methods.tokenURI(i).call();
        console.log("*** data 1", data);
        if (data == `https://gateway.pinata.cloud/ipfs/QmPdDPjmjJAcwXkWimqrpzqdy4YTVgv9PUheqxeyVsvBrc/1.json${i}.json`) {
          data = data.slice(0, 80);
          data = `${data}/${i}.json`;
        }
        let id = i;
        array1.push({ id, cName, cSymbol });
        list.push(data);
      }
      props.setLoader({ status: false });
      // console.log("*** list",list);
      props.getUriData({ list });
      setnftData(array1)
    }
  }

  useEffect(() => {
    if (props.uriData) updateData();
  }, [props.uriData]);

  async function updateData() {
    if (props && props.uriData.length > 0) {
      if (nftData?.length == props?.uriData?.length) {
        let temp = nftData.map((item, idx) => {
          return { ...item, ...props?.uriData?.[idx] };
        })
        setnftData(temp);
      }
    }
  }

  const handleImageError = (idx) => {
    // Set the failed image to the fallback image
    nftData[idx]['image'] = alternate;
    setnftData([...nftData]);
  };

  async function handleOnInput(e) {
    if ([e.target.name] == "description") {
      setdescription(e.target.value);
    }
  }

  async function updateDesc(e) {
    try {

      e.preventDefault();

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (description == "") {
        EventBus.publish("error", `Please enter token description`);
        return;
      }

      if (!description.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token description`);
        return;
      }

      if (!description.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token description must contain alphabets`);
        return;
      }

      props.setLoader({
        message: "Updating Description...",
        status: true,
      });

      await props.update721Description({ publicAddress, tokenAddress: collectionAddress, description });

      props.setLoader({
        message: "Updating Description...",
        status: false,
      });

    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "description update Failed...",
        status: false,
      });
      EventBus.publish("error", `Description update Failed`);
    }
  }

  function incrementCount() {
    if (count >= (tokenSupply - minted)) {
      EventBus.publish("error", `Mint amount exceeds available NFTs!`);
      return;
    }
    count = count + 1;
    setCount(count);
  }

  function decrementCount() {
    if (count > 0) {
      count = count - 1;
      setCount(count);
    } else {
      setCount(0);
    }
  }

  async function setInstance() {
    setMicroeconomyMintModal(true);
    let contract = new web3.eth.Contract(XRC721ABI, collectionAddress);
    let mint = await contract.methods.totalSupply().call();
    setMinted(mint);
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function mintNFT(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;


      if (count >= (tokenSupply - minted)) {
        EventBus.publish("error", `Mint amount exceeds available NFTs!`);
        return;
      }

      if (count == 0) {
        EventBus.publish("error", `Enter NFT amount to mint!`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      // if (props.kycStatus !== "adminApproved") {
      //   EventBus.publish("error", `Submit KYC verification to access this function!`);
      //   return;
      // }

      // if (props.userSoulBound == "") {
      //   EventBus.publish("error", `User soul bound token not available!`);
      //   return;
      // }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let tokenAmount = 0;

      let sentValue = (parseFloat(price) * parseInt(count));
      sentValue = web3.utils.toWei(sentValue.toString(), "ether");

      console.log("*** price", price);

      if (paymentTokenAddress !== "0x0000000000000000000000000000000000000000") {
        let contractPaymentToken = new web3.eth.Contract(XRC20ABI, paymentTokenAddress);
        let userBalance = await contractPaymentToken.methods.balanceOf(deployer).call();
        userBalance = web3.utils.fromWei(userBalance.toString(), "ether");


        if (parseFloat(userBalance) < (parseFloat(price) * parseInt(count))) return EventBus.publish("error", `Insufficient token amount`);
        props.setLoader({
          message: "Approval in Progress...",
          status: true,
        });

        tokenAmount = parseFloat(price) * parseInt(count);
        tokenAmount = web3.utils.toWei(tokenAmount.toString(), "ether");

        console.log("*** tokenAmount", tokenAmount);

        await contractPaymentToken.methods.approve(collectionAddress, tokenAmount.toString()).send({
          from: deployer,
        });
        props.setLoader({
          message: "Approval complete...",
          status: true,
        });
        sentValue = 0;
      }

      props.setLoader({
        message: "Minting in Progress...",
        status: true,
      });

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let contract = new web3.eth.Contract(XRC721ABI, collectionAddress);

      console.log("*** from", from);
      console.log("*** deployer", deployer);
      console.log("*** collectionAddress", collectionAddress);
      // console.log("*** userSoulBound", props.userSoulBound);
      console.log("*** count", count);
      console.log("*** tokenAmount", tokenAmount);
      console.log("*** sentValue", sentValue);

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: sentValue,
          to: collectionAddress,
          gas: 5000000,
          data: contract.methods
          .mint(deployer, count, tokenAmount.toString(), props.selectDefaultNetwok == 51 || props.selectDefaultNetwok == 50 ? "0x90b968b328c8e9399d6dcaedcf006ca913c538ba" : "0x364C552Fc969B077E0De3a8131CcCA241112e0FF")
          .encodeABI(),
        })
        .on('transactionHash', hash => {
          console.log(`************** deploy contract hash = ${hash}`);
          setTxHash(hash);
        })
        .on('receipt', async receipt => {
          await refreshBalance();
          setCount(0);
          props.setLoader({ status: false });
          EventBus.publish("success", `NFT(s) Minted Successfully!`);
          setMicroeconomyMintModal(false);
          setMicroeconomySuccessModal(true);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      setCount(0);
      setMicroeconomyMintModal(false);
      EventBus.publish("error", `Mint Failed`);
    }
  };

  async function copiedAddress() {
    EventBus.publish("success", "Transaction Hash Copied");
  }

  const handleCopyText = () => {
    navigator.clipboard.writeText(txHash)
      .then(() => EventBus.publish("success", "Transaction Hash Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };


  return (
    <>
      <div className="microeconomy-wrapper">
        <div className="microeconomy-head">

          <a className="user-profile-icon" href="#">
          </a>
          <a className="buy-vip-nft" target="_blank" href="https://vip.cifiapp.com/">Buy Cifi NFTs</a>
          <ConnectWallet />
        </div>

        <div className="marketplace-banner">
          <img src={collectionbackground} alt="" />
        </div>

        <div className="marketplace-collection-wrap">
          <div className="container">
            <div className="inner">

              <div className="main-collection-info">
                <img src={tokenBanner} alt="" />

                <h2>{tokenName} <img src={toprated} alt="" /></h2>

                <p>By <span>{tokenOwner.substring(0, 5) + '...' + tokenOwner.substring(37, tokenOwner.length)}</span><img src={toprated} alt="" /></p>
              </div>

            </div>

            <div className="wrap">
              <div className="minting-now">
                <p>
                  {
                    parseInt(tokenSupply) == parseInt(minted) ?
                      <span>Minted</span> :
                      <span>Minting now</span>
                  }
                </p>

                <p>Items <span>{minted}</span> of <span>{tokenSupply}</span></p>

                <p>Created <span>{createdAt}</span></p>

                {/* <p>Creator earnings <span>{toFixed((parseInt(minted) / parseInt(tokenSupply) * 100), 'price')}%</span></p> */}

                <p>Chain <span>{chain}</span></p>

                <p> <button className="common-tn" onClick={() => setInstance()}>Mint</button></p>
              </div>

              <div className="desc">
                <p>{tokenDescription}  <img src={editicon} onClick={() => setEditDescriptionModal(true)} alt="" /></p>
              </div>

              <div className="collection-info-list">
                <div className="text-center">
                  {
                    paymentToken !== "" ?
                      <p>{`${tokenPrice * minted} ${paymentToken}`}</p> :
                      <p>{`${tokenPrice * minted} ${BSymbol}`}</p>
                  }
                  <span>total volume</span>
                </div>
              </div>

              <div className="nft-wrapper">
                <Form className="filter-collection">
                  <span>{minted} results</span>
                </Form>
              </div>
            </div>

            <div className="multiple-collections-wrapper">
              <div className="multiple-collections">
                {
                  nftData.length > 0 &&
                  nftData.map((item, idx) => (
                    <div className="collection-box">
                      <div className="img-wrap">
                        <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                      </div>

                      <div className="collection-info">
                        <div className="head">
                          <div>
                            <h3>{item['cName']}</h3>
                            <h6>{item['cSymbol']}</h6>
                          </div>

                          <span># {item['id']}</span>
                        </div>
                      </div>
                      <Link className="" to={`/dashboard/microeconomy/sell/${collectionAddress}/${item['id']}`}>
                        <Button>Detail</Button>
                      </Link>
                    </div>
                  ))
                }

              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={stepModal1}
        onClose={() => setStepModal1(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal1(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 1/2</h3>

            <i>Mint:</i>

            <p>Click <span>Mint</span> button from any collection card to mint that collection.</p>
          </div>


          <img src={mintstepinfo} alt="" />

          <div className="steps-btns">
            <button disabled>Previous step</button>

            <button onClick={() => { setStepModal2(true); setStepModal1(false) }}>Next step</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal2}
        onClose={() => setStepModal2(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal2(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 2/2</h3>

            <i>NFT collection details:</i>

            <p>Click <span>image</span> of a collection to view collection details.</p>
          </div>


          <img src={collectionimagestepinfo} alt="" />

          <div className="steps-btns">
            <button onClick={() => { setStepModal1(true); setStepModal2(false) }}>Previous step</button>

            <button disabled>Next step</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={editDescriptionModal}
        onClose={() => setEditDescriptionModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal edit-desc-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setEditDescriptionModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="head mb-5">
              <h3>Edit Description
              </h3>
            </div>

            <form onSubmit={updateDesc}>
              <div className="form-group">
                <textarea
                  as="textarea"
                  rows={6}
                  name='description'
                  value={description}
                  placeholder="Description"
                  onChange={handleOnInput}
                />
              </div>

              <div className="btns dao-btn">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={microeconomyMintModal}
        onClose={() => setMicroeconomyMintModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal mint-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyMintModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <form onSubmit={mintNFT}>
              <div className="form-head">
                {
                  paymentToken == undefined || paymentToken == "0x0000000000000000000000000000000000000000"
                    ?
                    <h3>Public Sale : {price} XDC</h3>
                    :
                    <h3>Public Sale : {price} {tokenSymbol}</h3>

                }

                <p><span>{minted}</span> of <span>{tokenSupply}</span></p>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name='walletAddress'
                  value={props.publicAddress}
                  disabled
                />
              </div>

              <div className="form-group total-number">
                <div className="number">{count}</div>

                <div className="count-wrap">
                  <span onClick={incrementCount}>+</span>
                  <span onClick={decrementCount}>--</span>
                </div>
              </div>

              <button type="submit">
                Mint
              </button>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={microeconomySuccessModal}
        onClose={() => setMicroeconomySuccessModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal success-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomySuccessModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <img src={tickcircle} alt="Tick Circle" />

            <h3>Success</h3>

            <span>NFT Minted</span>
            <form>


              <div className="form-group hash">
                <p>
                  <CopyToClipboard
                    text={txHash}
                    onCopy={copiedAddress}
                  >
                    <a>{`Transaction Hash: ${txHash}`}</a>
                  </CopyToClipboard>
                  <img className="copy" src={copy} alt="Tick Circle" onClick={() => handleCopyText()} />
                </p>

                {networkId == 137 ? (
                  <a href={`https://polygonscan.com/tx/k${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 80002 ? (
                  <a href={`https://www.oklink.com/amoy/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 56 ? (
                  <a href={`https://bscscan.com/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 97 ? (
                  <a href={`https://testnet.bscscan.com/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 50 ? (
                  <a href={`https://xdc.blocksscan.io/txs/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : networkId == 51 ? (
                  <a href={`https://apothem.blocksscan.io/tx/${txHash}`} target="_blank">
                    <img className="go-arrow" src={goarrow} alt="Go Arrow" />
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapDispatchToProps = {
  getCollection,
  setLoader,
  getUriData,
  setBalance,
  getKycStatus,
  setGrootBalance,
  update721Description
};

const mapStateToProps = ({ Auth }) => {
  let { address, publicAddress, userSoulBound, collectionData, uriData, currentMode, kycStatus } = Auth;
  return { address, publicAddress, userSoulBound, collectionData, uriData, currentMode, kycStatus };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExploreNft);