import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import liquidityminingicon from "../../images/liquidity-mining.png";
import arrowrightbtn from "../../images/arrow-right.svg";
import rewardcollected from "../../images/reward-pool.png";
import investedpool from "../../images/invested-pool.png";
import lockedpool from "../../images/locked-pool.png";
import subtabicon from "../../images/menu-sub-tab-icon.svg";
import subtabfillicon from "../../images/subtab-fill-icon.svg";
import { networkId, LiquidityPoolContract2,LiquidityPoolAddress2,CIFI,SPNFTContract, message, CIFIAddress } from "../../store/config";
import { setLoader, setSPNFT } from "../../store/actions/Auth";
import Chart from "react-apexcharts";


const StabilityPool = (props, { mode }) => {

  const [model, setModel] = useState(true);
  const [compoundedAmount, setCompoundedAmount] = useState(0);
  const [years, setYears] = useState(0);
  const [totalYears, setTotalYears] = useState([]);
  const [series, setSeries] = useState([]);
  const [component, setComponent] = useState("compounded");

  const [totalXDCInvestment, setTotalXDCInvestment] = useState(0);
  const [totalRewardsCollected, setTotalRewardsCollected] = useState(0);
  const [totalRiFiAmount, setTotalRiFiAmount] = useState(0);
  const [canWithdraw, setCanWithdraw] = useState(false);

  const [CIFIAmount, setCIFITokens] = useState(0);

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);
  
  // const series = [
  //   {
  //     name: " ", //will be displayed on the y-axis
  //     data: [100, 1300, 400, 1000, 300, 750, 250, 1400, 500, 1050 ]
  //   }
  // ];

  const options = {
    chart: {
      id: "area"
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: totalYears,
      // categories: ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6", "Year 7", "Year 8", "Year 9", "Year 10"],

      axisBorder: {
        color: '#282C35',
        height: 2,
        width: '100%'
      },

      labels: {
        style: {
          colors: '#A9A9A9',
          fontSize: '12px',
          fontFamily: 'Quicksand-Medium',
          fontWeight: 400,
        },
      }
    },

    yaxis:{
      categories: [100, 300, 900, 1200, 1500],

      labels: {
        style: {
          colors: '#A9A9A9',
          fontSize: '12px',
          fontFamily: 'Quicksand-Medium',
          fontWeight: 400,
        },

        formatter: function (value) {
          return "ReFi " + value.toFixed(3);
        }
      }
    },
 
    fill: {
      colors: ['#5bc3b9'],
      opacity: 1,
      width:8,
      type: 'gradient',

      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.7,
        opacityTo: 0.7,
        stops: [0, 100]
      }
    },

    stroke:{
      colors:['#5bc3b9'],
      fill:['#5bc3b9']
    },

    grid: {
      show: true,
      borderColor: '#282C35',
      strokeDashArray: 6,
      xaxis: {
          lines: {
              show: false
          }
      },    
    },

    plotOptions: {
      bar: {
        columnWidth: '8',
      },
    }
  };
  const getLiquidityPoolData = async () =>{
    let totalXDCInvestment = await CIFI.methods.balanceOf(LiquidityPoolAddress2).call();
    let totalRewardsCollected = await LiquidityPoolContract2.methods.totalRewardsCollected().call();
    let totalRiFiAmount = await LiquidityPoolContract2.methods.totalRIFIAmount().call();
    totalXDCInvestment = await web3.utils.fromWei(totalXDCInvestment.toString(), 'ether');
    totalRewardsCollected = await web3.utils.fromWei(totalRewardsCollected.toString(), 'ether');
    totalRiFiAmount = await web3.utils.fromWei(totalRiFiAmount.toString(), 'ether');
    
    let deployer = (await web3.currentProvider.enable())[0];
    let isClaim = await LiquidityPoolContract2.methods.UserPoolData(deployer).call();
    if(isClaim.userAddress.toLowerCase() !== deployer.toLowerCase()) setCanWithdraw(false)
    else setCanWithdraw(true);
    
    setTotalXDCInvestment(totalXDCInvestment);
    setTotalRewardsCollected(totalRewardsCollected);
    setTotalRiFiAmount(totalRiFiAmount);
  }

  useEffect(()=>{
    if(web3 && props.publicAddress){
      // props.setLoader({message:"Load liquidity pool...",status:true});
      getLiquidityPoolData();
    }
  },[web3,props.publicAddress])

  const addLiquidity = async () => {
    try {
      
      if(!props.publicAddress) return  EventBus.publish("error", "Please connect wallet");
      let XDC = await web3.utils.toWei(CIFIAmount.toString(), 'ether');
      let deployer = (await web3.currentProvider.enable())[0];
      if(XDC <= 0) return  EventBus.publish("error", "CIFI Amount is less then 0");
      
      let balance = await CIFI.methods.balanceOf(deployer).call();

      if(Number(balance) < Number(XDC)) return  EventBus.publish("error", "Insufficient Amount of CIFI Tokens");

      props.setLoader({message:`Approve CIFI ${CIFIAmount} for Stability Pool...`,status:true});
      await web3.eth
      .sendTransaction({
        from: deployer,
        value: 0,
        to: CIFIAddress,
        gas: 5000000,
        data: CIFI.methods
          .approve(LiquidityPoolAddress2,XDC)
          .encodeABI(),
      })
      .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
      .on('receipt', async receipt => {
        props.setLoader({message:`Adding CIFI ${CIFIAmount} in Liquidity Pool...`,status:true});
        await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: LiquidityPoolAddress2,
          gas: 5000000,
          data: LiquidityPoolContract2.methods
            .depositCIFI(XDC)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          XDC = await web3.utils.fromWei(XDC.toString(), 'ether');
          let tokenId = await SPNFTContract.methods.walletOfOwner(deployer).call();
          tokenId = tokenId[0];
          // props.setSPNFT({ publicAddress: deployer, nftId: tokenId, tokenAddress: LiquidityPoolAddress2.toLowerCase(), totalAmount: XDC });
          getLiquidityPoolData();
          EventBus.publish("success", "Deposit Liquidity Successful!");
        });
        
      })
      
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
   
    } catch (error) {
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      EventBus.publish("error", error.message);
    }
  }

  const claimTokens = async () => {
    try {
      
      if(!props.publicAddress) return  EventBus.publish("error", "Please connect wallet");
      let deployer = (await web3.currentProvider.enable())[0];
      let isClaim = await LiquidityPoolContract2.methods.isClaim().call({from:deployer});
      if(parseInt(isClaim) < 30) return EventBus.publish("error", `You can claim after 30 days`);
      props.setLoader({message:"Claim Rewards...",status:true});
      
      await web3.eth
      .sendTransaction({
        from: deployer,
        value: 0,
        to: LiquidityPoolAddress2,
        gas: 5000000,
        data: LiquidityPoolContract2.methods
          .claimRewards()
          .encodeABI(),
      })
      .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
      .on('receipt', async receipt => {
        getLiquidityPoolData();
        props.setLoader({message:"Claim Rewards...",status:false});
        EventBus.publish("success", "Claim rewards successful!");
      });
    } catch (error) {
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      EventBus.publish("error", error.message);
    }
  }

  const Withdraw = async () => {
    try {
      
      if(!props.publicAddress) return  EventBus.publish("error", "Please connect wallet");
      let deployer = (await web3.currentProvider.enable())[0];
      let isClaim = await LiquidityPoolContract2.methods.UserPoolData(deployer).call();
      if(isClaim.userAddress.toLowerCase() !== deployer.toLowerCase()) return EventBus.publish("error", `You have already withdraw`);
      props.setLoader({message:"Withdraw...",status:true});
      
      await web3.eth
      .sendTransaction({
        from: deployer,
        value: 0,
        to: LiquidityPoolAddress2,
        gas: 5000000,
        data: LiquidityPoolContract2.methods
          .withdrawRewards()
          .encodeABI(),
      })
      .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
      .on('receipt', async receipt => {
        getLiquidityPoolData();

        props.setLoader({message:"Withdraw...",status:false});
        EventBus.publish("success", "Withdraw successful!");

      });
    } catch (error) {
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      EventBus.publish("error", error.message);
    }
  }

  const unCompound = async (e) => {
    e.preventDefault()
    if(Number(compoundedAmount) <= 0) return EventBus.publish("error", `Amount must be grather then 1`);
    if(Number(years) <= 0) return EventBus.publish("error", `Months must be grather then 1`);
    if(!compoundedAmount) return EventBus.publish("error", `Amount must be grather then 1`);
    if(!years) return EventBus.publish("error", `Months must be grather then 1`);
    setSeries([])
    setTotalYears([])
    let dataNew = [];
    let yearsCount = []
    let totalRewards = (compoundedAmount/1000*100)/30;
    //  let calculateMonths = years * 12
    for (let index = 1; index <= years; index++) {
      let yearsDays = 30*index
      let newAmount = totalRewards*yearsDays
      dataNew.push(newAmount)
      yearsCount.push(`Month ${index}`)
    }
    setSeries(dataNew)
    setTotalYears(yearsCount)
  }
  const Compound = async (e) => {
    e.preventDefault()
    if(Number(compoundedAmount) <= 0) return EventBus.publish("error", `Amount must be grather then 1`);
    if(Number(years) <= 0) return EventBus.publish("error", `Months must be grather then 1`);
    if(!compoundedAmount) return EventBus.publish("error", `Amount must be grather then 1`);
    if(!years) return EventBus.publish("error", `Months must be grather then 1`);
    setSeries([])
    setTotalYears([])
    let dataNew = [];
    let yearsCount = []
    let totalRewardsUnCompounds = (compoundedAmount/1000)/30;
    // let totalRewardsCompounds = (totalRewardsUnCompounds/1000*100)/30;
    // let calculateMonths = years * 12
    let lastAmount = 0;
    for (let index = 1; index <= years; index++) {
      let yearsDays = 30*index
      let totalCompounds = totalRewardsUnCompounds*yearsDays
      let totalRewardsCompounds = (totalCompounds/1000*100)/30;
      // let newAmount = totalRewardsCompounds*yearsDays
      let totalAmount = totalRewardsCompounds * yearsDays
      // let totalAmount  = totalCompounds*newAmount
      lastAmount = totalAmount + lastAmount;
      dataNew.push(totalAmount)
      yearsCount.push(`Month ${index}`)
    }
    setSeries(dataNew)
    setTotalYears(yearsCount)
  }

  const setRadioButton = (value) => {
    setCompoundedAmount("") 
    setYears("")
    setComponent(value);
  }

  return (
    <div className="microeconomy-wrapper dao-genesis launch-dao">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={liquidityminingicon} alt="" />
          <span>Liquidity Mining</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {/* {
          (title !== "" || description !== "" || tokenAddress !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        } */}
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="stability-boxes-wrap">
        <div className="box">
          <div className="icon-wrap">
            <img src={rewardcollected} alt="" />
          </div>

          <div className="info">
            <span>Total Reward Collected</span>

            <p>{Number(totalRewardsCollected).toFixed(3)} REFI</p>
          </div>
        </div>

        <div className="box">
          <div className="icon-wrap">
            <img src={investedpool} alt="" />
          </div>

          <div className="info">
            <span>Total Invested Amount</span>

            <p>{Number(totalXDCInvestment).toFixed(3)} CIFI</p>
          </div>
        </div>

        <div className="box">
          <div className="icon-wrap">
            <img src={lockedpool} alt="" />
          </div>

          <div className="info">
            <span>Total REFI In Pool</span>

            <p>{Number(totalRiFiAmount).toFixed(3)} REFI</p>
          </div>
        </div>
      </div>

      <div className="liq-cal-wrap">
        <div className="steps-form-wrapper stability-pool">
            <div className="mb-8 mt-4">
            <h3>Add liquidity in stability pool</h3>

            <p>Add liquidity using CIFI tokens to earn REFI tokens through an un-compounded reward mechanism. You can also multiply your rewards further by pledging earnings from Stability Pool 1 in a compounded reward mechanism. </p>
            </div>
            <div className="form-wrap">
                <div className="form-group">
                  <input
                    type="number"
                    name='amount'
                    placeholder="Enter amount in CIFI"
                    value={CIFIAmount}
                    onChange={e=>{
                      if(Number(e.target.value) <= -1) setCIFITokens(1);
                      else setCIFITokens(e.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                  <button className="common-btn" onClick={addLiquidity}>Add Liquidity <img src={arrowrightbtn} alt="" /></button>
                </div>
                {canWithdraw &&
                <>
                  <div className="form-group">
                    <button className="common-btn" onClick={claimTokens}>Claim Rewards <img src={arrowrightbtn} alt="" /></button>
                  </div>

                  <div className="form-group">
                    <button className="common-btn" onClick={Withdraw}>Withdraw <img src={arrowrightbtn} alt="" /></button>
                  </div>
                </>
                }
            </div>
        </div>
        
        <div className="form-wrap">
            <form>
              <h2>Calculator</h2>

              <div className="select-market">
                <div className="radio-wrapper">
                  <p>
                    <input type="radio" id="compounded" name="radio-group" checked={component == "compounded" ? true : false} onClick={()=>setRadioButton("compounded")} />
                    <label for="compounded">
                      <img src={subtabicon} ></img>
                      <img src={subtabfillicon} className="fill"></img>
                      Compounded
                    </label>
                  </p>

                  <p>
                    <input type="radio" id="un-compounded" name="radio-group" checked={component == "uncompounded" ? true : false} onClick={()=>setRadioButton("uncompounded")} />
                    <label for="un-compounded">
                      <img src={subtabicon} ></img>
                      <img src={subtabfillicon} className="fill"></img>
                      Un-Compounded
                    </label>
                  </p>
                </div>
              </div>
              {component == "compounded" ? 
              <>
              <div className="field-wrap">
                <div className="form-group">
                  <input
                    type="number"
                    placeholder="Invested Amount XDC"
                    value={compoundedAmount}
                    onChange={e=>{
                      setCompoundedAmount(e.target.value)
                    }}
                    // onWheel={handleOnWheel}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    placeholder="Months"
                    value={years}
                    onChange={e=>{
                      setYears(e.target.value)
                    }}
                    // onWheel={handleOnWheel}
                  />
                </div>
              </div>

              <div className="form-group">
                <button onClick={(e)=>Compound(e)}>
                  Compounded
                </button>
              </div>

              </>
              :
              <>
                <div className="field-wrap">
                  <div className="form-group">
                    <input
                      type="number"
                      placeholder="Invested Amount CiFi"
                      value={compoundedAmount}
                      onChange={e=>{
                        setCompoundedAmount(e.target.value)
                      }}
                      // onWheel={handleOnWheel}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="number"
                      placeholder="Months"
                      value={years}
                      onChange={e=>{
                        setYears(e.target.value)
                      }}
                      // onWheel={handleOnWheel}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <button onClick={(e)=>unCompound(e)}>
                    Un-Compounded
                  </button>
                </div>
              </>
            }
            </form>
        </div>
      </div>
      
      <div className="calculation-chart">
        {/* <div className="toggle-wrap">
            <label>
              Compounded return
              <input type="checkbox" id="backup_set_toggle" />
              <span className="check"></span>
            </label>
        </div> */}
         <Chart options={options} series={[{name:"",data:series}]} type="area" height={250} />
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  setSPNFT
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, isLoader } = Auth;
  return { publicAddress, isLoader }
};

export default connect(mapStateToProps, mapDispatchToProps)(StabilityPool);