import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import React, { useState, useEffect } from 'react';
import alternate from "../../images/alternate.jpg";
import microeconomylogo from "../../images/microeconomy-icon.png";
import walletaddresscopyicon from "../../images/wallet-address-copy-icon.png";
import { setLoader, getXRC20, setBalance, setGrootBalance } from "../../store/actions/Auth";
import { Link } from 'react-router-dom';


const ExploreToken = (props, { mode }) => {

  const [collections, setCollections] = useState([]);
  const [model, setModel] = useState(true);


  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');

    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  useEffect(() => {
    props.getXRC20();
    if (props.publicAddress == null || props.publicAddress == undefined) {
      EventBus.publish("info", `Please connect your wallet!`);
      return;
    }
  }, []);

  async function setMarketplaceData() {
    const updatedCollection = await Promise.all(
      props.xrc20Collections.filter(a=>parseInt(a.network) == parseInt(props.selectDefaultNetwok)).map(async (item) => {
        if (parseInt(item['network']) == parseInt(props.selectDefaultNetwok)) {
          return { ...item };
        }
        return item;
      })
    );

    updatedCollection.sort((a, b) => {
      const nameA = a.name.toLowerCase(); // Convert names to uppercase for case-insensitive comparison
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; // Names are equal
    });
    setCollections(updatedCollection);
  }

  useEffect(() => {
    if (props.publicAddress == null || props.publicAddress == undefined) {
      return;
    } else {
      if ((props.xrc20Collections.length > 0) && web3) {
        setMarketplaceData();
      }
    }
  }, [props.xrc20Collections, props.selectDefaultNetwok, props.publicAddress, web3]);

  async function callFilter(value) {
    let filterValue = props.xrc20Collections.filter(a=>parseInt(a.network) == parseInt(props.selectDefaultNetwok)).filter((a) => {
      if (value) {
        return a.name?.toString().toLowerCase().includes(value.toLowerCase())
      }
      else {
        return a
      }
    });
    setCollections(filterValue);
  }

  const handleImageError = (idx) => {
    // Set the failed image to the fallback image
    collections[idx]['image'] = alternate;
    setCollections([...collections]);
  };

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address)
      .then(() => EventBus.publish("success", "Token Address Copied"))
      .catch((error) => console.log('Copy failed:', error));
  };


  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Loading data please stay on this page!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="nft-collection">
        <div className="head">
          <h2>Explore Crypto Tokens</h2>
          <div className="collection-filters ">
            <span className="mb-1">Search Tokens : </span>
            <div className="filters">
              <div className="search-field">
                <input type="search" placeholder="Search Crypto Tokens..." onChange={e => callFilter(e.target.value)} />
              </div>
            </div>
          </div>

        </div>

        <div className="collection-wrap">
          {
            collections?.length > 0 &&
            collections?.map((item, idx) => (
              <div className="box">
                <img key={idx} src={item?.['banner'] ? item?.['banner'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />

                <div className="content">
                  <div className="info">
                    <div className="profile-info">
                      {/* <img src={item['ownerImg'] !== "" ? item['ownerImg'] : alternate} alt="" /> */}
                      <div>
                        <h4>{item['name']}</h4>
                        <p>{item['symbol']}</p>
                      </div>
                    </div>
                  </div>

                  <div className='wallet-address  mt-3 mb-3'>
                    <p>{item?.['address'] && item?.['address'].substring(0, 5) + '...' + item?.['address'].substring(37, item?.['address'].length)}</p>
                    <img src={walletaddresscopyicon} alt="" onClick={() => handleCopyAddress(item?.['address'])} />
                  </div>

                  <Link to={`/project-detail/${item?.['_id']}`}>
                    <button className="common-btn view-details">View details</button>
                  </Link>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div >
  );
}

const mapDispatchToProps = {
  setLoader,
  getXRC20,
  setBalance,
  setGrootBalance,
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, xrc20Collections, selectDefaultNetwok, currentMode, isLoader } = Auth;
  return { publicAddress, xrc20Collections, selectDefaultNetwok, currentMode, isLoader }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreToken);