import React,{ useState, useEffect }  from "react";
import { connect } from "react-redux";
// import zingchart from 'zingchart/es6';
import moment from 'moment';
// import 'zingchart/modules-es6/zingchart-depth.min.js';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider' 
import Chart from "react-apexcharts";
import ConnectWallet from "../ConnectWallet";
import tradeiconnew from "../../images/trade-icon-new.png";
import bitcoin from "../../images/bitcoin-icon2.svg"
import icon5 from "../../images/icon5.png"; 
import ethicon from "../../images/eth-icon.svg";
import subtabicon from "../../images/subtab-icon.png";
import subtabfillicon from "../../images/subtab-fill-icon.png";
import arrowdown from "../../images/arrow-down.png";
import arrowdownwhite from "../../images/arrow-down-white.png";
import {socket} from "../../store/config";
import HeaderDash from "./HeaderDash";
import edit from "../../images/edit.png";
import etherum from "../../images/etherum.png";
import modalcloseicon from "../../images/close.png";
import EventBus from "eventing-bus";
import { Modal } from "react-responsive-modal";
import {setSinglePair,setOrderBook,getLimitOrderBook,getUserOrders,updateGraph,setBuyMarketOrders,cancelTradeOrders,getSingleTradeOrders, setLoader, setTradeOrder} from "../../store/actions/Auth";
import {networkId,ERC20Abi,OrderBookContract,OrderBookAddress,GrootRouterAddress,GrootRouter,GrootBinanceAddress,TradeBotAddress,TradeBotContract} from "../../store/config";
import { setExchangePairsDetails, setPairsBalance,getExchangeGraph, setPairsData, setAllUsersOrder } from "../../store/actions/Events";
import { web3 } from '../../store/web3';
import {getBestGrootBNBTokenAmount,getGrootBNBExchangePair,getBinanceExchangePair,exchangeApproved,arbitrageTransactions,swapTokens} from "./arbitrageBot";

function Trades(props) {

  const [pairs, setPairs] = useState("");
  const [allPairs, setAllPairs] = useState([]);
  const [marketType, setMarketType] = useState("limit");
  
  const [sellOrderAmount, setSellOrderAmount] = useState(0);
  const [sellOrderPrice, setSellOrderPrice] = useState(100);

  const [buyOrderAmount, setBuyOrderAmount] = useState(0);
  const [buyOrderPrice, setBuyOrderPrice] = useState(100);

  const [balanceOfTokenA, setBalanceOfTokenA] = useState(0);
  const [balanceOfTokenB, setBalanceOfTokenB] = useState(0);

  const [tokenA, setTokenA] = useState("");
  const [tokenB, setTokenB] = useState("");

  const [tokenAName, setTokenAName] = useState("");
  const [tokenBName, setTokenBName] = useState("");

  const [allOrders, setAllOrders] = useState([]);

  const [graphData, setGraphDta] = useState([]);

  // model 
  const [open, setOpen] = useState(false);

  const[modalState , setModalStates] = useState("Buy");

  const [price, setPrice]= useState(0);
  const [stopLose, setStopLose]= useState(0);
  const [takeProfit, setTakeProfit]= useState(0);
  const handleOnWheel = (event) => {
    const { type } = event.target;
    if(type === 'number'){
      event.preventDefault();
    }
  }
  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [buysellModal, setBuySellModal] = useState(false);
const options = {
    chart: {
      height: '100%',
      type: 'candlestick',
    },
    dataPointWidth: 1,
    annotations: {
      xaxis: [
        {
          x: 'Oct 06 14:00',
          borderColor: 'red',
          label: {
            borderColor: 'red',
            style: {
              fontSize: '12px',
              color: '#fff',
              background: 'red'
            },
            orientation: 'horizontal',
            offsetY: 7,
          }
        }
      ]
    },
    tooltip: {
      enabled: true,

      xaxis: {
        borderColor: 'green',
        strokeDashArray: 3,
      }

    },
    xaxis: {
      type: 'category',
      tickPlacement: 'false',
      // labels: {
      //   formatter: function(val) {
      //     return (val).format('MMM DD HH:mm')
      //   }
      // }

      axisBorder: {
        show: true,
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      },

      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      opposite: true,
      tooltip: {
        enabled: true
      },

      axisBorder: {
        show: true,
        height: '100%',
        width: 1,
        offsetX: 0,
        offsetY: 0
      },
    },

    grid: {
      show: true,
      borderColor: '#1b1b1b',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
          lines: {
              show: true
          }
      },   
      yaxis: {
          lines: {
              show: true
          }
      },  
      row: {
          //colors: undefined,
          opacity: 0.5
      },  
      column: {
          //colors: undefined,
          opacity: 0.5
      },  
      padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
      },  
  },
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    colors: undefined,
    width: 2,
    dashArray: 0, 
  },

    scales: {
      xAxes: [{
          barPercentage: 0.1
      }]
    }
};

function setExchangeData(){
  socket.emit("exchange:pairs",props.selectDefaultNetwok);
  socket.on(`exchange:pairs:${props.selectDefaultNetwok}`, async (data) => {
    if(data && data.length > 0){
      setAllPairs(data);
      setPairs(data[0]['token0Name']+"-"+data[0]['token1Name']);
      props.setSinglePair(data[0]['token0Name']+"-"+data[0]['token1Name']);
      // getLocalExchangeGraph({pairs:data[0]['token0Name']+"-"+data[0]['token1Name'],networkId});
      props.getExchangeGraph({pairs:data[0]['token0Name']+"-"+data[0]['token1Name'],networkId});
      // props.get24HVolume({pairs:data[0]['token0Name']+"-"+data[0]['token1Name'],networkId});
      getUserTradesHavingSetTrades({pairs:data[0]['token0Name']+"-"+data[0]['token1Name'],networkId});

      setTokenA(data[0]["token0Address"]);
      setTokenB(data[0]["token1Address"]);
      setTokenAName(data[0]["token0Name"]);
      setTokenBName(data[0]["token1Name"]);

      if (data[0]["token0Name"] == "BNB" || data[0]["token0Name"] == "XDC" || data[0]["token0Name"] == "MATIC") {
        setBalances({
          tokenA: data[0]["token0Name"],
          tokenB: data[0]["token1Address"],
          networkId,
        });
      } else {
        setBalances({
          tokenA: data[0]["token0Address"],
          tokenB: data[0]["token1Address"],
          networkId,
        });
      }
    }
  });
}

useEffect(()=>{
    if(props.selectDefaultNetwok) setExchangeData();
},[props.selectDefaultNetwok]);

React.useEffect(() => {
  let { pairDetails } = props;
  if (pairDetails && Object.keys(pairDetails).length > 0) {

    props.setSinglePair(pairDetails['token0Name']+"-"+pairDetails['token1Name']);
    // getLocalExchangeGraph({pairs:pairDetails['token0Name']+"-"+pairDetails['token1Name'],networkId});
    props.getExchangeGraph({pairs:pairDetails['token0Name']+"-"+pairDetails['token1Name'],networkId});
    // props.get24HVolume({pairs:pairDetails['token0Name']+"-"+pairDetails['token1Name'],networkId});
    getUserTradesHavingSetTrades({pairs:pairDetails['token0Name']+"-"+pairDetails['token1Name'],networkId});

    setTokenA(pairDetails["token0Address"]);
    setTokenB(pairDetails["token1Address"]);
    setTokenAName(pairDetails["token0Name"]);
    setTokenBName(pairDetails["token1Name"]);
    
    if (pairDetails["token0Name"] == "BNB" || pairDetails["token0Name"] == "XDC" || pairDetails["token0Name"] == "MATIC") {
      setBalances({
        tokenA: pairDetails["token0Name"],
        tokenB: pairDetails["token1Address"],
        networkId,
      });
    } else {
      setBalances({
        tokenA: pairDetails["token0Address"],
        tokenB: pairDetails["token1Address"],
        networkId,
      });
    }
  }
}, [props.pairDetails,props.selectDefaultNetwok]);

React.useEffect(() => {
  let { pairBalance } = props;
  if (Object.keys(pairBalance).length > 0) {
    setBalanceOfTokenA(pairBalance["amountA"]);
    setBalanceOfTokenB(pairBalance["amountB"]);
  }
}, [props.pairBalance,props.selectDefaultNetwok]);

useEffect(()=>{
  if(pairs){
    socket.emit("exchange:pair:graph",{pairs,networkId});
    socket.on(`exchange:${pairs}:graph`, data => {
      let newData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        newData.push(
            {
              x: index,
              y: [element.open, element.high, element.low, element.close]
            }
        )
      }
        setGraphDta([{name: 'candle',data:newData}]);
    });
    getUsersTrades(pairs);
  }
},[pairs]);

const getUserTradesHavingSetTrades = async ({pairs,networkId}) => {
  let publicAddress = (await web3.currentProvider.enable())[0];
  getUsersTrades(pairs);
  props.getSingleTradeOrders({publicAddress,pairs,networkId});
}

const setBalances = async ({ tokenA, tokenB }) => {
    let publicAddress = (await web3.currentProvider.enable())[0];
    publicAddress = publicAddress.toLowerCase();
    props.getSingleTradeOrders({publicAddress,pairs:props.singlePair,networkId});
    socket.emit("address:pairA:pairB", {
      publicAddress,
      tokenA,
      tokenB,
      networkId,
    });
    socket.on(`${publicAddress}:${tokenA}:${tokenB}`, (data) =>
      {
        props.setPairsBalance(data)
        setBalanceOfTokenA(data["amountA"]);
        setBalanceOfTokenB(data["amountB"]);
      }
    );
};

const setPairDetails = async (data) => {
  await socket.emit("exchange:pair:detail", data);
  await socket.on(`exchange:${data.pairs}:detail`, (data) =>{
    props.setExchangePairsDetails(data)
  }
  );
};

const pairsFunction = (item) => {
  // socket.close(); 
  // setCandelsData([]);
  setPairs(item)
  props.setSinglePair(item,props.selectDefaultNetwok);
  // props.get24HVolume({pairs:item,networkId:props.selectDefaultNetwok}); //after
  // getLocalExchangeGraph({pairs:item,networkId});
  props.getExchangeGraph({pairs:item,networkId:props.selectDefaultNetwok});
  // console.log("************** {publicAddress:props['address'],pairs:item,networkId}",{publicAddress:props['address'],pairs:item,networkId})
  // props.getSingleTradeOrders({publicAddress:props['address'],pairs:item,networkId});
  getUserTradesHavingSetTrades({pairs:item,networkId:props.selectDefaultNetwok})
  setPairDetails({pairs:item,networkId:props.selectDefaultNetwok});
}

const getUsersTrades = async (pairs) =>{
  let publicAddress = (await web3.currentProvider.enable())[0];
  let pairA = pairs.split("-")[0];
  let pairB = pairs.split("-")[1];
  publicAddress = publicAddress.toLowerCase();
    socket.emit("orders:address:pairA:pairB",{publicAddress,pairs,networkId});
    socket.on(`orders:${publicAddress}:${pairA}:${pairB}`, data => {
      props.setAllUsersOrder(data)
    });
    socket.emit("pairA:pairB",{pairs,networkId});
    socket.on(`${pairA}:${pairB}`,(data)=>{
      setAllOrders(data);
    });
}

const buySellFunction = (type) =>{
  // console.log("***************** type",type)
  setPairDetails({pairs,networkId:props.selectDefaultNetwok});
}

const sellTokens = async (e) => {
  try {
    e.preventDefault()
    if (sellOrderAmount == 0)
      return EventBus.publish("error", `Amount is zero`);
    if (sellOrderPrice == 0)
      return EventBus.publish("error", `Price is zero`);

    let orderBook = await props.userOrders.filter(item=>{return item['status']=="Pending"});
    if(orderBook.length > 0) return EventBus.publish("error", `You Have Already Set Order`);

    let publicAddress = (await web3.currentProvider.enable())[0];
    let amountIn = web3.utils.toWei(sellOrderAmount.toString(), "ether");
    let amountOut = web3.utils.toWei(sellOrderPrice.toString(), "ether");
    let balanceOfUser = web3.eth.getBalance(publicAddress);
    let value = web3.utils.toWei("0.4", "ether");
    if (balanceOfUser < value)
      return EventBus.publish(
        "error",
        `You Have Insufficient Balance For Transaction`
      );
    let grotERC20 = new web3.eth.Contract(ERC20Abi, tokenA);
    props.setLoader({
      message: "Order Confirmation...",
      status: true,
    });

    console.log("*************** amountIn + value",amountIn + value)
    await web3.eth
      .sendTransaction({
        from: publicAddress,
        value: 0,
        to: tokenA,
        gas: 2000000,
        data: grotERC20.methods
          .approve(OrderBookAddress, amountIn + value)
          .encodeABI(),
      })
      .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
      .on("receipt", async (receipt) => {
        props.setLoader({
          message: "Placing Order On Chain...",
          status: true,
        });
        console.log("*****************8 ",amountIn, amountOut,tokenB, tokenA,"buy",value,tokenAName,tokenBName)

        await web3.eth
          .sendTransaction({
            from: publicAddress,
            value: 0,
            to: OrderBookAddress,
            gas: 2000000,
            data: OrderBookContract.methods
              .setLimitOrder(
                [amountIn, amountOut],
                [tokenA, tokenB],
                "sell",
                value,
                [tokenAName,tokenBName]
              )
              .encodeABI(),
          })
          .on("transactionHash", (hash) =>
            console.log(`*******hash = `, hash)
          )
          .on("receipt", async (receipt) => {
            await props.setOrderBook({
              publicAddress: publicAddress,
              tokenAddressA: tokenA,
              tokenAddressB: tokenB,
              price: sellOrderPrice,
              amount: sellOrderAmount,
              orderType: "sell",
              pairs,
            });

            setBalances({ publicAddress, tokenB, tokenA });
            publicAddress = publicAddress.toLowerCase();
            getUsersTrades(pairs);

            props.setLoader({
              message: "Transaction Completed...",
              status: false,
            });
            EventBus.publish("success", `Place Order Successfully!`);
          });
        // EventBus.publish("success", `Order Approve Successfully!`);
      });
  } catch (e) {
    console.log("********Error = ", e);
    props.setLoader({
      message: "Transfer Not Completed...",
      status: false,
    });
    EventBus.publish("error", `Unable To Execute Tx`);
  }
  // await props.setOrderBook({"publicAddress":"","tokenAddressA":"","tokenAddressB":"","price":0,"amount":0, orderType:"buy"})
};

const buyTokens = async (e) => {
  try {
    e.preventDefault()
    let publicAddress = (await web3.currentProvider.enable())[0];
    if (buyOrderAmount == 0)
      return EventBus.publish("error", `Amount is zero`);
    if (buyOrderPrice == 0) return EventBus.publish("error", `Price is zero`);
    
    let orderBook = await props.userOrders.filter(item=>{return item['status']=="Pending"});
    if(orderBook.length > 0) return EventBus.publish("error", `You Have Already Set Order`);

    let amountIn = web3.utils.toWei(buyOrderAmount.toString(), "ether");
    let amountOut = web3.utils.toWei(buyOrderPrice.toString(), "ether");
    let balanceOfUser = web3.eth.getBalance(publicAddress);
    let value = web3.utils.toWei("0.4", "ether");
    let totalAmount = parseInt(parseInt(amountIn)+parseInt(value));
    if (balanceOfUser < value)
      return EventBus.publish(
        "error",
        `You Have Insufficient Balance For Transaction`
      );
    let grotERC20 = new web3.eth.Contract(ERC20Abi, tokenB);

    props.setLoader({
      message: "Order Confirmation...",
      status: true,
    });

    await web3.eth
      .sendTransaction({
        from: publicAddress,
        value: 0,
        to: tokenB,
        gas: 2000000,
        data: grotERC20.methods.approve(OrderBookAddress,totalAmount.toString()).encodeABI(),
      })
      .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
      .on("receipt", async (receipt) => {
        props.setLoader({
          message: "Placing Order On Chain...",
          status: true,
        });
        await web3.eth
          .sendTransaction({
            from: publicAddress,
            value: 0,
            to: OrderBookAddress,
            gas: 2000000,
            data: OrderBookContract.methods.setLimitOrder([amountIn, amountOut],[tokenB, tokenA],"buy",value,[tokenAName,tokenBName]).encodeABI(),
          })
          .on("transactionHash", (hash) =>
            console.log(`*******hash = `, hash)
          )
          .on("receipt", async (receipt) => {
            await props.setOrderBook({ publicAddress: publicAddress, tokenAddressA: tokenB, tokenAddressB: tokenA, price: buyOrderPrice, amount: buyOrderAmount, orderType: "buy", pairs});
      
            setBalances({ publicAddress, tokenB, tokenA });
            publicAddress = publicAddress.toLowerCase();
            getUsersTrades(pairs)
            
            props.setLoader({
              message: "Transaction Completed...",
              status: false,
            });
            EventBus.publish("success", `Place Order Successfully!`);
          });
        // EventBus.publish("success", `Order Approve Successfully!`);
      });
  } catch (e) {
    console.log("********Error = ", e);
    props.setLoader({
      message: "Transfer Not Completed...",
      status: false,
    });
    EventBus.publish("error", `Unable To Execute Tx`);
  }
};

const sellMarketTokens = async (e) => {
  try {
    e.preventDefault()
    if (sellOrderAmount == 0)
      return EventBus.publish("error", `Amount is zero`);
    let publicAddress = (await web3.currentProvider.enable())[0];
    if (networkId) {
      let binanceBNBTokenAmount = 0;
      let grootBNBTokenAmount = 0;
      let getGrootBNBpairs = await getGrootBNBExchangePair(tokenA, tokenB);

      let _fee = (sellOrderAmount / 100) * 0.4;
      let amountIn = sellOrderAmount;

      binanceBNBTokenAmount = await getBestGrootBNBTokenAmount(amountIn, [tokenA,tokenB]);
      binanceBNBTokenAmount = web3.utils.fromWei(binanceBNBTokenAmount[1], "ether");

      let minAmount = binanceBNBTokenAmount;
      if(binanceBNBTokenAmount == 0)  return EventBus.publish("error", `Amount In Zero`);
        // let mAmount = (binanceBNBTokenAmount / 100) * slippageValue;
        // let minAmount = binanceBNBTokenAmount - mAmount;
      minAmount = web3.utils.toWei(minAmount.toString(), "ether");
      amountIn = web3.utils.toWei(amountIn.toString(), "ether");

      props.setLoader({
          message: "Order Confirmation...",
          status: true,
      });

      await exchangeApproved(publicAddress,tokenA,amountIn,GrootRouterAddress);
      // await exchangeApproved(publicAddress,tokenA,amountIn,GrootRouterAddress);
      props.setLoader({ message: "Placing Order On Chain...", status: false});

        // GrootRouter
      // await swapTokens( publicAddress, [tokenA,tokenB], amountIn, minAmount, _fee, GrootRouterAddress);
        
      console.log("********************** [tokenA,tokenB], amountIn, minAmount, _fee, GrootRouterAddress",[tokenA,tokenB], amountIn, minAmount, _fee, GrootRouterAddress)

      // setBalances({tokenA, tokenB });
      // props.setLoader({message: "Transaction Completed...",status: false});

      // await props.setOrderBook({
      //   publicAddress: publicAddress,
      //   tokenAddressA: tokenA,
      //   tokenAddressB: tokenB,
      //   price: sellOrderAmount,
      //   amount: binanceBNBTokenAmount,
      //   orderType: "sell",
      //   pairs,
      //   status:"Success"
      // });

      // setBalances({tokenB, tokenA });
      // publicAddress = publicAddress.toLowerCase();
      // getUsersTrades(pairs)

    }
  } catch (e) {
    console.log("********Error = ", e);
    props.setLoader({
      message: "Transfer Not Completed...",
      status: false,
    });
    EventBus.publish("error", e || e.message);
  }
};

const buyMarketTokens = async (e) => {
  try {
    e.preventDefault()
    if (buyOrderAmount == 0)
      return EventBus.publish("error", `Amount is zero`);
    let publicAddress = (await web3.currentProvider.enable())[0];
    if (networkId) {
      let binanceBNBTokenAmount = 0;
      let grootBNBTokenAmount = 0;
      let getGrootBNBpairs = await getGrootBNBExchangePair(tokenB, tokenA);
      // let getBinanceBNBpairs = await getBinanceExchangePair(tokenB, tokenA);
      // console.log("*************** getGrootBNBpairs.length",getGrootBNBpairs)
      // if (getGrootBNBpairs == undefined) return EventBus.publish("error", `Pairs Not Found`);

      let _fee = (buyOrderAmount / 100) * 0.4;
      let amountIn = buyOrderAmount;

        binanceBNBTokenAmount = await getBestGrootBNBTokenAmount(amountIn, [
          tokenB,
          tokenA,
        ]);
        console.log("**************** binanceBNBTokenAmount",binanceBNBTokenAmount);
        if(binanceBNBTokenAmount == 0)  return EventBus.publish("error", `Amount In Zero`);
        
        binanceBNBTokenAmount = web3.utils.fromWei(
          binanceBNBTokenAmount[1],
          "ether"
        );
        let minAmount = binanceBNBTokenAmount;

        props.setLoader({
          message: "Order Confirmation...",
          status: true,
        });

        // let mAmount = (binanceBNBTokenAmount / 100) * slippageValue;
        // let minAmount = binanceBNBTokenAmount - mAmount;
        minAmount = web3.utils.toWei(minAmount.toString(), "ether");
        amountIn = web3.utils.toWei(amountIn.toString(), "ether");

        await exchangeApproved(publicAddress,tokenB,amountIn,GrootRouterAddress);
        props.setLoader({ message: "Placing Order On Chain...", status: true});

        // GrootRouter
        await swapTokens(
          publicAddress,
          [tokenB, tokenA],
          amountIn,
          minAmount,
          _fee,
          GrootRouterAddress
        );
        setBalances({tokenA, tokenB });

        await props.setOrderBook({
          publicAddress: publicAddress,
          tokenAddressA: tokenA,
          tokenAddressB: tokenB,
          price: buyOrderAmount,
          amount: binanceBNBTokenAmount,
          orderType: "buy",
          pairs,
          status:"Success"
        });

        setBalances({tokenB, tokenA });
        let pairA = pairs.split("-")[0];
        let pairB = pairs.split("-")[1];
        publicAddress = publicAddress.toLowerCase();
        getUsersTrades(pairs)

        props.setLoader({message: "Transaction Completed...",status: false});
        
    }
  } catch (e) {
    console.log("********Error = ", e);
    props.setLoader({
      message: "Transfer Not Completed...",
      status: false,
    });
    EventBus.publish("error", e || e.message);
  }
};

const handleOnInput = (e) => {
  let maxNum = 9;
  if (e.target.value.length > maxNum) {
    e.target.value = e.target.value.slice(0, maxNum);
  }
};

const handleOnInputSell = async(e) => {
  try {
    e.preventDefault();
    if(price == 0) return EventBus.publish("error", `Price is zero`); 
    if(stopLose == 0) return EventBus.publish("error", `Stop Lose is zero`); 
    if(takeProfit == 0) return EventBus.publish("error", `Take Profit is zero`); 
    let filter =props.getUserTrades.some(item=>item['orderType'] !== "sell");
    if(filter) return EventBus.publish("error", `You Have Already Set Sell Trade`); 

    let tokenAddres0 = props.pairDetails['token0Address'];
    let tokenAddres1 = props.pairDetails['token1Address'];
    let tokenName0 = props.pairDetails['token0Name'];
    let tokenName1 = props.pairDetails['token1Name'];
    
    let publicAddress = (await web3.currentProvider.enable())[0];
    
    let newPrice = web3.utils.toWei(price.toString(),"ether");
    let newTakeProfit = web3.utils.toWei(takeProfit.toString(),"ether");
    let newStopLose = web3.utils.toWei(stopLose.toString(),"ether");

    let grotERC20 = new web3.eth.Contract(ERC20Abi,tokenAddres0);
    let balanceOfUser = await grotERC20.methods.balanceOf(publicAddress).call();
    let fee = web3.utils.toWei("0.4","ether");
    fee = parseInt(fee)/parseInt(newPrice);
    newPrice = parseInt(newPrice) - parseInt(fee);
    if(balanceOfUser <= newPrice) return EventBus.publish("error", `You Have Insufficient Balance For Transaction`);
    fee = (fee).toString();
    fee = web3.utils.toWei(fee,'ether')
    props.setLoader({
      message: "Order Confirmation...",
      status: true,
    });
    await web3.eth
      .sendTransaction({
        from: publicAddress,
        value: 0,
        to: tokenAddres0,
        gas: 2000000,
        data: grotERC20.methods
          .approve(TradeBotAddress,(newPrice+fee).toString()).encodeABI(),
      })
      .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
            console.log(`*******receipt = `, receipt)
            props.setLoader({
              message: "Placing Order On Chain...",
              status: true,
            });

            // uint256[] memory _pairsAmount,address[] memory _pairs, string memory _orderType, uint256 _fee, string[] memory _pairsNames
            await web3.eth
              .sendTransaction({
                from: publicAddress,
                to: TradeBotAddress,
                gas: 2000000,
                data: TradeBotContract.methods
                  .setLimitOrder([newPrice.toString(),newStopLose,newTakeProfit],[tokenAddres0,tokenAddres1],"sell",fee,[tokenName0,tokenName1]).encodeABI(),
              })
              .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
                .on("receipt", async (receipt) => {
                  await props.setTradeOrder({publicAddress,tokenAddressA:tokenAddres0,tokenAddressB:tokenAddres1,pairs:`${tokenName0}-${tokenName1}`,price,stopLose,takeProfit,orderType:"sell",defaultPair:pairs,networkId});                    
                  await getUserTradesHavingSetTrades({pairs,networkId});
                  props.setLoader({
                    message: "Transaction Completed...",
                    status: false,
                  });
            });

            EventBus.publish("success", `Place Order Successfully!`);
      }); 

  } catch (e) {
    console.log("********Error = ", e);
    props.setLoader({
      message: "Transfer Not Completed...",
      status: false,
    });
    EventBus.publish("error", `Unable To Execute Tx`);
  }
};

const handleOnInputBuy = async (e) => {
  try {
    e.preventDefault();
    if(price == 0) return EventBus.publish("error", `Price is zero`); 
    if(stopLose == 0) return EventBus.publish("error", `Stop Lose is zero`); 
    if(takeProfit == 0) return EventBus.publish("error", `Take Profit is zero`); 
    let filter =props.getUserTrades.some(item=>item['orderType'] !== "buy");
    if(filter) return EventBus.publish("error", `You Have Already Set Buy Trade`); 

    let tokenAddres0 = props.pairDetails['token1Address'];
    let tokenAddres1 = props.pairDetails['token0Address'];
    let tokenName0 = props.pairDetails['token1Name'];
    let tokenName1 = props.pairDetails['token0Name'];
    let publicAddress = (await web3.currentProvider.enable())[0];
    
    let newPrice = web3.utils.toWei(price.toString(),"ether");
    let newTakeProfit = web3.utils.toWei(takeProfit.toString(),"ether");
    let newStopLose = web3.utils.toWei(stopLose.toString(),"ether");

    let grotERC20 = new web3.eth.Contract(ERC20Abi,tokenAddres0);
    let balanceOfUser = await grotERC20.methods.balanceOf(publicAddress).call();
    let fee = web3.utils.toWei("0.4","ether");
    fee = parseInt(fee)/parseInt(newPrice);
    newPrice = parseInt(newPrice) - parseInt(fee);
    if(balanceOfUser <= newPrice) return EventBus.publish("error", `You Have Insufficient Balance For Transaction`);
    fee = (fee).toString();
    fee = web3.utils.toWei(fee,'ether')
    props.setLoader({
      message: "Order Confirmation...",
      status: true,
    });
    await web3.eth
      .sendTransaction({
        from: publicAddress,
        value: 0,
        to: tokenAddres0,
        gas: 2000000,
        data: grotERC20.methods
          .approve(TradeBotAddress,(newPrice+fee).toString()).encodeABI(),
      })
      .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
            console.log(`*******receipt = `, receipt)
            props.setLoader({
              message: "Placing Order On Chain...",
              status: true,
            });

            // uint256[] memory _pairsAmount,address[] memory _pairs, string memory _orderType, uint256 _fee, string[] memory _pairsNames
            await web3.eth
              .sendTransaction({
                from: publicAddress,
                to: TradeBotAddress,
                gas: 2000000,
                data: TradeBotContract.methods
                  .setLimitOrder([newPrice.toString(),newStopLose,newTakeProfit],[tokenAddres0,tokenAddres1],"buy",fee,[tokenName0,tokenName1]).encodeABI(),
              })
              .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
                .on("receipt", async (receipt) => {
                  await props.setTradeOrder({publicAddress,tokenAddressA:tokenAddres0,tokenAddressB:tokenAddres1,pairs:`${tokenName0}-${tokenName1}`,price,stopLose,takeProfit,orderType:"buy",defaultPair:pairs,networkId});                    
                  await getUserTradesHavingSetTrades({pairs,networkId});
                  props.setLoader({
                    message: "Transaction Completed...",
                    status: false,
                  });
            });

            EventBus.publish("success", `Place Order Successfully!`);
      }); 

  } catch (e) {
    console.log("********Error = ", e);
    props.setLoader({
      message: "Transfer Not Completed...",
      status: false,
    });
    EventBus.publish("error", `Unable To Execute Tx`);
  }
};

const modelsSet = (e,type) =>{
  e.preventDefault()
  setModalStates(type);
  setPrice("")
  setStopLose("")
  setTakeProfit("")
}

const cancelTrade = async (data) =>{
  try {
    let publicAddress = (await web3.currentProvider.enable())[0];
    if(!publicAddress) return EventBus.publish("error", `Wallet Not Connect`); 
    props.setLoader({
      message: "Order Canceling...",
      status: true,
    });

    await web3.eth
    .sendTransaction({
      from: publicAddress,
      to: TradeBotAddress,
      gas: 2000000,
      data: TradeBotContract.methods
        .orderCancel([data['tokenAddressA'],data['tokenAddressB']],[data['price'],data['stopLose'],data['takeProfit']],[pairs.split("-")[0],pairs.split("-")[1]]).encodeABI(),
    })
    .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
      .on("receipt", async (receipt) => {
        await props.cancelTradeOrders({publicAddress:data['publicAddress'],pairs:data['pairs'],networkId,_id:data['_id']}); 
        
        setBalances({ publicAddress, tokenB, tokenA });
            let pairA = pairs.split("-")[0];
            let pairB = pairs.split("-")[1];
            publicAddress = publicAddress.toLowerCase();
            await socket.emit("orders:address:pairA:pairB",{publicAddress,pairs,networkId});
            await socket.emit("orders:address:pairA:pairB",{publicAddress,pairs,networkId});
            await socket.emit("pairA:pairB",{pairs,networkId});
            await socket.on(`${pairA}:${pairB}`, async data => {
              await props.getUserOrders({publicAddress,pairs}); 
              await props.setPairsData(data);
              await socket.on(`orders:${publicAddress}:${pairA}:${pairB}`, data => props.setAllUsersOrder(data));
            });

        // await getUserTradesHavingSetTrades({pairs,networkId});
        props.setLoader({
          message: "Transaction Completed...",
          status: false,
        });
  });

  } catch (e) {
    console.log("********Error = ", e);
    props.setLoader({
      message: "Transfer Not Completed...",
      status: false,
    });
    EventBus.publish("error", e || e.message);
  }
}
  return (
    <>
    {/* model trading bot */}
    {/* <Modal open={open} onClose={onCloseModal} center>
        <div className="w-full">
          <div className="pt-8">
            <div className="pt-2 p-2 pb-2  rounded-[10px] bg-[#010101] w-80 flex">

              <button
                className={`${
                  modalState === "Buy"
                    ? "bg-golden text-black"
                    : "bg-transparent text-golden"
                } w-full sand-bold py-3 rounded-xl `}
                onClick={() => modelsSet("Buy")}
              >
                Buy
              </button>
              <button
                className={`${
                  modalState === "Sell"
                    ? "bg-golden text-black"
                    : "bg-transparent text-golden"
                }  w-full sand-bold py-3 rounded-xl `}
                onClick={() => modelsSet("Sell")}
              >
                Sell
              </button>
            </div>
          
            <div className="mt-1">
              <label for="countries" class="block    text-[#010101] frank-demi">
                Pair
              </label>
              <input
              onWheel={handleOnWheel}      
              type="number"
                    className="w-full min-w-0   px-3 py-2.5 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px] border-[#F5F5F5] border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                    id="exampleText0"
                    placeholder={pairs}
                    disabled={true}
              />
            </div>

            <div className="mt-1">
              <div class="">
                <label
                  for="exampleText0"
                  class="form-label inline-block  text-[#010101] frank-demi"
                >
                  Set price
                </label>
                <div className="">
                  <input
                  onWheel={handleOnWheel}  
                  type="number"
                    className="w-full min-w-0   px-3 py-2.5 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px] border-[#F5F5F5] border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                    id="exampleText0"
                    placeholder=""
                    value={price}
                    onChange={e=>setPrice(e.target.value)}
                  />
                </div>
              </div>
              <div class="mt-1">
                <label
                  for="exampleText0"
                  class="form-label inline-block  text-[#010101] frank-demi"
                >
                  Set Stop Loss
                </label>
                <div className="">
                  <input
                  onWheel={handleOnWheel}  
                  type="number"
                    className="w-full min-w-0 shadow  px-3 py-2.5 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px] border-[#F5F5F5] border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                    id="exampleText0"
                    placeholder=""
                    value={stopLose}
                    onChange={e=>setStopLose(e.target.value)}
                  />
                </div>
              </div>
              <div class="mt-1">
                <label
                  for="exampleText0"
                  class="form-label inline-block  text-[#010101] frank-demi"
                >
                  Set Take Profit
                </label>
                <div className="">
                  <input
                  onWheel={handleOnWheel}  
                  type="number"
                    className="w-full min-w-0 shadow  px-3 py-2.5 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px] border-[#F5F5F5] border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                    id="exampleText0"
                    placeholder=""
                    value={takeProfit}
                    onChange={e=>setTakeProfit(e.target.value)}
                  />
                </div>
              </div>

              <div className="mt-4">
                {modalState == "Sell" ?
                <button className="bg-[#B99237] w-full  py-3 text-[#010101] text-[18px] sand-bold rounded-[10px]" onClick={handleOnInputSell}>
                  Schedule trade
                </button>
                :
                <button className="bg-[#B99237] w-full  py-3 text-[#010101] text-[18px] sand-bold rounded-[10px]" onClick={handleOnInputBuy}>
                  Schedule trade
                </button>
              }
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
     {/* model trading bot */}
      <div className="microeconomy-wrapper dashboard staking">
        <div className="microeconomy-head">
          <a href="#" className="logo-wrap">
              <img src={tradeiconnew} alt="" />

              <span>Trading</span>
          </a>
          <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
          <ConnectWallet />
        </div>

        <div className="trading-main-wrapper">
          <div className="price-depth tabs-wrapper">
            <ul className="nav nav-tabs" id="tab1" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="price-tab" data-toggle="tab" href="#price" role="tab" aria-controls="price" aria-selected="true">Price</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" id="depth-tab" data-toggle="tab" href="#depth" role="tab" aria-controls="depth" aria-selected="false">Depth</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" id="funding-tab" data-toggle="tab" href="#funding" role="tab" aria-controls="funding" aria-selected="false">Funding Rate</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="false">Details</a>
              </li>
            </ul>
            <div className="tab-content" id="tab1Content">
              <div className="tab-pane fade show active" id="price" role="tabpanel" aria-labelledby="price-tab">
              <div id="chart-candlestick">
                <Chart options={options} series={graphData} type="candlestick" height={'100%'} />
              </div>
              </div>
              <div className="tab-pane fade" id="depth" role="tabpanel" aria-labelledby="depth-tab">
                <div id="depth-chart">
                  <div id="zc"></div>
                </div>
                  
                  {/* <ZingChart data={config} /> */}
              </div>
              <div className="tab-pane fade" id="funding" role="tabpanel" aria-labelledby="funding-tab">3</div>
              <div className="tab-pane fade" id="details" role="tabpanel" aria-labelledby="details-tab">4</div>
            </div>
          </div>

          <div className="select-market tabs-wrapper">
            <div className="head">
                <div className="">
                  {/* <img src={ethicon}/> */}

                  <span>{pairs}</span>
                </div>
                {/* set pair of data */}
                <select onChange={(e) => pairsFunction(e.target.value)}>
                  {allPairs && allPairs.length > 0 &&
                      allPairs.map(item=>
                        <option value={item['token0Name']+"-"+item['token1Name']}> {item['token0Name']+"-"+item['token1Name']} </option>
                      )
                    }
                </select>
            </div>

            <ul className="nav nav-tabs" id="tab2" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="limit-tab" data-toggle="tab" href="#limit" role="tab" aria-controls="limit" aria-selected="true" onClick={()=>buySellFunction("Buy")}>Buy</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" id="market-tab" data-toggle="tab" href="#market" role="tab" aria-controls="market" aria-selected="false" onClick={()=>buySellFunction("Sell")}>Sell</a>
              </li>
              <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => setBuySellModal(true)}>
                {/* <a className="nav-link" id="stop-tab" data-toggle="tab" href="#stop" role="tab" aria-controls="stop" aria-selected="false"> */}
                   Trade Bot
                </a>
              </li>
            </ul>
              
            <div className="tab-content" id="tab2Content">
                {/* buy */}
              <div className="tab-pane fade show active" id="limit" role="tabpanel" aria-labelledby="limit-tab">
              <div className="form-wrap">
                  <form onSubmit={marketType == "limit" ? buyTokens : buyMarketTokens}>
                     <div className="form-group">
                     <label>Type</label>
                     <div className="currency-label">
                      <select onChange={(e) => setMarketType(e.target.value)} value={marketType}>
                        <option value="limit">Limit</option>
                        <option value="market">Market</option>
                      </select>
                      </div>
                      </div>
                      <label>Assets</label>
                      {marketType == "limit" && (
                      <div className="form-group">
                        <input
                            onWheel={handleOnWheel}
                            type="number"
                            name='order-price'
                            placeholder="Order Price"
                            defaultValue={buyOrderPrice}
                            onChange={(e) => setBuyOrderPrice(e.target.value)}
                        />

                        <div className="currency-label">
                          <span>{pairs.split("-")[1]}</span>
                        </div>
                      </div>
                      )}
                      <div className="form-group">
                        <input
                            onWheel={handleOnWheel}
                            type="number"
                            placeholder="QTY"
                            value={buyOrderAmount}
                            onChange={(e) => setBuyOrderAmount(e.target.value)}
                        />

                        <div className="currency-label">
                          <span>{pairs.split("-")[0]}</span>
                        </div>

                        {/* <div className="currency-label">
                          <select>
                            <option selected value="eth">ETH</option>
                            <option value="bsc">BSC</option>
                            <option value="usd">USD</option>
                            <option value="nft">NFT</option>
                          </select>
                        </div> */}
                      </div>

                     {/* <div className="rangeslider-wrap">
                        <Slider
                        min={0}
                        max={8}
                        step={2}
                        allowCross={false}
                        reverse={false}
                        marks={marks}
                        trackStyle={trackStyle}
                        draggableTrack={true}
                        dragRange={true}
                        clickableTrack={false}
                        />
                        <div> 
                       {value[0]} - {value[1]} 
                         </div>
                      </div>  */}

                      {/* <div className="limit-wrap">
                        <div className="radio-wrapper">
                          <p>
                            <input type="radio" id="tp/sl" name="radio-group" />
                            <label for="tp/sl">
                              <img src={subtabicon} ></img>
                              <img src={subtabfillicon} className="fill"></img>
                              TP/SL
                            </label>

                            <div className="form-group">
                              <input
                              onWheel={handleOnWheel}    
                              type="number"
                                  name='profit'
                                  placeholder="Take Profit"
                              />
                            </div>

                            <div className="form-group">
                              <input
                              onWheel={handleOnWheel}    
                              type="number"
                                  name='loss'
                                  placeholder="Stop Loss"
                              />
                            </div>
                          </p>
                        </div>

                        <div className="radio-wrapper">
                          <p>
                            <input type="radio" id="reduce" name="radio-group"/>
                            <label for="reduce">
                              <img src={subtabicon} ></img>
                              <img src={subtabfillicon} className="fill"></img>
                              Reduce-Only
                            </label>

                            <div className="select-wrap">
                              <span>TIF</span>
                              
                              <select>
                                <option selected value="gtc">GTC</option>
                                <option value="bsc">BSC</option>
                                <option value="usd">USD</option>
                                <option value="nft">NFT</option>
                              </select>
                            </div>
                          </p>
                        </div>
                      </div> */}

                      <div className="fee-info">
                          {/* <div className="info">
                            <p>Fee</p>
                            <p>-</p>
                          </div> */}
                          <div className="info">
                            <p>Total</p>
                            <p>{parseFloat(balanceOfTokenB).toFixed(3)}</p>
                          </div>
                      </div>
                      
                        <button type="submit">
                          Buy
                        </button>
                  </form>
                </div>
              </div>
                {/* sell */}
              <div className="tab-pane fade" id="market" role="tabpanel" aria-labelledby="market-tab">
              <div className="form-wrap">
                  <form onSubmit={marketType == "limit" ? sellTokens : sellMarketTokens}>
                     <div className="form-group">
                     <label>Type</label>
                     <div className="currency-label">
                      <select onChange={(e) => setMarketType(e.target.value)} value={marketType}>
                        <option value="limit">Limit</option>
                        <option value="market">Market</option>
                      </select>
                      </div>
                      </div>
                      <label>Assets</label>
                      {marketType == "limit" && (
                      <div className="form-group">
                        <input
                            onWheel={handleOnWheel}
                            type="number"
                            name='order-price'
                            placeholder="Order Price"
                            defaultValue={sellOrderPrice}
                            onChange={(e) => setSellOrderPrice(e.target.value)}
                        />

                        <div className="currency-label">
                          <span>{pairs.split("-")[1]}</span>
                        </div>
                      </div>
                      )}
                      <div className="form-group">
                        <input
                            onWheel={handleOnWheel}
                            type="number"
                            placeholder="QTY"
                            value={sellOrderAmount}
                            onChange={(e) => setSellOrderAmount(e.target.value)}
                        />

                        <div className="currency-label">
                          <span>{pairs.split("-")[0]}</span>
                        </div>
                      </div>

                      <div className="fee-info">
                          <div className="info">
                            <p>Total</p>
                            <p>{parseFloat(balanceOfTokenA).toFixed(3)}</p>
                          </div>
                      </div>
                      
                        <button type="submit">
                          Sell
                        </button>
                  </form>
                </div>
              </div>
              <div className="tab-pane fade" id="stop" role="tabpanel" aria-labelledby="stop-tab">
                <p className="p-5 text-white">Nothing to show for now</p>
              </div>
            </div>
          </div>
 
          <div className="table-info tabs-wrapper">
            <ul className="nav nav-tabs" id="tab3" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="positions-tab" data-toggle="tab" href="#positions" role="tab" aria-controls="positions" aria-selected="true">Trades</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" id="profitloss-tab" data-toggle="tab" href="#profitloss" role="tab" aria-controls="profitloss" aria-selected="false">Pending</a>
              </li>

              {/* <li className="nav-item">
                <a className="nav-link" id="scheduledtrades-tab" data-toggle="tab" href="#scheduledtrades" role="tab" aria-controls="scheduledtrades" aria-selected="false">Scheduled Trades</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" id="filled-tab" data-toggle="tab" href="#filled" role="tab" aria-controls="filled" aria-selected="false">Filled</a>
              </li>

              <li className="nav-item">
                <a className="nav-link active" id="tradinghistory-tab" data-toggle="tab" href="#tradinghistory" role="tab" aria-controls="tradinghistory" aria-selected="false">Trading History</a>
              </li> */}
            </ul>
            <div className="tab-content" id="tab3Content">
              <div className="tab-pane fade show active" id="positions" role="tabpanel" aria-labelledby="positions-tab">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <th>Price</th>
                        <th>Stop Loss</th>
                        <th>Profit</th>
                        {/* <th>Stop Loss</th>
                        <th>Take Profit</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr> */}
                      {props.getUserTrades && props.getUserTrades.length > 0 && props.getUserTrades.map((value, index) => (
                        <tr key={index}>
                          <td >{value.price}</td>
                          <td >{value.stopLose}</td>
                          <td >{value.takeProfit}</td>
                        </tr>
                      ))}
                        {/* <td><img src={bitcoin}/> ETH - USDT</td>
                        <td>BUY</td>
                        <td>12:34:07</td>
                        <td>12:34:07</td>
                        <td>-</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="profitloss" role="tabpanel" aria-labelledby="profitloss-tab">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <th className="px-2 py-3">Price</th>
                        <th className="px-2 py-3">Stop Loss</th>
                        <th className="px-2 py-3">Profit</th>
                        {/* <th className="w-14 px-2 py-3">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {props.getUserTrades && props.getUserTrades.length > 0 && props.getUserTrades.filter(item=>item['status'] == "pending").map((value, index) => (
                        <tr className="border-b border-cultured" key={index}>
                          <td className="px-2 py-3">{value.price}</td>
                          <td className="px-2 py-3 text-red">{value.stopLose}</td>
                          <td className="px-2 py-3 text-[#17c517]">{value.takeProfit}</td>
                          {/* <td>
                            <button className="font-semibold tracking-widest text-xs text-red font-bold px-2 py-3" onClick={()=>cancelTrade(value)}>
                              Cancel
                            </button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="tab-pane fade" id="scheduledtrades" role="tabpanel" aria-labelledby="scheduledtrades-tab">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <th>Pair</th>
                        <th>Type</th>
                        <th>Execution Time</th>
                        <th>Stop Loss</th>
                        <th>Take Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><img src={bitcoin}/> ETH - USDT</td>
                        <td>BUY</td>
                        <td>12:34:07</td>
                        <td>12:34:07</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="filled" role="tabpanel" aria-labelledby="filled-tab">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <th>Pair</th>
                        <th>Type</th>
                        <th>Execution Time</th>
                        <th>Stop Loss</th>
                        <th>Take Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><img src={bitcoin}/> ETH - USDT</td>
                        <td>BUY</td>
                        <td>12:34:07</td>
                        <td>12:34:07</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade show active" id="tradinghistory" role="tabpanel" aria-labelledby="tradinghistory-tab">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <th>Pair</th>
                        <th>Type</th>
                        <th>Execution Time</th>
                        <th>Stop Loss</th>
                        <th>Take Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><img src={bitcoin}/> ETH - USDT</td>
                        <td>BUY</td>
                        <td>12:34:07</td>
                        <td>12:34:07</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
            </div>
          </div>

          <div className="book-trades tabs-wrapper">
            <ul className="nav nav-tabs" id="tab4" role="tablist">
              <li className="nav-item">
                <a className="nav-link" id="book-tab" data-toggle="tab" href="#book" role="tab" aria-controls="book" aria-selected="false">Order Book</a>
              </li>

              <li className="nav-item">
                <a className="nav-link active" id="trades-tab" data-toggle="tab" href="#trades" role="tab" aria-controls="trades" aria-selected="true">Trades</a>
              </li>
            </ul>
            <div className="tab-content" id="tab4Content">
               {/* Book */}
              <div className="tab-pane fade" id="book" role="tabpanel" aria-labelledby="book-tab">
                <div className="table-wrap">
                    <table>
                      <thead>
                        <tr>
                          <th>Price</th>
                          <th>Amount</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                    {allOrders.length > 0 && allOrders.map((value, index) => (
                      <tr className={value['orderType'] =='buy' ? "red" : "green" }>
                      <td>{value['price']}</td>
                      <td>{value['amount']}</td>
                      <td>{moment(value['createdAt']).format("MM-DD-YYYY")}</td>
                    </tr>
                    ))}
                  {/* <tr className="red"> */}
                      {/* <tr>
                          <td>1,900.11</td>
                          <td>0.05 ETH</td>
                          <td>13:55:02</td>
                        </tr> */}

                        {/* <tr className="green"> */}
                        {/* <tr className="green">
                          <td>1,900.11</td>
                          <td>0.05 ETH</td>
                          <td>13:55:02</td>
                        </tr> */}

                      </tbody>
                    </table>
                </div>
                  {/* <p className="p-5 text-white">Nothing to show for now</p> */}
              </div>
              {/* trades */}
              <div className="tab-pane fade show active" id="trades" role="tabpanel" aria-labelledby="trades-tab">
                <div className="table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <th>Price</th>
                        <th>Amount</th>
                        <th>Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody> 
                      {props.userOrders && props.userOrders.length > 0 && props.userOrders.map(item =>
                        <tr className={item['orderType'] =='buy' ? "red" : "green" }>
                          <td>{item['price']}</td>
                          <td>{item['amount']}</td>
                          <td>{moment(item['createdAt']).format("MM-DD-YYYY")}</td>
                          <td className={item['status'] == "Success" ? "green" : "red"}>{item['status']}</td>
                        </tr>
                      )}

                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <Modal
        open={buysellModal}
        onClose={() => setBuySellModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal add-liquidity`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setBuySellModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <form>

              <div className="buy-sell-wrap mb-5">
              <button
                className={`${
                  modalState === "Buy"
                    ? "active"
                    : ""
                }  `}
                onClick={(e) => modelsSet(e,"Buy")}
              >
                Buy
              </button>
              <button
                className={`${
                  modalState === "Sell"
                    ? "active"
                    : ""
                } `}
                onClick={(e) => modelsSet(e,"Sell")}
              >
                Sell
              </button>
              </div>

              <div className="mb-5">
                <label for="countries" class="block    text-[#010101] frank-demi">
                  Pair
                </label>

                <div className="form-group">
                  <input
                        onWheel={handleOnWheel}
                        type="number"
                        className="w-full min-w-0   px-3 py-2.5 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px] border-[#F5F5F5] border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                        id="exampleText0"
                        placeholder={pairs}
                        disabled={true}
                  />
                </div>
              </div>
              <div className="mb-5">
                <label
                  for="exampleText0"
                  class="form-label inline-block  text-[#010101] frank-demi"
                >
                  Set price
                </label>
                <div  className="form-group">
                  <input
                    onWheel={handleOnWheel}
                    type="number"
                    className="w-full min-w-0   px-3 py-2.5 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px] border-[#F5F5F5] border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                    id="exampleText0"
                    placeholder=""
                    value={price}
                    onChange={e=>setPrice(e.target.value)}
                  />
                </div>
              </div>
              
              <div class="mb-5">
                <label
                  for="exampleText0"
                  class="form-label inline-block  text-[#010101] frank-demi"
                >
                  Set Stop Loss
                </label>
                <div  className="form-group">
                  <input
                    onWheel={handleOnWheel}
                    type="number"
                    className="w-full min-w-0 shadow  px-3 py-2.5 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px] border-[#F5F5F5] border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                    id="exampleText0"
                    placeholder=""
                    value={stopLose}
                    onChange={e=>setStopLose(e.target.value)}
                  />
                </div>
              </div>
              <div class="mb-5">
                <label
                  for="exampleText0"
                  class="form-label inline-block  text-[#010101] frank-demi"
                >
                  Set Take Profit
                </label>
                <div  className="form-group">
                  <input
                    onWheel={handleOnWheel}
                    type="number"
                    className="w-full min-w-0 shadow  px-3 py-2.5 text-base font-medium text-black bg-[#F5F5F5] rounded-[10px] border-[#F5F5F5] border     focus:text-gray-700 focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:outline-none "
                    id="exampleText0"
                    placeholder=""
                    value={takeProfit}
                    onChange={e=>setTakeProfit(e.target.value)}
                  />
                </div>
              </div>

              <div>
                {modalState == "Sell" ?
                <button  onClick={handleOnInputSell}>
                  Schedule trade
                </button>
                :
                <button onClick={handleOnInputBuy}>
                  Schedule trade
                </button>
              }
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
const mapDispatchToProps = { setSinglePair,getExchangeGraph, setLoader, setOrderBook, getLimitOrderBook, getUserOrders, updateGraph, setPairsBalance, setExchangePairsDetails, cancelTradeOrders, getSingleTradeOrders, setPairsData, setAllUsersOrder,setTradeOrder };

const mapStateToProps = ({ Auth, Sockets }) => {
  let { getOrderBook,address, singlePair,getUserTrades,selectDefaultNetwok, currentMode } = Auth;
  let { pairDetails, pairBalance,userOrders } = Sockets;
  return { getOrderBook, address, singlePair, pairDetails, pairBalance, getUserTrades,userOrders,selectDefaultNetwok, currentMode };
};
export default connect(mapStateToProps, mapDispatchToProps)(Trades);
