import React, { useState } from "react";
import EventBus from "eventing-bus";
import {
  networkId,
  ERC20Abi,
  OrderBookContract,
  OrderBookAddress,
  GrootFactory,
  GrootBinanceFactoryContract,
  GrootRouterAddress,
  GrootRouter,
  GrootBinanceAddress,
  ArbitrageBotABI,
  ArbitrageBotContract,
  ArbitrageBotAddress
} from "../../store/config";
import { web3 } from "../../store/web3";

export const getBestGrootBNBTokenAmount = async (amountIn, pairs) => {
  return new Promise(async (resolve, reject) => {
      let getAmount = await GrootRouter.methods
        .getAmountsOut(amountIn, pairs)
        .call();
      if (getAmount) resolve(getAmount);
    });
};

export const getBinanceExchangePair = async (addressA, addressB) => {
  return new Promise(async (resolve, reject) => {
    let getAmount = await GrootBinanceFactoryContract.methods
      .getPair(addressA, addressB)
      .call();
    if (getAmount) return resolve(1);
    return resolve(0);
  });
};

export const getGrootBNBExchangePair = async (addressA, addressB) => {
 return new Promise(async (resolve, reject) => {
    let getAmount = await GrootFactory.methods
      .getPair(addressA, addressB)
      .call();
    if (getAmount == "0x0000000000000000000000000000000000000000") return reject("pairs not found");
    return resolve(1);
  });
};

export const exchangeApproved = async (from,tokenAddress, amountIn, ArbitrageBotContract) => {
  return new Promise(async (resolve, reject) => {
   try {
    let grotERC20 = new web3.eth.Contract(ERC20Abi,tokenAddress);
    await web3.eth
    .sendTransaction({
      from,
      value: 0,
      to: tokenAddress,
      gas: 2000000,
      data: grotERC20.methods
        .approve(ArbitrageBotContract,amountIn).encodeABI(),
    })
    .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
      .on("receipt", async (receipt) => {
        resolve(receipt);
    });
   } catch (error) {
    reject(error);
   }
  });
};

export const arbitrageTransactions = async (from,_path,_amountIn,_minamount,_fee,_routerAddress) => {
  return new Promise(async (resolve, reject) => {
   try {
    await web3.eth
    .sendTransaction({
      from,
      value: 0,
      to: ArbitrageBotAddress,
      gas: 2000000,
      data: OrderBookContract.methods
        .sellBuyMarketPriceTokensDC(_path,_amountIn,_minamount,_fee,_routerAddress).encodeABI(),
    })
    .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
      .on("receipt", async (receipt) => {
        resolve(receipt);
    });
   } catch (error) {
    reject(error);
   }
  });
};

export const swapTokens = async (from,_path,_amountIn,_minamount,_fee,_routerAddress) => {
  return new Promise(async (resolve, reject) => {
   try {

    let timestemp = Math.floor((new Date().getTime() +(2 * 60 * 1000)) / 1000);
    await web3.eth
    .sendTransaction({
      from,
      value: 0,
      to: GrootRouterAddress,
      gas: 2000000,
      data: GrootRouter.methods
        .swapExactTokensForTokens(_amountIn,_minamount,_path,from,timestemp).encodeABI(),
    })
    .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
      .on("receipt", async (receipt) => {
        resolve(receipt);
    });
   } catch (error) {
    reject(error);
   }
  });
};
