import { useState } from "react";
import { Link } from "react-router-dom";
import sunicon from "../../images/sun-icon.svg";
import moonicon from "../../images/moon-icon.svg";
import { useLocation } from "react-router-dom";
import Logo from "../../images/groot-logo.svg";
import subtabicon from "../../images/menu-sub-tab-icon.svg";
import borrowicon from "../../images/borrow-icon.png";
import supporticon from "../../images/support-icon.svg";
import pooliconnew from "../../images/pool-icon-new.png";
import swapiconnew from "../../images/swap-icon-new.png";
import documenticon from "../../images/document-icon.svg";
import daogenesisicon from "../../images/dao-genesis.png";
import sidebararrow from "../../images/sidebar-arrow.svg";
import stakeiconnew from "../../images/stake-icon-new.png";
import tradeiconnew from "../../images/trade-icon-new.png";
import dashboardicon from "../../images/dashboard-icon.png";
import subtabfillicon from "../../images/subtab-fill-icon.svg";
import offeringiconnew from "../../images/offering-icon-new.png";
import microeconomyicon from "../../images/microeconomy-icon.png";
import blockfund from "../../images/blockfund.png";
import viewpool from "../../images/view-pool-new.png";
import liquidityminingicon from "../../images/liquidity-mining.png";


export default function SideBar({ isActive, setIsActive, mode, setMode, kycStatus, kybStatus, isWhitelist }) {
  const getPaths = useLocation();
  const onOpen = () => SetOPen(true);
  const [Open, SetOPen] = useState(false);
  const [microIsExpanded, setmicroIsExpanded] = useState(false);
  const [daoIsExpanded, setdaoIsExpanded] = useState(false);
  const [circularityDao, setcircularityDao] = useState(false);
  const [blockfundIsExpanded, setblockfundIsExpanded] = useState(false);
  const [liquidiyMining, setLiquidityMining] = useState(false);

  const sidebarArrow = () => {
    setIsActive(!isActive);
  };

  const toggleExpandMicro = () => {
    setmicroIsExpanded(!microIsExpanded);
  };

  const toggleExpandBlockFund = () => {
    setblockfundIsExpanded(!blockfundIsExpanded);
  };

  const toggleExpandDao = () => {
    setdaoIsExpanded(!daoIsExpanded);
  };

  const toggleExpandCircularityDao = () => {
    setcircularityDao(!circularityDao);
  };

  const toggleExpandLiquidityMining = () => {
    setLiquidityMining(!liquidiyMining);
  };
 
  const widthCheck = window.innerWidth < 2300;

  return (
    <>
      <div className={isActive ? 'bg-open active' : 'bg-open '}>
      </div>

      <button className={isActive ? 'sidebar-arrow active' : 'sidebar-arrow'} onClick={sidebarArrow}>
        <img src={sidebararrow} />
      </button>

      <div className={isActive ? 'overflow-x-hidden sidebar px-3 w-auto sm:w-12 md:w-20 min-w-[290px] open' : 'overflow-x-hidden sidebar px-3 w-auto sm:w-12 md:w-20 min-w-[80px]'}>
        <div
          className={`${Open ? "font-medium pb-8  rounded-none text-center" : "font-medium pb-10 rounded-none xl:rounded-[26px] text-center"
            } `}>
          <div className="text-gray-100 ">
            <div className="flex px-3 xl:pr-2 items-center items-center mt-9 mb-4">
              <img width="34" src={Logo} alt="logo" className="mr-3" />

              <span className={isActive ? 'text-[16px] ' : 'text-[16px] minibar'}  >Circularity Finance</span>
            </div>
          </div>

          <ul className="mt-2 block xl:mt-4">
            {/* <li>
              <Link to="/dashboard">
                <div
                  className=" xl:W-20"

                  onClick={onOpen}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={dashboardicon} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Dashboard
                    </span>

                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/dashboard/trading">
                <div
                  className=" xl:W-20"
                  onClick={onOpen}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/trading" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={tradeiconnew} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Trading
                    </span>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/dashboard/borrow">
                <div
                  className=" xl:W-20"
                  onClick={onOpen}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/borrow" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={borrowicon} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Borrow
                    </span>

                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/dashboard/stability">
                <div
                  className=" xl:W-20"
                  onClick={onOpen}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/stability" &&
                      ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white `}
                  >
                    <img src={pooliconnew} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Stability pool
                    </span>

                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/dashboard/stake">
                <div
                  className=" xl:W-20"
                  onClick={onOpen}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/stake" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={stakeiconnew} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Staking
                    </span>

                  </div>
                </div>
              </Link>
            </li> 
            <li>
              <Link to="/dashboard/initial-offer">
                <div
                  className={`${!Open ? " xl:W-20" : "  xl:min-w-[280px]"
                    }`}
                  onClick={onOpen}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/initial-offer" &&
                      ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={offeringiconnew} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      ILO
                    </span>

                  </div>
                </div>
              </Link>
            </li> */}

            <li>
              <Link to="/dashboard/microeconomy">
                <div
                  className=" xl:W-20"
                  onClick={toggleExpandMicro}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/microeconomy" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={microeconomyicon} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Micro Economy
                    </span>

                  </div>
                </div>
              </Link>

              {/* {microIsExpanded && (
                (isWhitelist == true) || (kycStatus == "adminApproved" && kybStatus == "approved") ?
                <ul className={isActive ? "" : 'minibar'}>
                 <li>
                    <Link to="/dashboard/microeconomy/verification">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/verification" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Verification
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/profile">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/profile" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Profile
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/deploy-your-token">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/deploy-your-token" && ""
                            } h-[30px]  flex items-center px-3 pl-14   duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Deploy Your Token
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/create-nft">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/create-nft" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Create NFT Collection
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>
                  
                  <li>
                    <Link to="/dashboard/microeconomy/token-airdrop">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/token-airdrop" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            NFT Airdrop Tool
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/stake-generation">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/stake-generation" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Stake Generation
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/deploy-vesting">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/deploy-vesting" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Deploy Vesting
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>
                  
                  <li>
                    <Link to="/dashboard/microeconomy/vesting-schedules">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/vesting-schedules" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Vesting Schedules
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/initial-offer">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/initial-offer" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Initial Liquidity Offering
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/nft-drops">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/nft-drops" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Explore NFT Collections
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/stake-nft">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/stake-nft" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Stake Your NFT´s
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                </ul>
                :
                kycStatus == "adminApproved" && kybStatus !== "approved"?
                <ul className={isActive ? "" : 'minibar'}>
                
                <li>
                    <Link to="/dashboard/microeconomy/verification">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/verification" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Verification
                          </span>

                        </div>
                      </div>
                    </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/profile">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/profile" && ""
                          } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Profile
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
                
                <li>
                  <Link to="/dashboard/microeconomy/vesting-schedules">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/vesting-schedules" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Vesting Schedules
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/initial-offer">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/initial-offer" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Initial Liquidity Offering
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/stake-nft">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/stake-nft" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Stake Your NFT´s
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/nft-drops">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/nft-drops" && ""
                          } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Explore NFT Collections
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                </ul>
                :
                kybStatus == "approved" && kycStatus !== "adminApproved"?
                <ul className={isActive ? "" : 'minibar'}>
                <li>
                    <Link to="/dashboard/microeconomy/verification">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/verification" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Verification
                          </span>

                        </div>
                      </div>
                    </Link>
                </li>
                
                <li>
                  <Link to="/dashboard/microeconomy/profile">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/profile" && ""
                          } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Profile
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/deploy-your-token">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/deploy-your-token" && ""
                          } h-[30px]  flex items-center px-3 pl-14   duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Deploy Your Token
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/create-nft">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/create-nft" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Create NFT Collection
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/token-airdrop">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/token-airdrop" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          NFT Airdrop Tool
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/stake-generation">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/stake-generation" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Stake Generation
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/deploy-vesting">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/deploy-vesting" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Deploy Vesting
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/microeconomy/vesting-schedules">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/vesting-schedules" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Vesting Schedules
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
                
                <li>
                  <Link to="/dashboard/microeconomy/initial-offer">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/initial-offer" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Initial Liquidity Offering
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
                
                <li>
                  <Link to="/dashboard/microeconomy/nft-drops">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/microeconomy/nft-drops" && ""
                          } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Explore NFT Collections
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
                </ul>
                :
                <ul className={isActive ? "" : 'minibar'}>
                  <li>
                    <Link to="/dashboard/microeconomy/verification">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/verification" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Verification
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              )} */}
              {microIsExpanded && (
                <ul className={isActive ? "" : 'minibar'}>
                  <li>
                    <Link to="/dashboard/microeconomy/verification">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/verification" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Verification
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/profile">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/profile" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Profile
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/deploy-your-token">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/deploy-your-token" && ""
                            } h-[30px]  flex items-center px-3 pl-14   duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Deploy Your Token
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/create-nft">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/create-nft" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Create NFT Collection
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/nft-airdrop">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/nft-airdrop" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            NFT Airdrop Tool
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/token-airdrop">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/token-airdrop" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Token Airdrop Tool
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/stake-generation">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/stake-generation" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Stake Generation
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/lp-stake-generation">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/lp-stake-generation" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            LP Stake Generation
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/deploy-vesting">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/deploy-vesting" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Deploy Vesting
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/vesting-schedules">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/vesting-schedules" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Vesting Schedules
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/initial-offer">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/initial-offer" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Initial Liquidity Offering
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/nft-drops">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/nft-drops" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Explore NFT Collections
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/explore-token">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/explore-token" && ""
                            } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Explore Crypto Tokens
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/stake-nft">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/stake-nft" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Stake Your NFT´s
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dashboard/microeconomy/stake-lp">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/stake-lp" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Stake Your LP Tokens
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li>

                  {/* <li>
                    <Link to="/dashboard/microeconomy/project-detail-steps">
                      <div
                        className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                          }`}
                        onClick={onOpen}
                      >
                        <div
                          class={`${getPaths.pathname === "/dashboard/microeconomy/project-detail-steps" && ""
                            } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                        >
                          <img src={subtabicon} alt="" className="" />
                          <img src={subtabfillicon} className="fill" alt="" />


                          <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                            Project Detail
                          </span>

                        </div>
                      </div>
                    </Link>
                  </li> */}

                </ul>
              )}
            </li>

            {/* <li>
              <Link to="/dashboard/blockfund/erc-token">
                <div
                  className=" xl:W-20"
                  onClick={toggleExpandBlockFund}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/blockfund/erc-token" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={blockfund} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      BlockFund
                    </span>

                  </div>
                </div>
              </Link>

              {blockfundIsExpanded && (
               kycStatus == "adminApproved" &&
              <ul className={isActive ? "" : 'minibar'}>
                <li>
                  <Link to="/dashboard/blockfund/erc-token">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/blockfund/erc-token" && ""
                          } h-[30px]  flex items-center  px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                        ERC-1155 Token
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/blockfund/donations">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/blockfund/donations" && ""
                          } h-[30px]  flex items-center px-3 pl-14   duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                        Donations
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/blockfund/add-donations">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/blockfund/add-donations" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                        Add Donations
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/blockfund/update-donations">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/blockfund/update-donations" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                        Update Donations
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/blockfund/go-fund">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/blockfund/go-fund" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Go Fund
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
              )}
            </li> */}

            <li>
              <Link to="/dashboard/swap">
                <div
                  className=" xl:W-20"
                  onClick={onOpen}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/swap" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={swapiconnew} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Swap
                    </span>

                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/dashboard/view-pools">
                <div
                  className=" xl:W-20"
                  onClick={onOpen}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/view-pools" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={viewpool} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      View-Pools
                    </span>

                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/dashboard/dao-genesis/launch-dao">
                <div
                  className=" xl:W-20"
                  onClick={toggleExpandDao}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/dao-genesis" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={daogenesisicon} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      DAO Genesis
                    </span>

                  </div>
                </div>
              </Link>

              {daoIsExpanded && (<ul className={isActive ? "" : 'minibar'}>
                {/* <li>
                  <Link to="/dashboard/dao-genesis/launch-daos">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/dao-genesis/launch-daos" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Launch a DAOs
                        </span>

                      </div>
                    </div>
                  </Link>
                </li> */}

                <li>
                  <Link to="/dashboard/dao-genesis/launch-dao">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/dao-genesis/launch-dao" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Launch a DAO
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/dao-genesis/view-daos">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/dao-genesis/view-daos" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          View all DAOs
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
              </ul>)}
            </li>

            <li>
              <Link to="/dashboard/circularity-dao">
                <div
                  className=" xl:W-20"
                  onClick={toggleExpandCircularityDao}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/circularity-dao" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={daogenesisicon} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Circularity DAO
                    </span>

                  </div>
                </div>
              </Link>

              {circularityDao && (<ul className={isActive ? "" : 'minibar'}>
                <li>
                  <Link to="/dashboard/circularity-dao">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/circularity-dao" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          All Proposals
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>

                <li>
                  <Link to="/dashboard/circularity-dao/stake-nft">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/circularity-dao/stake-nft" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Stake NFT
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
              </ul>)}
            </li>

            <li>
              <Link to="/dashboard/liquidity-mining-1">
                <div
                  className=" xl:W-20"
                  onClick={toggleExpandLiquidityMining}
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/liquidity-mining-1" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={liquidityminingicon} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Liquidity Mining
                    </span>

                  </div>
                </div>
              </Link>

              {liquidiyMining && (<ul className={isActive ? "" : 'minibar'}>
                <li>
                  <Link to="/dashboard/liquidity-mining-1">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/liquidity-mining-1" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Stability Pool 1
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/liquidity-mining-2">
                    <div
                      className={`${!Open ? " xl:W-20" : " xl:min-w-[280px]"
                        }`}
                      onClick={onOpen}
                    >
                      <div
                        class={`${getPaths.pathname === "/dashboard/liquidity-mining-2" && ""
                          } h-[30px]  flex items-center px-3 pl-14  duration-300 cursor-pointer  text-white`}
                      >
                        <img src={subtabicon} alt="" className="" />
                        <img src={subtabfillicon} className="fill" alt="" />


                        <span class="text-[14px] ml-2 text-[#fff] font-medium xl:block">
                          Stability Pool 2
                        </span>

                      </div>
                    </div>
                  </Link>
                </li>
              </ul>)}
            </li>

            {/* <li>
              <Link to="/dashboard/circularity-dao">
                <div
                  className=" xl:W-20"
                >
                  <div
                    class={`${getPaths.pathname === "/dashboard/circularity-dao" && ""
                      } h-[50px] flex items-center px-2 mbl:px-3  duration-300 cursor-pointer  text-white`}
                  >
                    <img src={daogenesisicon} alt="" className="w-6" />
                    <span className={isActive ? 'text-[16px]  ml-4 text-[#fff] font-medium xl:block ' : 'text-[16px]  ml-4 text-[#fff] font-medium xl:block minibar'}>
                      Circularity DAO
                    </span>

                  </div>
                </div>
              </Link>
              </li> */}
          </ul>


          <form className={`dropdown-doc mt-6 px-2 text-left mbl:px-3 ${(widthCheck && microIsExpanded) ? 'dropdown-doc-expanded' : ''}`} action="#">
            {/* <ul class="bottom-links">
              <li class="mb-4">
                <Link to="/dashboard/" className="flex items-center">
                  <img src={supporticon} alt="" className="xl:inline-block" />
                  <span className={isActive ? 'text-[14px] ml-3 text-[#fff] font-medium xl:inline-block' : 'text-[14px] ml-3 text-[#fff] font-medium xl:inline-block minibar'}>
                    Support
                  </span>
                </Link>
              </li>
              <li class="mb-4">
                <Link to="/dashboard/" className="flex items-center">
                  <img src={documenticon} alt="" className="xl:inline-block" />
                  <span className={isActive ? 'text-[14px] ml-3 text-[#fff] font-medium xl:inline-block' : 'text-[14px] ml-3 text-[#fff] font-medium xl:inline-block minibar'}>
                    Documentation
                  </span>
                </Link>
              </li>
            </ul> */}
            <div className={isActive ? 'radio-wrap' : 'radio-wrap radio-wrap-mini '}>
              <p>
                <input type="radio" id="light" name="radio-group" onClick={() => setMode(true)} />
                <label for="light">
                  {/* <img src={sunicon} alt="" className=""></img> */}
                  <img src={sunicon} alt="" className="white"></img>

                  <span className={isActive ? '' : 'minibar'}>Light</span>
                </label>
              </p>

              <p>
                <input type="radio" id="dark" name="radio-group" onClick={() => setMode(false)} />
                <label class="dark-name" for="dark">
                  {/* <img src={moonicon} alt="" className=""></img> */}
                  <img src={moonicon} alt="" className="white"></img>

                  <span className={isActive ? '' : 'minibar '}>Dark</span></label>
              </p>
            </div>

            {/* <div className={isActive ? 'radio-wrap minibar' : 'radio-wrap '}>
              <p>
                <input type="radio" id="light" name="radio-group" onClick={() => setMode(true)} />
                <label for="light">
                  <img src={sunicon} alt="" className=""></img>
                  <img src={sunicon} alt="" className="white"></img>

                  Light
                </label>
              </p>

              <p>
                <input type="radio" id="dark" name="radio-group" onClick={() => setMode(false)} />
                <label class="dark-name" for="dark">
                  <img src={moonicon} alt="" className=""></img>
                  <img src={moonicon} alt="" className="white"></img>

                  Dark</label>
              </p>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
}
