import EventBus from 'eventing-bus';
import { web3, setProvider } from '../store/web3';
import { connect } from 'react-redux';
import { useState, useEffect } from "react";
import { useConnectWallet } from '@web3-onboard/react';
import { networkId, CIFI, CIFIABI, CIFIAddress, chainId} from '../store/config';
import onboard,{options} from '../store/web3-onboard';
import { setAddress, selectNetwork, setBalance, setGrootBalance, logout } from '../store/actions/Auth';
import showhideicon from "../images/eye.png";

function Connect(props) {
    const [Btoken, setBToken] = useState('XDC');
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
    const [selectedOption, setSelectedOption] = useState(
        options.filter(item => item.value == localStorage.getItem("chain")).length > 0 ? 
        options.filter(item => item.value == localStorage.getItem("chain"))[0] : 
        options[0]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [showBalance, setShowBalance] = useState(false);

    const toggleClass = () => {
        setShowBalance(!showBalance);
    };

    const handleSelectChange = (option) => {
        callLogout();
        localStorage.setItem("chain",parseInt(option.value));
        props.selectNetwork(parseInt(option.value));
        setSelectedOption(option);
        setDropdownOpen(false);
    };

    async function callLogout() {
        props.logout();
        props.setBalance(0);
        props.setGrootBalance(0);
        props.setAddress("");
        if (wallet && wallet.label) {
            disconnect({ label: wallet.label });
            window.location.reload();
        }
    }

    const addWallet =async(chainId)=> {
        try{
            await onboard.setChain({ chainId });
        }
        catch(e){
            return EventBus.publish('error', `${e.message}`);
        }
    }

    useEffect(() => {
        if (wallet?.provider) {
            // if using ethers v6 this is:
            // ethersProvider = new ethers.BrowserProvider(wallet.provider, 'any')
            if (wallet.provider.chainId == selectedOption.chainId) {
                setProvider(wallet.provider);
            } else {
                if (wallet && wallet.label) {
                    // disconnect({ label: wallet.label });
                    addWallet(selectedOption.chainId)
                    return;
                }
                return EventBus.publish('error', `Please change network to XDC or Polygon !!`);
            }
        }
        else if(wallet?.provider == undefined){
            callLogout();
        } 
        else {
            return;
            // return EventBus.publish('error', `Invalid wallet provider !!`);
            // Reset the provider back to 'undefined' such that the
            // connect wallet option will reappear in the uniswap modal
        }
        
    }, [wallet]);

    useEffect(() => {
        if (networkId == 80002 || networkId == 137) {
            setBToken("MATIC");
        } else if (networkId == 97 || networkId == 56) {
            setBToken("BNB");
        } else if (networkId == 51 || networkId == 50) {
            setBToken("XDC");
        }
    }, [networkId]);

    async function connectWalletAfterGet() {

        web3.eth.net.getId(async (err, netId) => {
            let x = await web3.currentProvider.chainId;
            if (!x) {
                EventBus.publish('error', `Please Connect Wallet!!!`);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            let balance = await web3.eth.getBalance(address); //Will give value in.
            // let NCIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);
            if (CIFI && Object.keys(CIFI).length > 0) {
                let tokenBalance = await CIFI.methods.balanceOf(address).call();
                tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
                balance = await web3.utils.fromWei(balance.toString(), "ether");
                let precision = 3;
                let power = Math.pow(10, precision);
                balance = parseFloat(balance);
                balance = Math.trunc(balance * power) / power;
                props.setBalance(balance);
                tokenBalance = parseFloat(tokenBalance);
                tokenBalance = Math.trunc(tokenBalance * power) / power;
                props.setGrootBalance(tokenBalance);
                if (address) props.setAddress(address);
            } else {
                window.location.reload();
                return;
            }
        });
    };


    useEffect(() => {
        if (web3) {
            connectWalletAfterGet();
        }
    }, [web3]);

    const connectWallet = async() => {
        connect()
    }

    return (
        <div className="btn-wrap">

            <div className="select-wrap">
                <div
                    className={`selected-option ${dropdownOpen ? 'open' : ''}`}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                    {selectedOption ? (
                        <>
                            <img src={selectedOption.imageUrl} alt={selectedOption.label} />
                            {selectedOption.label}
                        </>
                    ) : (
                        'Select an option'
                    )}
                </div>

                {dropdownOpen && (
                    <div className="options">
                        {options.map((option) => (
                            <div
                                key={option.label}
                                className="option"
                                onClick={() => handleSelectChange(option)}
                            >
                                <img src={option.imageUrl} alt={option.label} />
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {props.address === '' || props.address === null
                ?
                <button
                    onClick={connectWallet}>
                    Connect
                </button>
                // <button onClick={() => connectWallet(props.selectDefaultNetwok)}> Connect Metamask </button>
                :
                <>
                    <div className={showBalance ? 'balance-wrap hide-balance' : 'balance-wrap'}>
                        <button onClick={() => connectWallet()}>  <span className='balance'> {Btoken} {props.balance} </span> <span className='balance'> CiFi {props.grootBalance} </span> <span className='balance'>{props.address && props.address.substring(0, 5) + '...' + props.address.substring(37, props.address.length)}</span> </button>
                        <div onClick={toggleClass} className='show-hide'>
                            <img src={showhideicon} />
                        </div>
                        <button onClick={() => callLogout()}> Logout </button>
                    </div>
                </>
            }
        </div>


    )
}

const mapDispatchToProps = {
    setAddress, selectNetwork, setBalance, setGrootBalance, logout
};

const mapStateToProps = ({ Auth }) => {
    let { address, selectDefaultNetwok, balance, grootBalance } = Auth;
    return { address, selectDefaultNetwok, balance, grootBalance };
};
export default connect(mapStateToProps, mapDispatchToProps)(Connect);