import React from "react";
import { useState, useEffect } from "react";
import 'rc-slider/assets/index.css';
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { web3 } from '../../store/web3';
import ethicon from "../../images/eth.svg";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import { STAKING, USDG } from "../../store/config";
import modalcloseicon from "../../images/close.png";
import tickcircle from "../../images/tickcircle.png";
import stakingicon from "../../images/stake-icon-new.png";


function Stake(props) {

  const [value, setValue] = useState(0);
  const [staked, setStaked] = useState(0);
  const [stakeETHModal, setStakeETHModal] = useState(false);
  const [unstakeETHModal, setUnstakeETHModal] = useState(false);
  const [microeconomySuccessModal, setMicroeconomySuccessModal] = useState(false);
  const handleOnWheel = (event) => {
    const { type } = event.target;
    if(type === 'number'){
      event.preventDefault();
    }
  }
  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  const handleDeposit = async (e) => {
    try {
      e.preventDefault();
      const depositValue = await web3.utils.toWei(value, "ether");
      console.log(depositValue);
      let publicAddress = (await web3.currentProvider.enable())[0];
      console.log(publicAddress);
      await USDG.methods.approve("0x2E6253c333Ca32C581C414F19A639d264eB71fA3", depositValue).send({
        from: publicAddress
      }).on("receipt", async function (hash) {
        const data = await STAKING.methods.stake(
          depositValue
        ).send({
          from: publicAddress
        })
          .on("receipt", function (hash) {
            setMicroeconomySuccessModal(true);
            setStakeETHModal(false)
          });
        console.log(data);
      });;

    } catch (error) {
      console.log("********* error", error);
    }
  }

  const handleUnstake = async (e) => {
    try {
      e.preventDefault();
      let publicAddress = (await web3.currentProvider.enable())[0];
      const data = await STAKING.methods.unStake().send({
        from: publicAddress
      });
      setMicroeconomySuccessModal(true);
      setUnstakeETHModal(false)
      console.log(data);
    } catch (error) {
      console.log("********* error", error);
    }
  }

  const handleField = (e) => {
    setValue(e.target.value);
  }

  function mySubmit(e) {
    e.preventDefault();
    return false;
  }

  const trackStyle = {
    cursor: 'pointer'
  }

  const marks = {
    0: '0%',
    2: '25%',
    4: '50%',
    6: '75%',
    8: '100%',
  };

  const series = [
    {
      name: " ", //will be displayed on the y-axis
      data: [1400, 1700, 1200, 1900, 3300, 2000, 2700, 3200, 2500, 3300, 1200, 4700]
    }
  ];

  const options = {
    chart: {
      id: "simple-bar"
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

      axisBorder: {
        color: '#282C35',
        height: 2,
        width: '100%'
      },

      labels: {
        style: {
          colors: '#A9A9A9',
          fontSize: '12px',
          fontFamily: 'Quicksand-Medium',
          fontWeight: 400,
        },
      }
    },

    yaxis: {
      categories: [1000, 3000, 9000, 11000],

      labels: {
        style: {
          colors: '#A9A9A9',
          fontSize: '12px',
          fontFamily: 'Quicksand-Medium',
          fontWeight: 400,
        },

        formatter: function (value) {
          return "$" + value;
        }
      }
    },

    fill: {
      opacity: 1,
      width: 8,
      type: 'gradient',

      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: ['#3BE3B6'],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },

    grid: {
      show: true,
      borderColor: '#282C35',
      strokeDashArray: 6,
      xaxis: {
        lines: {
          show: false
        }
      },
    },

    plotOptions: {
      bar: {
        columnWidth: '8',
      },
    }
  };


  const series1 = [55, 45];

  const options1 = {
    chart: {
      type: 'donut',
      width: 200
    },

    labels: ["Bitcoin <span>BTC</span>", "Binance Coin <span>BNB</span>"],

    colors: ['#6EB3D6', '#3BE3B6'],

    dataLabels: {
      enabled: false,
    },

    stroke: {
      width: 0
    },

    plotOptions: {
      pie: {
        donut: {
          size: "82%"
        },

        expandOnClick: false
      }
    },
  }

  return (
    <>
      <div className="microeconomy-wrapper staking">
        <div className="microeconomy-head">
          <a href="#" className="logo-wrap">
            <img src={stakingicon} alt="" />

            <span>Staking</span>
          </a>
          <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
          <ConnectWallet />

        </div>

        <div className="chart-wrapper">
          <div class="bar-chart-wrapper">
            <div className="bar-chart-head">
              <div>
                <h2>Recent Payouts</h2>
              </div>

              <div className="time-duration">
                <span>24H</span>

                <span>7D</span>

                <span className="active">30D</span>

                <span>90D</span>

                <span>All</span>
              </div>
            </div>

            <div className="inner">
              <Chart options={options} series={series} type="bar" height={250} />
            </div>
          </div>

          <div class="radial-chart-wrapper">
            <div className="bar-chart-head">
              <div>
                <h2>Total Staked</h2>
              </div>
            </div>

            <div className="inner">
              <Chart options={options1} series={series1} type="donut" height={200} />
            </div>
          </div>
        </div>

        <div className="nft-collection">
          <div className="head">
            <h2>Pools</h2>

            <div className="collection-filters">
              <div className="toggle-wrap">
                <label>
                  <input type="checkbox" id="backup_set_toggle" />
                  <span className="check"></span>
                  Staked only
                </label>
              </div>
              <div className="sort">
                <label>SORT BY</label>
                {/* <button>
                <img src={bscicon} alt="" /> BSC
              </button> */}
                <select>
                  <option value="bsc">HOT</option>
                  <option value="usd">USD</option>
                  <option value="nft">NFT</option>
                </select>
              </div>
              <div className="search">
                <label for="search">Search</label>
                <input type="search" id="search-input" name="search-input" placeholder="Search Pools" />
              </div>
            </div>
          </div>

          <div className="staking-table-wrap">
            <div className="overflow-y-auto">
              <table class="table-fixed  w-full staking-table">
                <tbody className="">
                  <tr className="bg-[#32353D] flex items-center justify-between px-2 md:px-5 py-5 mb-2 font-medium border-[#D9D9D9]">

                    <td className="flex items-center col-span-2 font-medium text-lg">
                      <img src={ethicon} className="w-6 mr-4 md:w-10"></img>
                      <span className="text-[#ffffff] text-sm md:text-lg">
                        USDG
                      </span>
                    </td>

                    <td>
                      <p className="text-[#858585] text-[14px]">
                        Staked
                      </p>
                      <p className="text-[#ffffff] mt-2 text-sm md:text-lg">{staked}</p>
                    </td>

                    <td>
                      <p className="text-[#858585] text-[14px]">
                        In Wallet
                      </p>
                      <p className="text-[#ffffff] mt-2 text-sm md:text-lg">{props.balance}</p>
                    </td>

                    <td>
                      <p className="text-[#858585] text-[14px]">Total Staking</p>
                      <p className="text-[#ffffff] mt-2 text-lg">{staked} </p>
                    </td>{" "}

                    {/* <td>
                      <p className="text-[#858585] text-[14px]">ARR%</p>
                      <p className="text-[#ffffff] mt-2 text-sm md:text-lg">3.97%</p>
                    </td> */}

                    {/* <td>
                      <div className="logo-wrap">
                        <img src={logoSmall} alt="" />
                        <p className="text-[#ffffff] mt-2 text-sm md:text-lg">0.00</p>
                      </div>
                    </td> */}
                    {/* 
                    <td className="text-right claim-btn">
                      <button className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px]">
                        Claim
                      </button>
                    </td> */}

                    <td className="text-right borrow-btns">
                      <button onClick={() => setStakeETHModal(true)} className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px]">
                        Stake
                      </button>
                      <button onClick={() => setUnstakeETHModal(true)} className=" text-[#0A0A0A] px-8 h-11 manage-position">
                        Unstake
                      </button>
                      {/* <button className=" text-[#0A0A0A] px-8 h-11 manage-position">
                        Get LP <img src={shareDirect} alt="" />
                      </button> */}
                    </td>
                  </tr>
                  {/* <tr className="bg-[#32353D] w-[200%] sm:w-full flex items-center justify-between px-2 md:px-12 py-5 mb-2 font-medium border-[#D9D9D9]">
                
                  <td className="flex items-center col-span-2 font-medium text-lg">
                    <img src={ethicon} className="w-6 mr-4 md:w-10"></img>
                    <span className="text-[#ffffff] text-sm md:text-lg">
                    LP-WETH
                    </span>
                  </td>

                  <td>
                    <p className="text-[#858585] text-[14px]">
                      Staked
                    </p>
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg">0</p>
                  </td>
  
                  <td>
                    <p className="text-[#858585] text-[14px]">
                      In Wallet
                    </p>
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg">25.69</p>
                  </td>
  
                  <td>
                    <p className="text-[#858585] text-[14px]">Total Staking</p>
                    <p className="text-[#ffffff] mt-2 text-lg">265.93</p>
                  </td>{" "}
  
                  <td>
                    <p className="text-[#858585] text-[14px]">ARR%</p>
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg">3.97%</p>
                  </td>

                  <td>
                    <div className="logo-wrap">
                      <img src={logoSmall} alt="" />
                      <p className="text-[#ffffff] mt-2 text-sm md:text-lg">0.00</p>
                    </div>
                  </td>

                  <td className="text-right claim-btn">
                    <button className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px] active">
                      Claim
                    </button>
                  </td>

                  <td className="text-right borrow-btns">
                    <button  onClick={() => setStakeETHModal(true)} className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px]">
                      Stake
                    </button>
                    <button onClick={() => setUnstakeETHModal(true)} className=" text-[#0A0A0A] px-8 h-11 manage-position">
                      Unstake
                    </button>
                    <button className=" text-[#0A0A0A] px-8 h-11 manage-position">
                      Get LP <img src={shareDirect} alt="" />
                    </button>
                  </td>
                </tr>
                <tr className="bg-[#32353D] w-[200%] sm:w-full flex items-center justify-between px-2 md:px-12 py-5 mb-2 font-medium border-[#D9D9D9]">
                
                <td className="flex items-center col-span-2 font-medium text-lg">
                  <img src={ethicon} className="w-6 mr-4 md:w-10"></img>
                  <span className="text-[#ffffff] text-sm md:text-lg">
                  LP-WETH
                  </span>
                </td>

                <td>
                  <p className="text-[#858585] text-[14px]">
                    Staked
                  </p>
                  <p className="text-[#ffffff] mt-2 text-sm md:text-lg">0</p>
                </td>

                <td>
                  <p className="text-[#858585] text-[14px]">
                    In Wallet
                  </p>
                  <p className="text-[#ffffff] mt-2 text-sm md:text-lg">25.69</p>
                </td>

                <td>
                  <p className="text-[#858585] text-[14px]">Total Staking</p>
                  <p className="text-[#ffffff] mt-2 text-lg">265.93</p>
                </td>{" "}

                <td>
                  <p className="text-[#858585] text-[14px]">ARR%</p>
                  <p className="text-[#ffffff] mt-2 text-sm md:text-lg">3.97%</p>
                </td>

                <td>
                  <div className="logo-wrap">
                    <img src={logoSmall} alt="" />
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg">0.00</p>
                  </div>
                </td>

                <td className="text-right claim-btn">
                  <button className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px]">
                    Claim
                  </button>
                </td>

                <td className="text-right borrow-btns">
                  <button onClick={() => setStakeETHModal(true)} className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px]">
                    Stake
                  </button>
                  <button onClick={() => setUnstakeETHModal(true)} className=" text-[#0A0A0A] px-8 h-11 manage-position">
                    Unstake
                  </button>
                  <button className=" text-[#0A0A0A] px-8 h-11 manage-position">
                    Get LP <img src={shareDirect} alt="" />
                  </button>
                </td>
                 </tr>
                <tr className="bg-[#32353D] w-[200%] sm:w-full flex items-center justify-between px-2 md:px-12 py-5 mb-2 font-medium border-[#D9D9D9]">
                
                  <td className="flex items-center col-span-2 font-medium text-lg">
                    <img src={ethicon} className="w-6 mr-4 md:w-10"></img>
                    <span className="text-[#ffffff] text-sm md:text-lg">
                    LP-WETH
                    </span>
                  </td>

                  <td>
                    <p className="text-[#858585] text-[14px]">
                      Staked
                    </p>
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg">0</p>
                  </td>

                  <td>
                    <p className="text-[#858585] text-[14px]">
                      In Wallet
                    </p>
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg">25.69</p>
                  </td>

                  <td>
                    <p className="text-[#858585] text-[14px]">Total Staking</p>
                    <p className="text-[#ffffff] mt-2 text-lg">265.93</p>
                  </td>{" "}

                  <td>
                    <p className="text-[#858585] text-[14px]">ARR%</p>
                    <p className="text-[#ffffff] mt-2 text-sm md:text-lg">3.97%</p>
                  </td>

                  <td>
                    <div className="logo-wrap">
                      <img src={logoSmall} alt="" />
                      <p className="text-[#ffffff] mt-2 text-sm md:text-lg">0.00</p>
                    </div>
                  </td>

                  <td className="text-right claim-btn">
                    <button className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px] active">
                      Claim
                    </button>
                  </td>

                  <td className="text-right borrow-btns">
                    <button  onClick={() => setStakeETHModal(true)}  className=" text-[#0A0A0A] bg-[#B7933D] px-8 h-11 rounded-[100px]">
                      Stake
                    </button>
                    <button onClick={() => setUnstakeETHModal(true)} className=" text-[#0A0A0A] px-8 h-11 manage-position">
                      Unstake
                    </button>
                    <button className=" text-[#0A0A0A] px-8 h-11 manage-position">
                      Get LP <img src={shareDirect} alt="" />
                    </button>
                  </td>
                </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={stakeETHModal}
        onClose={() => setStakeETHModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal eth-stake-unstake-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStakeETHModal(false)}
          />
        </button>

        <div className="modal-body">

          <div className="inner">
            <div className="form-wrap">
              <h3>USDG Available To Stake</h3>

              <form onSubmit={handleDeposit}>
                <div className="form-group">
                  <input
                    type="text"
                    name='ethstake'
                    placeholder="2.44"
                    onChange={handleField}
                  />

                  <div className="eth-label">
                    <span></span>
                  </div>
                </div>
                <div className="rangeslider-wrap">
                  {/* <Slider
                      min={0}
                      max={8}
                      step={2}
                      allowCross={false}
                      reverse={false}
                      marks={marks}
                      trackStyle={trackStyle}
                      draggableTrack={true}
                      dragRange={true}
                      clickableTrack={false}
                      /> */}
                  <div>
                    {/* {value[0]} - {value[1]} */}
                  </div>
                </div>

                <button type="submit">
                  {/* <button type="submit" onClick={() => {setMicroeconomySuccessModal(true); setStakeETHModal(false)}}> */}
                  Stake
                </button>
              </form>
            </div>
          </div>

        </div>
      </Modal>

      <Modal
        open={unstakeETHModal}
        onClose={() => setUnstakeETHModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal eth-stake-unstake-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setUnstakeETHModal(false)}
          />
        </button>

        <div className="modal-body">

          <div className="inner">
            <div className="form-wrap">
              <h3> Available To Unstake</h3>

              <form onSubmit={handleUnstake}>
                {/* <div className="form-group">
                      <input
                          type="number"
                          name='ethstake'
                          placeholder="2.44"
                      />

                      <div className="eth-label">
                        <span>ETH</span>
                      </div>
                    </div>
                    <div className="rangeslider-wrap">
                      <Slider
                      min={0}
                      max={8}
                      step={2}
                      allowCross={false}
                      reverse={false}
                      marks={marks}
                      trackStyle={trackStyle}
                      draggableTrack={true}
                      dragRange={true}
                      clickableTrack={false}
                      />
                      <div> */}
                {/* {value[0]} - {value[1]} */}
                {/* </div>
                    </div> */}

                <button type="submit">
                  Unstake
                </button>
              </form>
            </div>
          </div>

        </div>
      </Modal>

      <Modal
        open={microeconomySuccessModal}
        onClose={() => setMicroeconomySuccessModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal success-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomySuccessModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <img src={tickcircle} alt="Tick Circle" />

            <h3>Success</h3>

            {/* <span>2.44 ETH  Staked</span> */}
            <form>


              {/* <div className="form-group hash">
                    <input
                        type="text"
                        name='walletAddress'
                        placeholder="Hash | 0xBBB6A7...6hn9"
                    />

                    <img className="copy" src={copy} alt="Tick Circle"/>

                    <img className="go-arrow" src={goarrow} alt="Go Arrow"/>
                </div> */}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}


const mapStateToProps = ({ Auth }) => {
  let { address, balance, currentMode } = Auth;
  return { address, balance, currentMode };
};
export default connect(mapStateToProps)(Stake);