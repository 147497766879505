import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import ConnectWallet from "../ConnectWallet";
import React, { useState, useEffect } from "react";
import alternate from "../../images/alternate.jpg";
import { setLoader, getSingelNft, createMarketItem, createMarketSale, unListItem, getCollection, getUriData, setBalance, setGrootBalance, getKybStatus, getKycStatus } from "../../store/actions/Auth.js";
import { Groot, CIFI, networkId, XRC721ABI, Marketplace, MarketplaceAddress, CiFiAccessNFTAddress, CiFiAccessNFTContract, CiFiAccessNFTABI, AdminWhitelist, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";
import { web3 } from '../../store/web3';

function Sell(props, { mode }) {

  const { collectionAddress, tokenId } = useParams();

  let [from, setFrom] = useState("");
  let [isNFT, setIsNFT] = useState();
  let [bToken, setBToken] = useState("");
  let [tokens, setTokens] = useState({});
  let [bSymbol, setBSymbol] = useState("");
  let [salePrice, setSalePrice] = useState("");
  let [isWhitelist, setIsWhitelist] = useState(false);

  const [model, setModel] = useState(true);

  const navigate = useNavigate()


  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  const getNFTs = async () => {
    if (CiFiAccessNFTABI && CiFiAccessNFTAddress) {
      props.setLoader({ message: "Load Microeconomy...", status: true });
      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      if (newCiFiAccessNFTContract) {
        let tokenBalance = await newCiFiAccessNFTContract.methods.balanceOf(props.publicAddress).call({ from: props.publicAddress });
        // tokenBalance =  1; //tesing purpose
        setIsNFT(tokenBalance);
        if (Number(tokenBalance) >= 1) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Load Microeconomy...", status: false });
      }
    }

    if (AdminWhitelistABI && AdminWhitelistAddress) {
      props.setLoader({ message: "Loading...", status: true });
      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      if (newAdminWhitelist) {
        let status = await newAdminWhitelist.methods.isWhitelist(props.publicAddress).call({ from: props.publicAddress });
        // status =  true; //tesing purpose
        setIsWhitelist(status);
        if (status == true) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Loading...", status: false });
      }
    }

  }

  useEffect(() => {
    if (props.publicAddress  && web3) {
      getNFTs();
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  useEffect(() => {
    props.getCollection({ address: collectionAddress });
    if (web3 && props.publicAddress) {
      getNft();
    }
  }, [web3, props.publicAddress]);

  async function getNft() {
    let contract = new web3.eth.Contract(XRC721ABI, collectionAddress);
    let ownerToken = await contract.methods.ownerOf(tokenId).call({ from: props.publicAddress });
    props.getSingelNft({ publicAddress: ownerToken.toLowerCase(), TokenAddress: collectionAddress, nftId: tokenId, networkId });
  }

  useEffect(() => {
    if (props.publicAddress == null || props.publicAddress == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    } else {
      let address = props.publicAddress;
      let output = props.publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        address = "0x" + props.publicAddress.substring(3);
        setFrom(address);
      } else {
        address = props.publicAddress;
        setFrom(address);
      }
    }
    setTokenData();
  }, [networkId, props.nftData]);

  async function setTokenData() {
    let { nftData, collectionData } = props;
    collectionData = collectionData[0];
    let contract = new web3.eth.Contract(XRC721ABI, collectionAddress);
    let ownerToken = await contract.methods.ownerOf(tokenId).call({ from: props.publicAddress });
    let uri = await contract.methods.tokenURI(tokenId).call({ from: props.publicAddress });
    props.getUriData({ list: [uri] });
    setTokens({ ownerToken, collectionAddress, tokenId, ...collectionData, ...nftData });
  }

  useEffect(() => {
    if (props.uriData) updateData();
  }, [props.uriData]);

  async function updateData() {
    if (props && props.uriData.length > 0) {
      setTokens({ ...tokens, ...props?.uriData?.[0] });
    }
  }

  useEffect(() => {
    if (networkId == 80002 || networkId == 137) {
      setBToken("0x4aA5d5AEDd38046537f4b83775Ef8D76a77eFf79");
      setBSymbol("MATIC");
    } else if (networkId == 97 || networkId == 56) {
      setBToken("0x153F54dEf755C589c6607adEB889D9B4340c6198");
      setBSymbol("BNB");
    } else if (networkId == 51 || networkId == 50) {
      setBToken("0x9c906336a58749584BD056125385B4347D8EE0C6");
      setBSymbol("XDC");
    }
  }, [networkId]);

  async function handleChange(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "salePrice") {
      if (parseFloat(e.target.value) > 0) {
        setSalePrice(parseFloat(e.target.value));
      } else {
        setSalePrice("");
      }
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function sellItem(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (salePrice == "") {
        EventBus.publish("error", `Enter selling price to place order!`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      // if (props.kycStatus !== "adminApproved") {
      //   EventBus.publish("error", `Submit KYC verification to access this function!`);
      //   return;
      // }

      let deployer = (await web3.currentProvider.enable())[0];
      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      // if (props.userSoulBound == "") {
      //   EventBus.publish("error", `User soul bound token not available!`);
      //   return;
      // }

      salePrice = web3.utils.toWei(salePrice.toString(), "ether");

      props.setLoader({
        message: "Approval in Progress...",
        status: true,
      });

      let contract = new web3.eth.Contract(XRC721ABI, collectionAddress);
      await contract.methods.approve(MarketplaceAddress, tokenId).send({
        from: deployer,
      });

      props.setLoader({
        message: "Approval complete...",
        status: false,
      });

      props.setLoader({
        message: "Sell order in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: MarketplaceAddress,
          gas: 5000000,
          data: Marketplace.methods
            .createMarketItem(collectionAddress, tokenId, salePrice,   props.selectDefaultNetwok == 51 || props.selectDefaultNetwok == 50 ? 
              "0x90b968b328c8e9399d6dcaedcf006ca913c538ba" 
              : 
              "0x364C552Fc969B077E0De3a8131CcCA241112e0FF")
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({
            message: "Sell Placed...",
            status: false,
          });
          await props.createMarketItem({ publicAddress, nftId: tokenId, price: salePrice, TokenAddress: collectionAddress });
          waitFor(1500);
          await refreshBalance();
          setSalePrice("");
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      setSalePrice("");
      EventBus.publish("error", `Sell Order Failed`);
    }
  }

  async function unlistItem(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      // if (props.kycStatus !== "adminApproved") {
      //   EventBus.publish("error", `Submit KYC verification to access this function!`);
      //   return;
      // }

      // if (props.userSoulBound == "") {
      //   EventBus.publish("error", `User soul bound token not available!`);
      //   return;
      // }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      props.setLoader({
        message: "Unlist in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: MarketplaceAddress,
          gas: 5000000,
          data: Marketplace.methods
            .unListItems(collectionAddress, tokenId,  props.selectDefaultNetwok == 51 || props.selectDefaultNetwok == 50 ? 
              "0x90b968b328c8e9399d6dcaedcf006ca913c538ba" 
              : 
              "0x364C552Fc969B077E0De3a8131CcCA241112e0FF")
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** tx hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({
            message: "Unlisted NFT Successfuly...",
            status: false,
          });
          await props.unListItem({ nftId: tokenId, TokenAddress: collectionAddress });
          await refreshBalance();
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Unlist Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unlist NFT Failed`);
    }
  }

  async function buyItem(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      // if (props.kycStatus !== "adminApproved") {
      //   EventBus.publish("error", `Submit KYC verification to access this function!`);
      //   return;
      // }

      // if (props.userSoulBound == "") {
      //   EventBus.publish("error", `User soul bound token not available!`);
      //   return;
      // }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      props.setLoader({
        message: "Buy order in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: tokens['price'],
          to: MarketplaceAddress,
          gas: 5000000,
          data: Marketplace.methods
            .createMarketSale(tokenId, collectionAddress,   props.selectDefaultNetwok == 51 || props.selectDefaultNetwok == 50 ? 
              "0x90b968b328c8e9399d6dcaedcf006ca913c538ba" 
              : 
              "0x364C552Fc969B077E0De3a8131CcCA241112e0FF")
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** tx hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({
            message: "Buy NFT Successfull...",
            status: false,
          });
          await props.createMarketSale({ publicAddress, nftId: tokenId, TokenAddress: collectionAddress });
          await refreshBalance();
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Buy Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Buy NFT Failed`);
    }
  }

  const handleImageError = () => {
    // Set the failed image to the fallback image
    tokens['image'] = alternate;
    setTokens({ ...tokens });
  };

  return (
    <>
      <div className="microeconomy-wrapper">
        <div className="microeconomy-head">
          <a className="user-profile-icon" href="#">
          </a>
          {props.isLoader.status == true &&
            <Prompt when={model}
              message="Transaction in progress, leaving page may result in transaction failure!"
              beforeUnload={true}
            />
          }
          {(salePrice !== "") &&
            <Prompt when={model}
              message="Leaving page will result in losing details entered!"
              beforeUnload={true}
            />
          }
          <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
          <ConnectWallet />
        </div>

        <div className="sell-wrap">
          <div className="img-wrap">
            <img src={tokens['image'] ? tokens['image'] : alternate} onError={() => handleImageError()} alt="Alternate Image" />
          </div>

          <div className="content">
            <span>About this colllection</span>

            <h2>“{tokens['name']}”</h2>

            <div className="item-interaction">
              <div className="item-views">
                {
                  networkId == 80002 || networkId == 137 ?
                    (<span>Polygon</span>) :
                    networkId == 97 || networkId == 56 ?
                      (<span>Binance</span>) :
                      networkId == 51 || networkId == 50 ?
                        (<span>XDC</span>) : <></>
                }
                <span>{tokens['type']}</span>
              </div>
              <div className="share-option">
              </div>
            </div>

            <p>{tokens['description']}</p>

            <div className="form-wrap">
              {
                Object.keys(tokens).length > 0 && tokens['owner'] && tokens['owner'].toLowerCase() == props.publicAddress.toLowerCase() &&
                  tokens['isMarketItem'] == false ?
                  (<form onSubmit={sellItem}>
                    {
                      salePrice !== "" ?
                        < input
                          onWheel={handleOnWheel}
                          type="number"
                          name='salePrice'
                          value={salePrice}
                          placeholder={salePrice}
                          onChange={handleChange}
                        /> :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          name='salePrice'
                          value={salePrice}
                          placeholder="Set price"
                          onChange={handleChange}
                        />
                    }
                    <button type="submit">Sell</button>
                  </form>) :
                  Object.keys(tokens).length > 0 && tokens['owner'] && tokens['owner'].toLowerCase() == props?.publicAddress.toLowerCase() &&
                    tokens['isMarketItem'] == true ?
                    (<form onSubmit={unlistItem}>
                      <button type="submit">Unlist NFT</button>
                    </form>) :
                    Object.keys(tokens).length > 0 && tokens['owner'] && tokens['owner'].toLowerCase() !== props?.publicAddress.toLowerCase() &&
                      tokens['isMarketItem'] == true ?
                      (<form onSubmit={buyItem}>
                        <button type="submit">Buy NFT at {web3.utils.fromWei(tokens['price'].toString(), "ether")} {bSymbol}</button>
                      </form>) :
                      <></>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  setLoader,
  getSingelNft,
  createMarketItem,
  createMarketSale,
  unListItem,
  getCollection,
  getUriData,
  setBalance,
  setGrootBalance,
  getKybStatus,
  getKycStatus
};

const mapStateToProps = ({ Auth }) => {
  let { address, publicAddress, nftData, selectDefaultNetwok, collectionData, uriData, isLoader, kycStatus, userSoulBound } = Auth;
  return { address, publicAddress, nftData, selectDefaultNetwok, collectionData, uriData, isLoader, kycStatus, userSoulBound };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sell);