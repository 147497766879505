/*========== LOGIN ACTIONS ============= */

export const getNonce = (data) => ({
  type: 'GET_NONCE',
  payload: data,
});

export const setNonce = (data) => ({
  type: 'SET_NONCE',
  payload: data,
});

export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const toggleLogin = () => ({
  type: 'TOGGLE_LOGIN',
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

export const setLoginToken = (data) => ({
  type: 'SET_LOGIN_TOKEN',
  payload: data
});

export const setToken = (data) => ({
  type: 'SET_TOKEN',
  payload: data
});

/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});

export const toggleInfoModal = (data) => ({
  type: 'TOGGLE_INFO_MODAL',
  payload: data
});

export const setOrderBook = (data) => ({
  type: 'SET_ORDERBOOK',
  payload: data,
});

export const getOrderBook = (data) => ({
  type: 'GET_ORDERBOOK',
  payload: data
});

export const getLimitOrderBook = (data) => ({
  type: 'GET_LIMIT_ORDERBOOK',
  payload: data
});

export const getUserOrders = (data) => ({
  type: 'GET_USER_ORDERS',
  payload: data
});

export const setSinglePair = (data) => ({
  type: "SET_TOKEN_PAIR",
  payload: data,
});

export const updateGraph = (data) => ({
  type: "UPDATE_GRAPH",
  payload: data,
});

export const get24HVolume = (data) => ({
  type: "GET_24_HOURS_VOLUME",
  payload: data,
});

export const set24HVolume = (data) => ({
  type: "SET_24_HOURS_VOLUME",
  payload: data,
});


export const setBuyMarketOrders = (data) => ({
  type: "SET_BUY_MARKET_ORDERS",
  payload: data,
});

export const getBuyMarketOrders = (data) => ({
  type: "GET_BUY_MARKET_ORDERS",
  payload: data,
});


export const setPairsAddress = (data) => ({
  type: "SET_PAIRS_ADDRESS",
  payload: data,
});

export const setTradeOrder = (data) => ({
  type: "SET_TRADE_ORDERS",
  payload: data,
});

export const cancelTradeOrders = (data) => ({
  type: "CANCEL_TRADE_ORDERS",
  payload: data,
});

export const getSingleTradeOrders = (data) => ({
  type: "GET_SINGLE_TRADE_ORDERS",
  payload: data,
});

export const getTradeOrders = (data) => ({
  type: "GET_TRADE_ORDERS",
  payload: data,
});


export const cancelLimitOrderTrade = (data) => ({
  type: "CANCEL_LIMIT_ORDER_TRADES",
  payload: data,
});



export const getAllUserTrades = (data) => ({
  type: "GET_ALL_USER_TRADES",
  payload: data,
});

export const getAllUserOrders = (data) => ({
  type: "GET_ALL_USER_ORDERS",
  payload: data,
});

export const setAllUserTrades = (data) => ({
  type: "SET_ALL_USER_TRADES",
  payload: data,
});

export const setAllUserOrders = (data) => ({
  type: "SET_ALL_ORDERS",
  payload: data,
});

export const selectNetwork = (data) => ({
  type: 'SELECT_NETWORK',
  payload: data
});

export const setBalance = (data) => ({
  type: 'SET_BALANCE',
  payload: data
});

export const setGrootBalance = (data) => ({
  type: 'SET_GROOT_BALANCE',
  payload: data
});

/*========== MICRO ECONOMY ACTIONS ============= */

export const uploadCollection = (data) => ({
  type: 'UPLOAD_COLLECTION',
  payload: data
});

export const uploadStake = (data) => ({
  type: 'UPLOAD_STAKE',
  payload: data
});

export const getStaking = () => ({
  type: 'GET_STAKING'
});

export const setStaking = (data) => ({
  type: 'SET_STAKING',
  payload: data,
});

export const uploadLPStake = (data) => ({
  type: 'UPLOAD_LP_STAKE',
  payload: data
});

export const getLPStaking = () => ({
  type: 'GET_LP_STAKING'
});

export const setLPStaking = (data) => ({
  type: 'SET_LP_STAKING',
  payload: data,
});

export const getXRC20 = () => ({
  type: 'GET_XRC20'
});

export const setXRC20 = (data) => ({
  type: 'SET_XRC20',
  payload: data,
});

export const getICO = (data) => ({
  type: 'GET_ICO',
  payload: data,
});

export const setICO = (data) => ({
  type: 'SET_ICO',
  payload: data,
});

export const getXRC721 = () => ({
  type: 'GET_XRC721'
});

export const setXRC721 = (data) => ({
  type: 'SET_XRC721',
  payload: data,
});

export const getCollection = (data) => ({
  type: 'GET_COLLECTION',
  payload: data,
});

export const setCollection = (data) => ({
  type: 'SET_COLLECTION',
  payload: data,
});

export const stakeNFT = (data) => ({
  type: 'STAKE_NFT',
  payload: data,
});

export const unStakeNFT = (data) => ({
  type: 'UNSTAKE_NFT',
  payload: data,
});

export const getStakedNFT = (data) => ({
  type: 'GET_STAKED_NFT',
  payload: data,
});

export const setStakedNFT = (data) => ({
  type: 'SET_STAKED_NFT',
  payload: data,
});

export const stakeLP = (data) => ({
  type: 'STAKE_LP',
  payload: data,
});

export const unStakeLP = (data) => ({
  type: 'UNSTAKE_LP',
  payload: data,
});

export const getStakedLP = (data) => ({
  type: 'GET_STAKED_LP',
  payload: data,
});

export const setStakedLP = (data) => ({
  type: 'SET_STAKED_LP',
  payload: data,
});

export const deployVestings = (data) => ({
  type: 'DEPLOY_VESTINGS',
  payload: data
});

export const getVesting = () => ({
  type: 'GET_VESTING'
});

export const setVesting = (data) => ({
  type: 'SET_VESTING',
  payload: data,
});

export const deployILO = (data) => ({
  type: 'DEPLOY_ILO',
  payload: data
});

export const updateILOTime = (data) => ({
  type: 'UPDATE_ILO_TIME',
  payload: data
});

export const getILO = () => ({
  type: 'GET_ILO'
});

export const setILO = (data) => ({
  type: 'SET_ILO',
  payload: data,
});

export const update721Description = (data) => ({
  type: 'UPDATE_721_DESCRIPTION',
  payload: data,
});

export const updateUserImage = (data) => ({
  type: 'UPDATE_USER_IMAGE',
  payload: data,
});

export const getProjectDetails = (data) => ({
  type: 'GET_PROJECT_DETAILS',
  payload: data,
});

export const setProjectDetails = (data) => ({
  type: 'SET_PROJECT_DETAILS',
  payload: data,
});

export const addProjectDetails = (data) => ({
  type: 'ADD_PROJECT_DETAILS',
  payload: data,
});

export const clearProjectDetails = (data) => ({
  type: 'CLEAR_PROJECT_DETAILS',
  payload: data,
});


/*========== DAO ACTIONS ============= */

export const getDAO = () => ({
  type: 'GET_DAO'
});

export const setDAO = (data) => ({
  type: 'SET_DAO',
  payload: data,
});

export const getProposals = (data) => ({
  type: 'GET_PROPOSALS',
  payload: data,
});

export const setProposals = (data) => ({
  type: 'SET_PROPOSALS',
  payload: data,
});

export const deployDAO = (data) => ({
  type: 'DEPLOY_DAO',
  payload: data,
});

export const addProposal = (data) => ({
  type: 'ADD_PROPOSAL',
  payload: data,
});

export const sendEmailDao = (data) => ({
  type: 'SEND_EMAIL_DAO',
  payload: data,
});


export const getCiFiDAO = () => ({
  type: 'GET_CIFI_DAO'
});

export const setCiFiDAO = (data) => ({
  type: 'SET_CIFI_DAO',
  payload: data,
});

export const getCiFiProposals = (data) => ({
  type: 'GET_CIFI_PROPOSALS',
  payload: data,
});

export const setCiFiProposals = (data) => ({
  type: 'SET_CIFI_PROPOSALS',
  payload: data,
});

export const addCiFiProposal = (data) => ({
  type: 'ADD_CIFI_PROPOSAL',
  payload: data,
});

export const sendEmailCIFIDao = (data) => ({
  type: 'SEND_EMAIL_CIFI_DAO',
  payload: data,
});

/*========== MARKETPLACE ACTIONS ============= */

export const getSingelNft = (data) => ({
  type: 'GET_SINGLE_NFT',
  payload: data,
});

export const setSingelNft = (data) => ({
  type: 'SET_SINGLE_NFT',
  payload: data,
});

export const createMarketItem = (data) => ({
  type: 'CREATE_MARKET_ITEM',
  payload: data,
});

export const createMarketSale = (data) => ({
  type: 'CREATE_MARKET_SALE',
  payload: data,
});

export const unListItem = (data) => ({
  type: 'UNLIST_ITEM',
  payload: data,
});

export const getUriData = (data) => ({
  type: 'GET_URI_DATA',
  payload: data,
});

export const setUriData = (data) => ({
  type: 'SET_URI_DATA',
  payload: data,
});

export const getUriStakeData = (data) => ({
  type: 'GET_URI_STAKE_DATA',
  payload: data,
});

export const setUriStakeData = (data) => ({
  type: 'SET_URI_STAKE_DATA',
  payload: data,
});



export const getMode = (data) => ({
  type: 'GET_MODE',
  payload: data
});

export const setMode = (data) => ({
  type: 'SET_MODE',
  payload: data
});

/*========== KYC ACTIONS ============= */

export const getKycStatus = (data) => ({
  type: 'GET_KYC_STATUS',
  payload: data
});

export const setKycStatus = (data) => ({
  type: 'SET_KYC_STATUS',
  payload: data
});

// export const getKybStatus = (data) => ({
//   type: 'GET_KYB_STATUS',
//   payload: data
// });

// export const setKybStatus = (data) => ({
//   type: 'SET_KYB_STATUS',
//   payload: data
// });

export const getXdcPrice = () => ({
  type: 'GET_XDC_PRICE',
});

export const setXdcPrice = (data) => ({
  type: 'SET_XDC_PRICE',
  payload: data
});

export const kycGStatus = (data) => ({
  type: 'KYC_G_STATUS',
  payload: data
});

export const setGKycStatus = (data) => ({
  type: 'SET_G_KYC_STATUS',
  payload: data
});

export const setKyc = (data) => ({
  type: 'SET_KYC',
  payload: data
});

export const kycResponse = (data) => ({
  type: 'KYC_RESPONSE',
  payload: data
});

export const kycSubmit = (data) => ({
  type: 'KYC_SUBMIT',
  payload: data
});

export const setSoulBound = (data) => ({
  type: 'SET_SOUL_BOUND',
  payload: data
});

/*========== KYC ACTIONS ============= */

export const kybSubmit = (data) => ({
  type: 'KYB_SUBMIT',
  payload: data
});

export const getKybStatus = (data) => ({
  type: 'GET_KYB_STATUS',
  payload: data
});

export const setKybStatus = (data) => ({
  type: 'SET_KYB_STATUS',
  payload: data
});


/*========== LIQUIDITY MINING ACTIONS ============= */

export const setSPNFT = (data) => ({
  type: 'SET_SP_NFT',
  payload: data
});