import io from 'socket.io-client';

// const ILODataPoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/ILO.json`);
// const WMATICDataPoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/WMATIC.json`);
// const CiFiDataPoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/CiFiToken.json`);
// const GrootDataPoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootToken.json`);
// const GrootPresalePoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/Presale.json`);
// const GrootRouterDataPoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootV2Router.json`);
// const SubscriptionDataPoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/Subscription.json`);
// const GrootFactoryDataPoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootV2Factory.json`);
// const MarketplaceDataPoly = require(`./contract/${process.env.REACT_APP_MODE}Polygon/financeMarketplace.json`);


// const BorrowOperations = require(`./contract/${process.env.REACT_APP_MODE}Polygon/borrowOptionsAbi.json`);
// const DeFranc = require(`./contract/${process.env.REACT_APP_MODE}Polygon/deFracParameters.json`);
// const TroveHelpers = require(`./contract/${process.env.REACT_APP_MODE}Polygon/troveManagerHelpers.json`);
// const SortedTroves = require(`./contract/${process.env.REACT_APP_MODE}Polygon/sortedTroves.json`);
// const HintHelpers = require(`./contract/${process.env.REACT_APP_MODE}Polygon/hintHelpers.json`);
// const TroveManager = require(`./contract/${process.env.REACT_APP_MODE}Polygon/TroveManagerAbi.json`);
// const Staking = require(`./contract/${process.env.REACT_APP_MODE}Polygon/StakingAbi.json`);
// const usdg = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootUSDGABI.json`);


const ILODataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/ILO.json`);
const WXDCDataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/WMATIC.json`);
const CiFiDataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/CiFiToken.json`);
const GrootDataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootToken.json`);
const GrootPresalePolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/Presale.json`);
const SubscriptionDataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/Subscription.json`);
const GrootRouterDataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootV2Router.json`);
const GrootFactoryDataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootV2Factory.json`);
const GrootPairDataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootV2Pair.json`);
const MarketplaceDataPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/financeMarketplace.json`);
const CiFiAccessNFTPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/CiFiAccessNFT.json`);
const AdminWhitelistPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/AdminWhitelist.json`);
const CiFiDAONFTPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/CiFiDAONFT.json`);
const CiFiDAOPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/CiFiDAO.json`);
const TOKENAIRDROPPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/TokenAirDrop.json`);
const LIQUIDITYPOOL1Polygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/LiquidityPool1.json`);
const LIQUIDITYPOOL2Polygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/LiquidityPool2.json`);
const SPNFTPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/SPNFT.json`);
const CifiStackPolygon = require(`./contract/${process.env.REACT_APP_MODE}Polygon/CifiStack.json`);

//old
const BorrowOperations = require(`./contract/${process.env.REACT_APP_MODE}Polygon/borrowOptionsAbi.json`);
const DeFranc = require(`./contract/${process.env.REACT_APP_MODE}Polygon/deFracParameters.json`);
const TroveHelpers = require(`./contract/${process.env.REACT_APP_MODE}Polygon/troveManagerHelpers.json`);
const SortedTroves = require(`./contract/${process.env.REACT_APP_MODE}Polygon/sortedTroves.json`);
const HintHelpers = require(`./contract/${process.env.REACT_APP_MODE}Polygon/hintHelpers.json`);
const TroveManager = require(`./contract/${process.env.REACT_APP_MODE}Polygon/TroveManagerAbi.json`);
const Staking = require(`./contract/${process.env.REACT_APP_MODE}Polygon/StakingAbi.json`);
const usdg = require(`./contract/${process.env.REACT_APP_MODE}Polygon/GrootUSDGABI.json`);

const ILODataBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/ILO.json`);
const WBNBDataBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/WBNB.json`);
const CiFiDataBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/CiFiToken.json`);
const GrootDataBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/GrootToken.json`);
const GrootPresaleBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/Presale.json`);
const SubscriptionDataBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/Subscription.json`);
const GrootRouterDataBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/GrootV2Router.json`);
const GrootFactoryDataBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/GrootV2Factory.json`);
const MarketplaceDataBinance = require(`./contract/${process.env.REACT_APP_MODE}Binance/financeMarketplace.json`);
const CiFiAccessNFTBinance = require(`./contract/${process.env.REACT_APP_MODE}XDC/CiFiAccessNFT.json`);

const ILODataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/ILO.json`);
const WXDCDataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/WXDC.json`);
const CiFiDataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/CiFiToken.json`);
const GrootDataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/GrootToken.json`);
const GrootPresaleXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/Presale.json`);
const SubscriptionDataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/Subscription.json`);
const GrootRouterDataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/GrootV2Router.json`);
const GrootFactoryDataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/GrootV2Factory.json`);
const GrootPairDataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/GrootV2Pair.json`);
const MarketplaceDataXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/financeMarketplace.json`);
const CiFiAccessNFTXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/CiFiAccessNFT.json`);
const AdminWhitelistXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/AdminWhitelist.json`);
const CiFiDAONFTXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/CiFiDAONFT.json`);
const CiFiDAOXDC = require(`./contract/${process.env.REACT_APP_MODE}XDC/CiFiDAO.json`);
const TOKENAIRDROP = require(`./contract/${process.env.REACT_APP_MODE}XDC/TokenAirDrop.json`);
const LIQUIDITYPOOL1 = require(`./contract/${process.env.REACT_APP_MODE}XDC/LiquidityPool1.json`);
const LIQUIDITYPOOL2 = require(`./contract/${process.env.REACT_APP_MODE}XDC/LiquidityPool2.json`);
const SPNFT = require(`./contract/${process.env.REACT_APP_MODE}XDC/SPNFT.json`);
const CiFiAccessNFTPoly = require(`./contract/${process.env.REACT_APP_MODE}XDC/CiFiAccessNFT.json`);
const CifiStack = require(`./contract/${process.env.REACT_APP_MODE}XDC/CifiStack.json`);


// Deployable Contracts
const DAOData = require(`./contract/${process.env.REACT_APP_MODE}XDC/Dao.json`);
const XRC20Data = require(`./contract/${process.env.REACT_APP_MODE}XDC/XRC20.json`);
const XRC721Data = require(`./contract/${process.env.REACT_APP_MODE}XDC/XRC721.json`);
const StakingData = require(`./contract/${process.env.REACT_APP_MODE}XDC/Staking.json`);
const ICOXRC20Data = require(`./contract/${process.env.REACT_APP_MODE}XDC/ICOXRC20.json`);
const CiFiLPStakeData = require(`./contract/${process.env.REACT_APP_MODE}XDC/CiFiLPStake.json`);
const XRC20VestingData = require(`./contract/${process.env.REACT_APP_MODE}XDC/XRC20Vesting.json`);
const DaoMultiSigData = require(`./contract/${process.env.REACT_APP_MODE}XDC/daoMultiSignature.json`);


// const BinanceERC20 = require(`./contract/${process.env.REACT_APP_MODE}Binance/ERC20.json`);
const BinanceOrderBook = require(`./contract/${process.env.REACT_APP_MODE}Binance/OrderBook.json`);
const PolygonOrderBook = require(`./contract/${process.env.REACT_APP_MODE}Polygon/OrderBook.json`);
const XDCOrderBook = require(`./contract/${process.env.REACT_APP_MODE}XDC/OrderBook.json`);
const BinanceArbitrageBot = require(`./contract/${process.env.REACT_APP_MODE}Binance/ArbitrageBot.json`);

const tradeBot = require(`./contract/${process.env.REACT_APP_MODE}Binance/TradeBot.json`);


const ERC20 = require(`./contract/${process.env.REACT_APP_MODE}XDC/ERC20.json`);


/* -- set app title --*/
const AppTitle = 'Circularity Finance';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

// let SocketUrl;
let production = 'https://server.cifiapp.com';
let development = 'https://www.servergroot.grootdex.io';
// let development = 'http://localhost:4000';

let isTestnet = true;
let env = AppMode[0] || 'development', SocketUrl = '', networkId = '', message = '', explorer = '', GrootABI = '', GrootAddress = '', Groot = '', GrootFactoryABI = GrootFactoryDataXDC['abi'], GrootPairABI = '',
  GrootFactoryAddress = '', GrootFactory = '', GrootRouterABI = '', GrootRouterAddress = '', GrootRouter = '', OrderBookABI = '', OrderBookAddress = '', ERC20Abi = '', OrderBookContract = '',
  ArbitrageBotABI = '', ArbitrageBotAddress = '', ArbitrageBotContract = '', GrootBinanceABI = '', GrootBinanceAddress = '', GrootBinanceFactoryAbi = '', GrootBinanceFactoryAddress = '', GrootBinanceFactoryContract = '',
  TradeBotABI = '', TradeBotAddress = '', TradeBotContract = '', GrootILOABI = ILODataXDC['abi'], GrootILOAddress = '', GrootILO = '', XRC20VestingABI = '', XRC20VestingBytecode = '',
  BinanceOrderBookAddress = '', BinanceERC20Abi = '', BinanceOrderBookContract = '', XDCOrderBookABI = '', XDCOrderBookAddress = '', XDCERC20Abi = '', ICOXRC20ABI = ICOXRC20Data['abi'], ICOXRC20Bytecode = ICOXRC20Data['bytecode'],
  XDCOrderBookContract = '', GrootPresaleABI = '', GrootPresaleAddress = '', GrootPresale = '', GrootSubscriptionABI = '', GrootSubscriptionAddress = '', GrootSubscription = '',
  XRC20ABI = XRC20Data['abi'], XRC721ABI = XRC721Data['abi'], StakeABI = StakingData['abi'], XRC20Bytecode = XRC20Data['bytecode'], XRC721Bytecode = XRC721Data['bytecode'], StakeBytecode = StakingData['bytecode'],
  BORROW = '', DE_FRANC = '', TROVE_HELPERS = '', SORTEDTROVES = '', HINT_HELPERS = '', TROVE_MANAGER = '', STAKING = '', USDG = '', DaoABI = DAOData['abi'], DaoBytecode = DAOData['bytecode'],
  MarketplaceABI = '', MarketplaceAddress = '', Marketplace = '', DaoMultiSigABI = DaoMultiSigData['abi'], DaoMultiSigBytecode = DaoMultiSigData['bytecode'], CIFIABI = CiFiDataXDC['abi'], CIFIAddress = CiFiDataXDC['address'], CIFI = '',
  CiFiAccessNFTABI = CiFiAccessNFTXDC['abi'], CiFiAccessNFTAddress = CiFiAccessNFTXDC['address'], CiFiAccessNFTContract = '', AdminWhitelist = '', AdminWhitelistAddress = AdminWhitelistXDC['address'], AdminWhitelistABI = AdminWhitelistXDC['abi'],
  CiFiDAONFTABI = CiFiDAONFTXDC['abi'], CiFiDAONFTAddress = CiFiDAONFTXDC['address'], CiFiDAONFTContract = '', CiFiDAOAddress= '', CiFiDAOABI = CiFiDAOXDC['abi'], CiFiLPStakeABI = CiFiLPStakeData['abi'], CiFiLPStakeBytecode = CiFiLPStakeData['bytecode'], TokenAirDropContract = '', 
  TokenAirDropABI = TOKENAIRDROP['abi'], TokenAirDropAddress = TOKENAIRDROP['address'],
  chainId = '',
  CifiStackAbi = '', CifiStackBytecode = '', CifiStackNFTAddressGOV = '', CifiStackTokenAddressGOV = '', CifiStakeAddressGOV = '',
  CifiStakeNFTAddressVIP = '',  CifiStakeTokenAddressVIP = '',  CifiStakeAddressVIP = '',  
  LiquidityPoolABI1 = LIQUIDITYPOOL1['abi'], LiquidityPoolAddress1 = '', LiquidityPoolContract1 = '',
  LiquidityPoolABI2 = LIQUIDITYPOOL2['abi'], LiquidityPoolAddress2 = '', LiquidityPoolContract2 = '',
  SPNFTABI = SPNFT['abi'], SPNFTAddress = '', SPNFTContract = '';

const setProviderAddress = (web3) => {
  if (web3) {
    if (AppMode[0] === 'development') {
      web3.eth.net.getId(async (err, netId) => {

        if (netId === 80002) {
          networkId = 80002;
          chainId = "0x13882";
          SocketUrl = development;
          explorer = 'https://www.oklink.com/amoy';
          message = 'Please switch your network to Mumbai Testnet';

          CifiStackAbi = CifiStackPolygon['abi'];
          CifiStackBytecode = CifiStackPolygon['bytecode'];
          CifiStackNFTAddressGOV = CifiStackPolygon['nftAddressGOV'];
          CifiStackTokenAddressGOV = CifiStackPolygon['tokenAddressGOV'];
          CifiStakeAddressGOV = CifiStackPolygon['stakeAddressGOV'];
          CifiStakeNFTAddressVIP = CifiStackPolygon['nftAddressVIP'];
          CifiStakeTokenAddressVIP = CifiStackPolygon['tokenAddressVIP'];
          CifiStakeAddressVIP = CifiStackPolygon['stakeAddressVIP'];

          CIFIABI = CiFiDataPolygon['abi'];
          CIFIAddress = CiFiDataPolygon['address'].toLowerCase();
          CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

          GrootABI = GrootDataPolygon['abi'];
          GrootAddress = GrootDataPolygon['address'].toLowerCase();
          Groot = new web3.eth.Contract(GrootABI, GrootAddress);

          GrootFactoryABI = GrootFactoryDataPolygon['abi'];
          GrootFactoryAddress = GrootFactoryDataPolygon['address'].toLowerCase();
          GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

          GrootPairABI = GrootPairDataPolygon['abi'];

          GrootRouterABI = GrootRouterDataPolygon['abi'];
          GrootRouterAddress = GrootRouterDataPolygon['address'];
          GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

          GrootILOABI = ILODataPolygon['abi'];
          GrootILOAddress = ILODataPolygon['address'];
          GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);

          GrootSubscriptionABI = SubscriptionDataPolygon['abi'];
          GrootSubscriptionAddress = SubscriptionDataPolygon['address'];
          GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

          MarketplaceABI = MarketplaceDataPolygon['abi'];
          MarketplaceAddress = MarketplaceDataPolygon['address'];
          Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

          CiFiAccessNFTABI = CiFiAccessNFTPolygon['abi'];
          CiFiAccessNFTAddress = CiFiAccessNFTPolygon['address'];
          CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

          CiFiDAONFTABI = CiFiDAONFTPolygon['abi'];
          CiFiDAONFTAddress = CiFiDAONFTPolygon['address'];
          CiFiDAONFTContract = new web3.eth.Contract(CiFiDAONFTABI, CiFiDAONFTAddress);

          CiFiDAOABI = CiFiDAOPolygon['abi'];
          CiFiDAOAddress = CiFiDAOPolygon['address'];

          AdminWhitelistABI = AdminWhitelistPolygon['abi'];
          AdminWhitelistAddress = AdminWhitelistPolygon['address'];
          AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

          TokenAirDropABI = TOKENAIRDROPPolygon['abi'];
          TokenAirDropAddress = TOKENAIRDROPPolygon['address'];
          TokenAirDropContract = new web3.eth.Contract(TokenAirDropABI, TokenAirDropAddress);

          LiquidityPoolABI1 = LIQUIDITYPOOL1Polygon['abi'];
          LiquidityPoolAddress1 = LIQUIDITYPOOL1Polygon['address'];
          LiquidityPoolContract1 = new web3.eth.Contract(LiquidityPoolABI1, LiquidityPoolAddress1);

          LiquidityPoolABI2 = LIQUIDITYPOOL2Polygon['abi'];
          LiquidityPoolAddress2 = LIQUIDITYPOOL2Polygon['address'];
          LiquidityPoolContract2 = new web3.eth.Contract(LiquidityPoolABI2, LiquidityPoolAddress2);
          
          SPNFTABI = SPNFTPolygon['abi'];
          SPNFTAddress = SPNFTPolygon['address'];
          SPNFTContract = new web3.eth.Contract(SPNFTABI, SPNFTAddress);


          XRC20ABI = XRC20Data['abi'];
          XRC20Bytecode = XRC20Data['bytecode'];
          ICOXRC20ABI = ICOXRC20Data['abi'];
          ICOXRC20Bytecode = ICOXRC20Data['bytecode'];
          XRC721ABI = XRC721Data['abi'];
          XRC721Bytecode = XRC721Data['bytecode'];
          StakeABI = StakingData['abi'];
          StakeBytecode = StakingData['bytecode'];
          XRC20VestingABI = XRC20VestingData['abi'];
          XRC20VestingBytecode = XRC20VestingData['bytecode'];
          DaoABI = DAOData['abi'];
          DaoBytecode = DAOData['bytecode'];
          DaoMultiSigABI = DaoMultiSigData['abi'];
          DaoMultiSigBytecode = DaoMultiSigData['bytecode'];
          CiFiLPStakeABI = CiFiLPStakeData['abi'];
          CiFiLPStakeBytecode = CiFiLPStakeData['bytecode'];

          GrootPresaleABI = GrootPresaleXDC['abi'];

          OrderBookABI = PolygonOrderBook['abi'];
          OrderBookAddress = PolygonOrderBook['address'];

          ERC20Abi = ERC20['abi'];
          OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

          BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
          DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
          TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
          SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
          HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
          TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
          STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
          USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])

        } else if (netId === 97) {
          console.log("*************** bnb");
          networkId = 97;
          chainId = "0x61";
          SocketUrl = development;
          explorer = 'https://testnet.bscscan.com'
          message = 'Please switch your network to Binance Testnet';

          CIFIABI = CiFiDataBinance['abi'];
          CIFIAddress = CiFiDataBinance['address'].toLowerCase();
          CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

          GrootABI = GrootDataBinance['abi'];
          GrootAddress = GrootDataBinance['address'].toLowerCase();
          Groot = new web3.eth.Contract(GrootABI, GrootAddress);

          GrootFactoryABI = GrootFactoryDataBinance['abi'];
          GrootFactoryAddress = GrootFactoryDataBinance['address'].toLowerCase();
          GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

          GrootRouterABI = GrootRouterDataBinance['abi'];
          GrootRouterAddress = GrootRouterDataBinance['address'];
          GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

          ArbitrageBotABI = BinanceArbitrageBot['abi'];
          ArbitrageBotAddress = BinanceArbitrageBot['address'];
          ArbitrageBotContract = new web3.eth.Contract(ArbitrageBotABI, ArbitrageBotAddress);

          TradeBotABI = tradeBot['abi'];
          TradeBotAddress = tradeBot['address'];
          TradeBotContract = new web3.eth.Contract(TradeBotABI, TradeBotAddress);

          OrderBookABI = BinanceOrderBook['abi'];
          OrderBookAddress = BinanceOrderBook['address'];

          ERC20Abi = ERC20['abi'];
          OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

          GrootILOABI = ILODataBinance['abi'];
          GrootILOAddress = ILODataBinance['address'];
          GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);

          CiFiAccessNFTABI = CiFiAccessNFTBinance['abi'];
          CiFiAccessNFTAddress = CiFiAccessNFTBinance['address'];
          CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

          GrootSubscriptionABI = SubscriptionDataBinance['abi'];
          GrootSubscriptionAddress = SubscriptionDataBinance['address'];
          GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

          MarketplaceABI = MarketplaceDataBinance['abi'];
          MarketplaceAddress = MarketplaceDataBinance['address'];
          Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

          XRC20ABI = XRC20Data['abi'];
          XRC20Bytecode = XRC20Data['bytecode'];
          XRC721ABI = XRC721Data['abi'];
          XRC721Bytecode = XRC721Data['bytecode'];
          StakeABI = StakingData['abi'];
          StakeBytecode = StakingData['bytecode'];
          XRC20VestingABI = XRC20VestingData['abi'];
          XRC20VestingBytecode = XRC20VestingData['bytecode'];
          DaoABI = DAOData['abi'];
          DaoBytecode = DAOData['bytecode'];
          DaoMultiSigABI = DaoMultiSigData['abi'];
          DaoMultiSigBytecode = DaoMultiSigData['bytecode'];

          GrootPresaleABI = GrootPresaleBinance['abi'];

          BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
          DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
          TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
          SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
          HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
          TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
          STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
          USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])

        } else if (netId === 51) {
          networkId = 51;
          chainId = "0x33";
          SocketUrl = development;
          explorer = 'https://explorer.apothem.network'
          message = 'Please switch your network to XDC Testnet';

          CifiStackAbi = CifiStack['abi'];
          CifiStackBytecode = CifiStack['bytecode'];
          CifiStackNFTAddressGOV = CifiStack['nftAddressGOV'];
          CifiStackTokenAddressGOV = CifiStack['tokenAddressGOV'];
          CifiStakeAddressGOV = CifiStack['stakeAddressGOV'];
          CifiStakeNFTAddressVIP = CifiStack['nftAddressVIP'];
          CifiStakeTokenAddressVIP = CifiStack['tokenAddressVIP'];
          CifiStakeAddressVIP = CifiStack['stakeAddressVIP'];

          CIFIABI = CiFiDataXDC['abi'];
          CIFIAddress = CiFiDataXDC['address'].toLowerCase();
          CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

          GrootABI = GrootDataXDC['abi'];
          GrootAddress = GrootDataXDC['address'].toLowerCase();
          Groot = new web3.eth.Contract(GrootABI, GrootAddress);

          GrootFactoryABI = GrootFactoryDataXDC['abi'];
          GrootFactoryAddress = GrootFactoryDataXDC['address'].toLowerCase();
          GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

          GrootPairABI = GrootPairDataXDC['abi'];

          GrootRouterABI = GrootRouterDataXDC['abi'];
          GrootRouterAddress = GrootRouterDataXDC['address'];
          GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

          GrootILOABI = ILODataXDC['abi'];
          GrootILOAddress = ILODataXDC['address'];
          GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);

          GrootSubscriptionABI = SubscriptionDataXDC['abi'];
          GrootSubscriptionAddress = SubscriptionDataXDC['address'];
          GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

          MarketplaceABI = MarketplaceDataXDC['abi'];
          MarketplaceAddress = MarketplaceDataXDC['address'];
          Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

          CiFiAccessNFTABI = CiFiAccessNFTXDC['abi'];
          CiFiAccessNFTAddress = CiFiAccessNFTXDC['address'];
          CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

          CiFiDAONFTABI = CiFiDAONFTXDC['abi'];
          CiFiDAONFTAddress = CiFiDAONFTXDC['address'];
          CiFiDAONFTContract = new web3.eth.Contract(CiFiDAONFTABI, CiFiDAONFTAddress);

          CiFiDAOABI = CiFiDAOXDC['abi'];
          CiFiDAOAddress = CiFiDAOXDC['address'];

          AdminWhitelistABI = AdminWhitelistXDC['abi'];
          AdminWhitelistAddress = AdminWhitelistXDC['address'];
          AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

          TokenAirDropABI = TOKENAIRDROP['abi'];
          TokenAirDropAddress = TOKENAIRDROP['address'];
          TokenAirDropContract = new web3.eth.Contract(TokenAirDropABI, TokenAirDropAddress);

          LiquidityPoolABI1 = LIQUIDITYPOOL1['abi'];
          LiquidityPoolAddress1 = LIQUIDITYPOOL1['address'];
          LiquidityPoolContract1 = new web3.eth.Contract(LiquidityPoolABI1, LiquidityPoolAddress1);

          LiquidityPoolABI2 = LIQUIDITYPOOL2['abi'];
          LiquidityPoolAddress2 = LIQUIDITYPOOL2['address'];
          LiquidityPoolContract2 = new web3.eth.Contract(LiquidityPoolABI2, LiquidityPoolAddress2);
          
          SPNFTABI = SPNFT['abi'];
          SPNFTAddress = SPNFT['address'];
          SPNFTContract = new web3.eth.Contract(SPNFTABI, SPNFTAddress);


          XRC20ABI = XRC20Data['abi'];
          XRC20Bytecode = XRC20Data['bytecode'];
          ICOXRC20ABI = ICOXRC20Data['abi'];
          ICOXRC20Bytecode = ICOXRC20Data['bytecode'];
          XRC721ABI = XRC721Data['abi'];
          XRC721Bytecode = XRC721Data['bytecode'];
          StakeABI = StakingData['abi'];
          StakeBytecode = StakingData['bytecode'];
          XRC20VestingABI = XRC20VestingData['abi'];
          XRC20VestingBytecode = XRC20VestingData['bytecode'];
          DaoABI = DAOData['abi'];
          DaoBytecode = DAOData['bytecode'];
          DaoMultiSigABI = DaoMultiSigData['abi'];
          DaoMultiSigBytecode = DaoMultiSigData['bytecode'];
          CiFiLPStakeABI = CiFiLPStakeData['abi'];
          CiFiLPStakeBytecode = CiFiLPStakeData['bytecode'];

          GrootPresaleABI = GrootPresaleXDC['abi'];

          OrderBookABI = XDCOrderBook['abi'];
          OrderBookAddress = XDCOrderBook['address'];

          ERC20Abi = ERC20['abi'];
          OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

          TradeBotABI = tradeBot['abi'];
          TradeBotAddress = tradeBot['address'];
          TradeBotContract = new web3.eth.Contract(TradeBotABI, TradeBotAddress);

          BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
          DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
          TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
          SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
          HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
          TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
          STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
          USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])

        } else {
          console.log("*************** poly");
          networkId = 80002;
          chainId = "0x33";
          SocketUrl = development;
          explorer = 'https://www.oklink.com/amoy';
          message = 'Please switch your network to Mumbai Testnet';

          CIFIABI = CiFiDataPolygon['abi'];
          CIFIAddress = CiFiDataPolygon['address'].toLowerCase();
          CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

          GrootABI = GrootDataPolygon['abi'];
          GrootAddress = GrootDataPolygon['address'].toLowerCase();
          Groot = new web3.eth.Contract(GrootABI, GrootAddress);

          OrderBookABI = XDCOrderBook['abi'];
          OrderBookAddress = XDCOrderBook['address'];

          ERC20Abi = ERC20['abi'];
          OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

          TradeBotABI = tradeBot['abi'];
          TradeBotAddress = tradeBot['address'];
          TradeBotContract = new web3.eth.Contract(TradeBotABI, TradeBotAddress);

          GrootFactoryABI = GrootFactoryDataPolygon['abi'];
          GrootFactoryAddress = GrootFactoryDataPolygon['address'].toLowerCase();
          GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

          GrootRouterABI = GrootRouterDataPolygon['abi'];
          GrootRouterAddress = GrootRouterDataPolygon['address'];
          GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

          CiFiAccessNFTABI = CiFiAccessNFTPolygon['abi'];
          CiFiAccessNFTAddress = CiFiAccessNFTPolygon['address'];
          CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

          GrootILOABI = ILODataPolygon['abi'];
          GrootILOAddress = ILODataPolygon['address'];
          GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);


          GrootSubscriptionABI = SubscriptionDataPolygon['abi'];
          GrootSubscriptionAddress = SubscriptionDataPolygon['address'];
          GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

          MarketplaceABI = MarketplaceDataPolygon['abi'];
          MarketplaceAddress = MarketplaceDataPolygon['address'];
          Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

          XRC20ABI = XRC20Data['abi'];
          XRC20Bytecode = XRC20Data['bytecode'];
          XRC721ABI = XRC721Data['abi'];
          XRC721Bytecode = XRC721Data['bytecode'];
          StakeABI = StakingData['abi'];
          StakeBytecode = StakingData['bytecode'];
          XRC20VestingABI = XRC20VestingData['abi'];
          XRC20VestingBytecode = XRC20VestingData['bytecode'];
          DaoABI = DAOData['abi'];
          DaoBytecode = DAOData['bytecode'];
          OrderBookABI = PolygonOrderBook['abi'];
          OrderBookAddress = PolygonOrderBook['address'];
          DaoMultiSigABI = DaoMultiSigData['abi'];
          DaoMultiSigBytecode = DaoMultiSigData['bytecode'];

          GrootPresaleABI = GrootPresalePolygon['abi'];

          BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
          DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
          TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
          SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
          HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
          TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
          STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
          USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])
        }
      });
    } else if (AppMode[0] === 'production') {
      web3.eth.net.getId(async (err, netId) => {
        if (netId === 137) {
          networkId = 137;
          chainId = "0x89";
          SocketUrl = production;
          message = 'Please switch your network to Polygon Mainnet';
          explorer = 'https://polygonscan.com'

          CIFIABI = CiFiDataBinance['abi'];
          CIFIAddress = CiFiDataBinance['address'].toLowerCase();
          CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

          GrootABI = GrootDataBinance['abi'];
          GrootAddress = GrootDataBinance['address'].toLowerCase();
          Groot = new web3.eth.Contract(GrootABI, GrootAddress);

          GrootFactoryABI = GrootFactoryDataBinance['abi'];
          GrootFactoryAddress = GrootFactoryDataBinance['address'].toLowerCase();
          GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

          GrootRouterABI = GrootRouterDataBinance['abi'];
          GrootRouterAddress = GrootRouterDataBinance['address'];
          GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

          CiFiAccessNFTABI = CiFiAccessNFTPoly['abi'];
          CiFiAccessNFTAddress = CiFiAccessNFTPoly['address'];
          CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

          OrderBookABI = PolygonOrderBook['abi'];
          OrderBookAddress = PolygonOrderBook['address'];

          ERC20Abi = ERC20['abi'];
          OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

          TradeBotABI = tradeBot['abi'];
          TradeBotAddress = tradeBot['address'];
          TradeBotContract = new web3.eth.Contract(TradeBotABI, TradeBotAddress);

          GrootILOABI = ILODataPolygon['abi'];
          GrootILOAddress = ILODataPolygon['address'];
          GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);

          GrootSubscriptionABI = SubscriptionDataPolygon['abi'];
          GrootSubscriptionAddress = SubscriptionDataPolygon['address'];
          GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

          MarketplaceABI = MarketplaceDataPolygon['abi'];
          MarketplaceAddress = MarketplaceDataPolygon['address'];
          Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

          XRC20ABI = XRC20Data['abi'];
          XRC20Bytecode = XRC20Data['bytecode'];
          XRC721ABI = XRC721Data['abi'];
          XRC721Bytecode = XRC721Data['bytecode'];
          StakeABI = StakingData['abi'];
          StakeBytecode = StakingData['bytecode'];
          XRC20VestingABI = XRC20VestingData['abi'];
          XRC20VestingBytecode = XRC20VestingData['bytecode'];
          DaoABI = DAOData['abi'];
          DaoBytecode = DAOData['bytecode'];
          DaoMultiSigABI = DaoMultiSigData['abi'];
          DaoMultiSigBytecode = DaoMultiSigData['bytecode'];

          GrootPresaleABI = GrootPresalePolygon['abi'];

          BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
          DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
          TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
          SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
          HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
          TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
          STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
          USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])
        } else if (netId === 56) {
          networkId = 56;
          chainId = "0x38";
          SocketUrl = production;
          message = 'Please switch your network to Binance Mainnet';
          explorer = 'https://bscscan.com'

          CIFIABI = CiFiDataBinance['abi'];
          CIFIAddress = CiFiDataBinance['address'].toLowerCase();
          CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

          GrootABI = GrootDataBinance['abi'];
          GrootAddress = GrootDataBinance['address'].toLowerCase();
          Groot = new web3.eth.Contract(GrootABI, GrootAddress);

          CiFiAccessNFTABI = CiFiAccessNFTBinance['abi'];
          CiFiAccessNFTAddress = CiFiAccessNFTBinance['address'];
          CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

          GrootFactoryABI = GrootFactoryDataBinance['abi'];
          GrootFactoryAddress = GrootFactoryDataBinance['address'].toLowerCase();
          GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

          GrootRouterABI = GrootRouterDataBinance['abi'];
          GrootRouterAddress = GrootRouterDataBinance['address'];
          GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

          ArbitrageBotABI = BinanceArbitrageBot['abi'];
          ArbitrageBotAddress = BinanceArbitrageBot['address'];
          ArbitrageBotContract = new web3.eth.Contract(ArbitrageBotABI, ArbitrageBotAddress);

          TradeBotABI = tradeBot['abi'];
          TradeBotAddress = tradeBot['address'];
          TradeBotContract = new web3.eth.Contract(TradeBotABI, TradeBotAddress);

          OrderBookABI = BinanceOrderBook['abi'];
          OrderBookAddress = BinanceOrderBook['address'];

          ERC20Abi = ERC20['abi'];
          OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

          GrootILOABI = ILODataBinance['abi'];
          GrootILOAddress = ILODataBinance['address'];
          GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);

          GrootSubscriptionABI = SubscriptionDataBinance['abi'];
          GrootSubscriptionAddress = SubscriptionDataBinance['address'];
          GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

          MarketplaceABI = MarketplaceDataBinance['abi'];
          MarketplaceAddress = MarketplaceDataBinance['address'];
          Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

          XRC20ABI = XRC20Data['abi'];
          XRC20Bytecode = XRC20Data['bytecode'];
          XRC721ABI = XRC721Data['abi'];
          XRC721Bytecode = XRC721Data['bytecode'];
          StakeABI = StakingData['abi'];
          StakeBytecode = StakingData['bytecode'];
          XRC20VestingABI = XRC20VestingData['abi'];
          XRC20VestingBytecode = XRC20VestingData['bytecode'];
          DaoABI = DAOData['abi'];
          DaoBytecode = DAOData['bytecode'];
          DaoMultiSigABI = DaoMultiSigData['abi'];
          DaoMultiSigBytecode = DaoMultiSigData['bytecode'];

          GrootPresaleABI = GrootPresaleBinance['abi'];

          BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
          DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
          TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
          SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
          HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
          TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
          STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
          USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])

        } else if (netId === 50) {
          networkId = 50;
          chainId = "0x32";
          SocketUrl = production;
          message = 'Please switch your network to XDC Mainnet';
          explorer = 'https://xdc.blocksscan.io'

          CIFIABI = CiFiDataXDC['abi'];
          CIFIAddress = CiFiDataXDC['address'].toLowerCase();
          CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

          GrootABI = GrootDataXDC['abi'];
          GrootAddress = GrootDataXDC['address'].toLowerCase();
          Groot = new web3.eth.Contract(GrootABI, GrootAddress);

          GrootFactoryABI = GrootFactoryDataXDC['abi'];
          GrootFactoryAddress = GrootFactoryDataXDC['address'].toLowerCase();
          GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

          GrootRouterABI = GrootRouterDataXDC['abi'];
          GrootRouterAddress = GrootRouterDataXDC['address'];
          GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

          GrootILOABI = ILODataXDC['abi'];
          GrootILOAddress = ILODataXDC['address'];
          GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);

          GrootSubscriptionABI = SubscriptionDataXDC['abi'];
          GrootSubscriptionAddress = SubscriptionDataXDC['address'];
          GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

          MarketplaceABI = MarketplaceDataXDC['abi'];
          MarketplaceAddress = MarketplaceDataXDC['address'];
          Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

          CiFiAccessNFTABI = CiFiAccessNFTXDC['abi'];
          CiFiAccessNFTAddress = CiFiAccessNFTXDC['address'];
          CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

          CiFiDAOABI = CiFiDAOXDC['abi'];
          CiFiDAOAddress = CiFiDAOXDC['address'];

          AdminWhitelistABI = AdminWhitelistXDC['abi'];
          AdminWhitelistAddress = AdminWhitelistXDC['address'];
          AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

          TokenAirDropABI = TOKENAIRDROP['abi'];
          TokenAirDropAddress = TOKENAIRDROP['address'];
          TokenAirDropContract = new web3.eth.Contract(TokenAirDropABI, TokenAirDropAddress);

          XRC20ABI = XRC20Data['abi'];
          XRC20Bytecode = XRC20Data['bytecode'];
          ICOXRC20ABI = ICOXRC20Data['abi'];
          ICOXRC20Bytecode = ICOXRC20Data['bytecode'];
          XRC721ABI = XRC721Data['abi'];
          XRC721Bytecode = XRC721Data['bytecode'];
          StakeABI = StakingData['abi'];
          StakeBytecode = StakingData['bytecode'];
          XRC20VestingABI = XRC20VestingData['abi'];
          XRC20VestingBytecode = XRC20VestingData['bytecode'];
          DaoABI = DAOData['abi'];
          DaoBytecode = DAOData['bytecode'];
          DaoMultiSigABI = DaoMultiSigData['abi'];
          DaoMultiSigBytecode = DaoMultiSigData['bytecode'];

          GrootPresaleABI = GrootPresaleXDC['abi'];

          OrderBookABI = XDCOrderBook['abi'];
          OrderBookAddress = XDCOrderBook['address'];

          ERC20Abi = ERC20['abi'];
          OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

          TradeBotABI = tradeBot['abi'];
          TradeBotAddress = tradeBot['address'];
          TradeBotContract = new web3.eth.Contract(TradeBotABI, TradeBotAddress);

          BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
          DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
          TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
          SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
          HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
          TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
          STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
          USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])
        } else {
          networkId = 137;
          chainId = "0x89";
          SocketUrl = production;
          message = 'Please switch your network to Polygon Mainnet';
          explorer = 'https://polygonscan.com'

          CIFIABI = CiFiDataPolygon['abi'];
          CIFIAddress = CiFiDataPolygon['address'].toLowerCase();
          CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

          GrootABI = GrootDataPolygon['abi'];
          GrootAddress = GrootDataPolygon['address'].toLowerCase();
          Groot = new web3.eth.Contract(GrootABI, GrootAddress);

          GrootFactoryABI = GrootFactoryDataPolygon['abi'];
          GrootFactoryAddress = GrootFactoryDataPolygon['address'].toLowerCase();
          GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

          CiFiAccessNFTABI = CiFiAccessNFTPoly['abi'];
          CiFiAccessNFTAddress = CiFiAccessNFTPoly['address'];
          CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

          GrootRouterABI = GrootRouterDataPolygon['abi'];
          GrootRouterAddress = GrootRouterDataPolygon['address'];
          GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

          OrderBookABI = PolygonOrderBook['abi'];
          OrderBookAddress = PolygonOrderBook['address'];

          ERC20Abi = ERC20['abi'];
          OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

          TradeBotABI = tradeBot['abi'];
          TradeBotAddress = tradeBot['address'];
          TradeBotContract = new web3.eth.Contract(TradeBotABI, TradeBotAddress);

          GrootILOABI = ILODataPolygon['abi'];
          GrootILOAddress = ILODataPolygon['address'];
          GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);

          GrootSubscriptionABI = SubscriptionDataPolygon['abi'];
          GrootSubscriptionAddress = SubscriptionDataPolygon['address'];
          GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

          MarketplaceABI = MarketplaceDataPolygon['abi'];
          MarketplaceAddress = MarketplaceDataPolygon['address'];
          Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

          XRC20ABI = XRC20Data['abi'];
          XRC20Bytecode = XRC20Data['bytecode'];
          XRC721ABI = XRC721Data['abi'];
          XRC721Bytecode = XRC721Data['bytecode'];
          StakeABI = StakingData['abi'];
          StakeBytecode = StakingData['bytecode'];
          XRC20VestingABI = XRC20VestingData['abi'];
          XRC20VestingBytecode = XRC20VestingData['bytecode'];
          DaoABI = DAOData['abi'];
          DaoBytecode = DAOData['bytecode'];
          DaoMultiSigABI = DaoMultiSigData['abi'];
          DaoMultiSigBytecode = DaoMultiSigData['bytecode'];

          GrootPresaleABI = GrootPresalePolygon['abi'];

          BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
          DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
          TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
          SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
          HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
          TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
          STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
          USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])
        }
      });
    } else {
      networkId = 51;
      chainId = "0x33";
      SocketUrl = development;
      explorer = 'https://explorer.apothem.network'
      message = 'Please switch your network to XDC Testnet';

      CifiStackAbi = CifiStack['abi'];
      CifiStackBytecode = CifiStack['bytecode'];
      CifiStackNFTAddressGOV = CifiStack['nftAddressGOV'];
      CifiStackTokenAddressGOV = CifiStack['tokenAddressGOV'];
      CifiStakeAddressGOV = CifiStack['stakeAddressGOV'];
      CifiStakeNFTAddressVIP = CifiStack['nftAddressVIP'];
      CifiStakeTokenAddressVIP = CifiStack['tokenAddressVIP'];
      CifiStakeAddressVIP = CifiStack['stakeAddressVIP'];

      CIFIABI = CiFiDataXDC['abi'];
      CIFIAddress = CiFiDataXDC['address'].toLowerCase();
      CIFI = new web3.eth.Contract(CIFIABI, CIFIAddress);

      GrootABI = GrootDataXDC['abi'];
      GrootAddress = GrootDataXDC['address'].toLowerCase();
      Groot = new web3.eth.Contract(GrootABI, GrootAddress);

      GrootFactoryABI = GrootFactoryDataXDC['abi'];
      GrootFactoryAddress = GrootFactoryDataXDC['address'].toLowerCase();
      GrootFactory = new web3.eth.Contract(GrootFactoryABI, GrootFactoryAddress);

      GrootPairABI = GrootPairDataXDC['abi'];

      GrootRouterABI = GrootRouterDataXDC['abi'];
      GrootRouterAddress = GrootRouterDataXDC['address'];
      GrootRouter = new web3.eth.Contract(GrootRouterABI, GrootRouterAddress);

      GrootILOABI = ILODataXDC['abi'];
      GrootILOAddress = ILODataXDC['address'];
      GrootILO = new web3.eth.Contract(GrootILOABI, GrootILOAddress);

      GrootSubscriptionABI = SubscriptionDataXDC['abi'];
      GrootSubscriptionAddress = SubscriptionDataXDC['address'];
      GrootSubscription = new web3.eth.Contract(GrootSubscriptionABI, GrootSubscriptionAddress);

      CiFiAccessNFTABI = CiFiAccessNFTXDC['abi'];
      CiFiAccessNFTAddress = CiFiAccessNFTXDC['address'];
      CiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);

      CiFiDAONFTABI = CiFiDAONFTXDC['abi'];
      CiFiDAONFTAddress = CiFiDAONFTXDC['address'];
      CiFiDAONFTContract = new web3.eth.Contract(CiFiDAONFTABI, CiFiDAONFTAddress);

      CiFiDAOABI = CiFiDAOXDC['abi'];
      CiFiDAOAddress = CiFiDAOXDC['address'];
      
      MarketplaceABI = MarketplaceDataXDC['abi'];
      MarketplaceAddress = MarketplaceDataXDC['address'];
      Marketplace = new web3.eth.Contract(MarketplaceABI, MarketplaceAddress);

      AdminWhitelistABI = AdminWhitelistXDC['abi'];
      AdminWhitelistAddress = AdminWhitelistXDC['address'];
      AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

      TokenAirDropABI = TOKENAIRDROP['abi'];
      TokenAirDropAddress = TOKENAIRDROP['address'];
      TokenAirDropContract = new web3.eth.Contract(TokenAirDropABI, TokenAirDropAddress);

      LiquidityPoolABI1 = LIQUIDITYPOOL1['abi'];
      LiquidityPoolAddress1 = LIQUIDITYPOOL1['address'];
      LiquidityPoolContract1 = new web3.eth.Contract(LiquidityPoolABI1, LiquidityPoolAddress1);

      LiquidityPoolABI2 = LIQUIDITYPOOL2['abi'];
      LiquidityPoolAddress2 = LIQUIDITYPOOL2['address'];
      LiquidityPoolContract2 = new web3.eth.Contract(LiquidityPoolABI2, LiquidityPoolAddress2);

      SPNFTABI = SPNFT['abi'];
      SPNFTAddress = SPNFT['address'];
      SPNFTContract = new web3.eth.Contract(SPNFTABI, SPNFTAddress);

      XRC20ABI = XRC20Data['abi'];
      XRC20Bytecode = XRC20Data['bytecode'];
      ICOXRC20ABI = ICOXRC20Data['abi'];
      ICOXRC20Bytecode = ICOXRC20Data['bytecode'];
      XRC721ABI = XRC721Data['abi'];
      XRC721Bytecode = XRC721Data['bytecode'];
      StakeABI = StakingData['abi'];
      StakeBytecode = StakingData['bytecode'];
      XRC20VestingABI = XRC20VestingData['abi'];
      XRC20VestingBytecode = XRC20VestingData['bytecode'];
      DaoABI = DAOData['abi'];
      DaoBytecode = DAOData['bytecode'];
      DaoMultiSigABI = DaoMultiSigData['abi'];
      DaoMultiSigBytecode = DaoMultiSigData['bytecode'];
      CiFiLPStakeABI = CiFiLPStakeData['abi'];
      CiFiLPStakeBytecode = CiFiLPStakeData['bytecode'];

      GrootPresaleABI = GrootPresaleXDC['abi'];

      OrderBookABI = XDCOrderBook['abi'];
      OrderBookAddress = XDCOrderBook['address'];

      ERC20Abi = ERC20['abi'];
      OrderBookContract = new web3.eth.Contract(OrderBookABI, OrderBookAddress);

      TradeBotABI = tradeBot['abi'];
      TradeBotAddress = tradeBot['address'];
      TradeBotContract = new web3.eth.Contract(TradeBotABI, TradeBotAddress);

      BORROW = new web3.eth.Contract(BorrowOperations['abi'], BorrowOperations['address'])
      DE_FRANC = new web3.eth.Contract(DeFranc['abi'], DeFranc['address'])
      TROVE_HELPERS = new web3.eth.Contract(TroveHelpers['abi'], TroveHelpers['address'])
      SORTEDTROVES = new web3.eth.Contract(SortedTroves['abi'], SortedTroves['address'])
      HINT_HELPERS = new web3.eth.Contract(HintHelpers['abi'], HintHelpers['address'])
      TROVE_MANAGER = new web3.eth.Contract(TroveManager['abi'], TroveManager['address'])
      STAKING = new web3.eth.Contract(Staking['abi'], Staking['address'])
      USDG = new web3.eth.Contract(usdg['abi'], usdg['address'])
    }
  }
}
let ApiUrl = `${development}/api`;
const socket = io(development);

export {
  AppTitle, ApiUrl, networkId, message, explorer, env, GrootABI, GrootAddress, Groot,
  GrootFactoryABI, GrootFactoryAddress, GrootPairABI, GrootFactory, GrootRouterABI, GrootRouterAddress, GrootRouter,
  OrderBookABI, OrderBookAddress, ERC20Abi, OrderBookContract,
  GrootBinanceABI, GrootBinanceAddress, Marketplace, MarketplaceABI, MarketplaceAddress,
  GrootBinanceFactoryContract, GrootBinanceFactoryAbi, GrootBinanceFactoryAddress,
  ArbitrageBotABI, ArbitrageBotAddress, ArbitrageBotContract,
  TradeBotABI, TradeBotAddress, TradeBotContract,
  XRC20VestingABI, XRC20VestingBytecode, ICOXRC20ABI, ICOXRC20Bytecode,
  BinanceOrderBookAddress, BinanceERC20Abi, BinanceOrderBookContract,
  XDCOrderBookABI, XDCOrderBookAddress, XDCERC20Abi, XDCOrderBookContract, GrootILO, GrootILOABI, GrootILOAddress,
  GrootPresaleABI, GrootSubscriptionABI, GrootSubscriptionAddress, GrootSubscription, XRC20ABI, XRC20Bytecode, XRC721ABI,
  XRC721Bytecode, StakeABI, StakeBytecode, DaoMultiSigABI, DaoMultiSigBytecode,
  BORROW, DE_FRANC, TROVE_HELPERS, SORTEDTROVES, HINT_HELPERS, TROVE_MANAGER, STAKING, USDG, DaoABI, DaoBytecode,
  CIFI, CIFIABI, CIFIAddress, CiFiAccessNFTAddress, CiFiAccessNFTContract, CiFiAccessNFTABI, CiFiDAONFTAddress, CiFiDAONFTContract,
  AdminWhitelist, AdminWhitelistAddress, AdminWhitelistABI,
  CiFiDAOAddress, CiFiDAOABI, CiFiDAONFTABI, CiFiLPStakeABI, CiFiLPStakeBytecode,
  TokenAirDropABI,TokenAirDropAddress,TokenAirDropContract,
  LiquidityPoolABI1,LiquidityPoolAddress1,LiquidityPoolContract1,
  LiquidityPoolABI2,LiquidityPoolAddress2,LiquidityPoolContract2,
  SPNFTABI,SPNFTContract,SPNFTAddress,
  chainId,
  CifiStackAbi , CifiStackBytecode , CifiStackNFTAddressGOV , CifiStackTokenAddressGOV , CifiStakeAddressGOV ,
  CifiStakeNFTAddressVIP ,  CifiStakeTokenAddressVIP ,  CifiStakeAddressVIP ,  
  socket,
  isTestnet,
  setProviderAddress
};
