import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import { Form, Button } from 'react-bootstrap';
import stakenft from "../../images/stakenft.png";
import React, { useState, useEffect } from 'react';
import alternate from "../../images/alternate.jpg";
import modalcloseicon from "../../images/close.png";
import infocircle from "../../images/infocircle.svg";
import claimstepinfo from "../../images/claim-step-info.png";
import microeconomylogo from "../../images/microeconomy-icon.png";
import stakeyournftstepinfo from "../../images/stake-your-nft-step-info.png";
import unstakeyournftstepinfo from "../../images/unstake-your-nft-step-info.png";
import { setLoader, getXRC721, getXRC20, getStaking, setBalance, setGrootBalance, stakeNFT, unStakeNFT, getStakedNFT, getKybStatus, getKycStatus } from "../../store/actions/Auth";
import { XRC721ABI, StakeABI, Groot, CIFI, XRC20ABI, CiFiAccessNFTAddress, CiFiAccessNFTContract, CiFiAccessNFTABI, AdminWhitelist, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";
import { useNavigate } from "react-router-dom";


const StakeNft = (props) => {

  let [data, setData] = useState({});
  let [from, setFrom] = useState("");
  let [stakings, setStakings] = useState([]);
  let [tokenIds, setTokenIds] = useState([]);
  let [tokenIds2, setTokenIds2] = useState([]);
  let [tokenId, setTokenId] = useState("");
  let [tokenId2, setTokenId2] = useState("");

  let [isNFT, setIsNFT] = useState();
  let [isWhitelist, setIsWhitelist] = useState(false);

  let [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  let [microeconomyStakeModal, setMicroeconomyStakeModal] = useState(false);
  let [microeconomyUnStakeModal, setMicroeconomyUnStakeModal] = useState(false);
  const [stepModal1, setStepModal1] = useState(true);
  const [stepModal2, setStepModal2] = useState(false);
  const [stepModal3, setStepModal3] = useState(false);

  const navigate = useNavigate()
  


  const [model, setModel] = useState(true);

  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');

    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  const getNFTs = async () => {
    if (CiFiAccessNFTABI && CiFiAccessNFTAddress) {
      props.setLoader({ message: "Load Microeconomy...", status: true });
      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      if (newCiFiAccessNFTContract) {
        let tokenBalance = await newCiFiAccessNFTContract.methods.balanceOf(props.publicAddress).call({ from: props.publicAddress });
        // tokenBalance =  1; //tesing purpose
        setIsNFT(tokenBalance);
        if (Number(tokenBalance) >= 1) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Load Microeconomy...", status: false });
      }
    }

    if (AdminWhitelistABI && AdminWhitelistAddress) {
      props.setLoader({ message: "Loading...", status: true });
      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      if (newAdminWhitelist) {
        let status = await newAdminWhitelist.methods.isWhitelist(props.publicAddress).call({ from: props.publicAddress });
        // status =  true; //tesing purpose
        setIsWhitelist(status);
        if (status == true) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Loading...", status: false });
      }
    }

  }

  useEffect(() => {
    if (props.publicAddress  && web3) {
      getNFTs();
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    props.getXRC20();
    props.getXRC721();
    props.getStaking();
    if (props.publicAddress == null || props.publicAddress == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    } else {
      let address = props.publicAddress;
      let output = props.publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        address = "0x" + props.publicAddress.substring(3);
        setFrom(address);
      } else {
        address = props.publicAddress;
        setFrom(address);
      }
    }
  }, []);

  async function setStakeData() {
    const updatedStakings = await Promise.all(
      props.stakingList.filter(a=>parseInt(a.network) == parseInt(props.selectDefaultNetwok)).map(async (item) => {
          return { ...item };
      })
    );
    setStakings(updatedStakings);
  }

  useEffect(() => {
    if (props.stakingList.length > 0 && web3) {
      setStakeData();
    }
  }, [props.stakingList, props.selectDefaultNetwok, web3]);

  async function setInstance(item) {
    setMicroeconomyStakeModal(true);
    setData(item);
    let address = (await web3.currentProvider.enable())[0];
    props.getStakedNFT({ stakeAddress: item['stakeAddress'], staker: address });
    let contract = new web3.eth.Contract(XRC721ABI, item['nftAddress']);
    let holdings = await contract.methods.walletOfOwner(from).call();
    if (holdings.length == 0) {
      setTokenIds([]);
    } else {
      setTokenIds(holdings);
    }
  }

  useEffect(() => {
    if (props.stakedNFTlist.length > 0) {
      if(props.stakedNFTlist.length > 0){
        setTokenIds2(props.stakedNFTlist[0]['nfts']);
      }
    }
  }, [props.stakedNFTlist, props.selectDefaultNetwok]);

  async function setInstance1(item) {
    setMicroeconomyUnStakeModal(true);
    setData(item);
    let address = (await web3.currentProvider.enable())[0];
    props.getStakedNFT({ stakeAddress: item['stakeAddress'], staker: address });
  }

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "tokenId") {
      setTokenId(parseInt(e.target.value));
    } else if ([e.target.name] == "tokenId2") {
      setTokenId2(parseInt(e.target.value));
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function stake(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      // if (props.kycStatus !== "adminApproved") {
      //   EventBus.publish("error", `Submit KYC verification to access this function!`);
      //   return;
      // }

      if (tokenIds.length == 0) {
        EventBus.publish("error", `You don't own NFT from this collection!`);
        return;
      }

      if (tokenId == undefined) {
        setTokenId(parseInt(tokenIds[0]));
      }

      let deployer = (await web3.currentProvider.enable())[0];
      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      props.setLoader({
        message: "Approval in Progress...",
        status: true,
      });


      let contract = new web3.eth.Contract(XRC721ABI, data['nftAddress']);
      let contract1 = new web3.eth.Contract(StakeABI, data['stakeAddress']);

      await contract.methods.approve(data['stakeAddress'], tokenId ? tokenId : parseInt(tokenIds[0])).send({
        from: deployer,
      });

      props.setLoader({ status: false });

      props.setLoader({
        message: "Stake in Progress...",
        status: true,
      });

      tokenId = tokenId ? tokenId : parseInt(tokenIds[0])

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: data['stakeAddress'],
          gas: 5000000,
          data: contract1.methods
            .stake(tokenId ? tokenId : parseInt(tokenIds[0]))
            .encodeABI(),
        })
        .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          await setInstance(data);
          await props.stakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId });
          await refreshBalance();
          setMicroeconomyStakeModal(false);
          setTokenId("");
          props.setLoader({ status: false });
          EventBus.publish("success", `NFT Staked Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Stake Not Completed...",
        status: false,
      });
      setData({});
      setMicroeconomyStakeModal(false);
      setTokenId("");
      EventBus.publish("error", `Staking Failed`);
    }
  };

  async function unStake(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      // if (props.kycStatus !== "adminApproved") {
      //   EventBus.publish("error", `Submit KYC verification to access this function!`);
      //   return;
      // }

      if (tokenIds2.length == 0) {
        EventBus.publish("error", `You have no NFTs staked in this collection yet!`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);

      let contract1 = new web3.eth.Contract(XRC20ABI, data['tokenAddress']);
      let availableBalance = await contract1.methods.balanceOf(data['stakeAddress']).call({ from: deployer });

      if (availableBalance <= 0) {
        EventBus.publish("info", `Insufficient contract balance to withdraw rewards, NFT will be withdrawn!`);
        // return;
      }

      if (tokenId2 == undefined) {
        setTokenId(parseInt(tokenIds2[0]));
      }

      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let contract = new web3.eth.Contract(StakeABI, data['stakeAddress']);

      let available = await contract.methods.totalStake(deployer).call({ from: deployer });

      if (parseInt(available['totalStake']) == 0) {
        props.setLoader({ status: false });
        EventBus.publish("error", `You have no NFTs staked in this collection yet!`);
        return;
      }

      props.setLoader({
        message: "Un-staking in Progress...",
        status: true,
      });

      tokenId2 = tokenId2 ? tokenId2 : parseInt(tokenIds2[0]);

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: data['stakeAddress'],
          gas: 5000000,
          data: contract.methods
            .withdraw(parseInt(tokenId2))
            .encodeABI(),
        })
        .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          await props.unStakeNFT({ stakeAddress: data['stakeAddress'], staker: deployer, tokenId: tokenId2 });
          await refreshBalance();
          setMicroeconomyUnStakeModal(false);
          props.setLoader({ status: false });
          setTokenId2("");
          EventBus.publish("success", `Un-staked Successfully!`);
        });
    } catch (e) {
      console.log(e);
      setMicroeconomyUnStakeModal(false);
      props.setLoader({
        message: "Un-stake Not Completed...",
        status: false,
      });
      setData({});
      EventBus.publish("error", `Un-staking Failed`);
    }
  };

  async function claim(item) {
    try {

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      // if (props.kycStatus !== "adminApproved") {
      //   EventBus.publish("error", `Submit KYC verification to access this function!`);
      //   return;
      // }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let contract = new web3.eth.Contract(StakeABI, item['stakeAddress']);

      let available = await contract.methods.totalStake(deployer).call({ from: deployer });

      if (parseInt(available['totalStake']) == 0) {
        EventBus.publish("error", `You have no NFTs staked in this collection yet!!`);
        return;
      }

      let contract1 = new web3.eth.Contract(XRC20ABI, data['tokenAddress']);
      let availableBalance = await contract1.methods.balanceOf(data['stakeAddress']).call({ from: deployer });

      if (availableBalance <= 0) {
        EventBus.publish("error", `Insufficient contract balance to withdraw rewards!`);
        return;
      }

      props.setLoader({
        message: "Claim in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: item['stakeAddress'],
          gas: 5000000,
          data: contract.methods
            .claimReward()
            .encodeABI(),
        })
        .on("transactionHash", hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          await refreshBalance();
          props.setLoader({ status: false });
          EventBus.publish("success", `Claimed Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Un-stake Not Completed...",
        status: false,
      });
      setData({});
      EventBus.publish("error", `Claim Failed`);
    }
  };

  const handleImageError = (idx) => {
    // Set the failed image to the fallback image
    stakings[idx]['nftBanner'] = alternate;
    setStakings([...stakings]);
  };
  

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="nft-collection">
        <div className="head">
          <h2>NFT Collections<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>
        </div>

        <div className="collection-wrap">
          {stakings.map((item, idx) => (
            <div className="box">
              <img key={idx} src={item['nftBanner'] ? item['nftBanner'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />

              <div className="content">
                <div className="info">
                  <div className="profile-info">
                    <div>
                      <h4>{item['nftName']}</h4>

                      <p>{item['nftSymbol']}</p>
                    </div>
                  </div>
                </div>

                <div className="btn-wrap">
                  <button className="active" onClick={() => setInstance(item)}>Stake</button>

                  <button onClick={() => setInstance1(item)}>Unstake</button>

                  <button onClick={() => claim(item)}>Claim</button>
                </div>
              </div>

            </div>
          ))
          }
        </div>
      </div>

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={stakenft} alt="" />

          <h3>Stake Your NFT’s</h3>

          <p>Simplify your staking process with our easy-to-use smart contract builder. Customize your staking rewards, duration, and other key features to meet your project's needs. Please mint your NFT in the "Explore your NFT Collection" before proceeding to stake your NFTs.</p>
        </div>
      </Modal>

      <Modal
        open={microeconomyStakeModal}
        onClose={() => setMicroeconomyStakeModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal stake-unstake-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyStakeModal(false)}
          />
        </button>

        <div className="modal-body nft-collection">
          <div className="collection-wrap">
            <h3 className="text-center">Stake Your NFT</h3>
            <div className="box">
              <div className="content form-wrap">
                <Form onSubmit={stake}>
                  <Form.Group>
                    <Form.Select
                      name="tokenId"
                      value={tokenId !== "" ? tokenId : tokenIds[0]}
                      onChange={handleOnInput}
                    >
                      {
                        tokenIds.length > 0 &&
                        tokenIds.map((item) => (
                          <option value={item}>
                            {`#${item} - ${data['nftSymbol']}`}
                          </option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>

                  <div>
                    <Button type="submit">
                      Confirm
                    </Button>

                    <Button onClick={() => setMicroeconomyStakeModal(false)}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

          </div>
        </div>
      </Modal>

      <Modal
        open={microeconomyUnStakeModal}
        onClose={() => setMicroeconomyUnStakeModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal stake-unstake-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyUnStakeModal(false)}
          />
        </button>

        <div className="modal-body nft-collection">
          <div className="collection-wrap">
            <h3 className="text-center">Unstake Your NFT</h3>
            <div className="box">
              <div className="content form-wrap">
                <Form onSubmit={unStake}>
                  <Form.Group>
                    <Form.Select
                      name="tokenId2"
                      value={tokenId2 !== "" ? tokenId2 : tokenIds2[0]}
                      onChange={handleOnInput}
                    >
                      {
                        tokenIds2.length > 0 &&
                        tokenIds2.map((item) => (
                          <option value={item}>
                            {`#${item} - ${data['nftSymbol']}`}
                          </option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>

                  <div>
                    <Button type="submit">
                      Confirm
                    </Button>

                    <Button onClick={() => setMicroeconomyUnStakeModal(false)}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal1}
        onClose={() => setStepModal1(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal1(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 1/3</h3>

            <i>Stake:</i>

            <p>Click <span>Stake</span> button and Select your NFT from the dropdown, that you want to stake.</p>
          </div>


          <img src={stakeyournftstepinfo} alt="" />

          <div className="steps-btns">
            <button disabled>Previous step</button>

            <button onClick={() => { setStepModal2(true); setStepModal1(false) }}>Next step</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal2}
        onClose={() => setStepModal2(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal2(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 2/3</h3>

            <i>Claim:</i>

            <p>Click <span>Claim</span> button to claim reward token of stake.</p>
          </div>


          <img src={claimstepinfo} alt="" />

          <div className="steps-btns">
            <button onClick={() => { setStepModal1(true); setStepModal2(false) }}>Previous step</button>

            <button onClick={() => { setStepModal3(true); setStepModal2(false) }}>Next step</button>
          </div>
        </div>
      </Modal>

      <Modal
        open={stepModal3}
        onClose={() => setStepModal3(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal3(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <h3>Step 3/3</h3>

            <i>Unstake:</i>

            <p>Click <span>Unstake</span> button and Select your NFT from the dropdown, that you want to unstake.</p>
          </div>

          <img src={unstakeyournftstepinfo} alt="" />

          <div className="steps-btns">
            <button onClick={() => { setStepModal2(true); setStepModal3(false) }}>Previous step</button>

            <button disabled>Next step</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  getXRC20,
  stakeNFT,
  setLoader,
  getXRC721,
  getStaking,
  setBalance,
  unStakeNFT,
  getStakedNFT,
  setGrootBalance,
  getKybStatus, 
  getKycStatus
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, xrc20Collections, xrc721Collections, stakingList, stakedNFTlist, currentMode, isLoader, kycStatus,selectDefaultNetwok } = Auth;
  // console.log("****************** xrc20Collections",xrc20Collections);
  // console.log("****************** xrc721Collections",xrc721Collections);
  // console.log("****************** stakedNFTlist",stakedNFTlist);
  console.log("****************** stakingList",stakingList);
  // console.log("****************** stakingList",stakingList);
  return { publicAddress, xrc20Collections, xrc721Collections, stakingList, stakedNFTlist, currentMode, isLoader, kycStatus,selectDefaultNetwok }
};

export default connect(mapStateToProps, mapDispatchToProps)(StakeNft);