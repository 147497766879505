import React from "react";
import { useState } from "react";
import { Modal } from "react-responsive-modal";
import AssetsIcon from "../../images/wallet.svg"
import modalcloseicon from "../../images/close.png";
import MarketplaceIcon from "../../images/shop.svg"
import infocircle from "../../images/infocircle.svg";
import DaoGenesislogo from "../../images/dao-genesis.png";
import TransactionsIcon from "../../images/receipt-item.svg"
import microeconomyicon from "../../images/microeconomy-icon.png";


export default function DaoGenesis({ mode }) {

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);

  return (
    <div className="microeconomy-wrapper dao-genesis">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={DaoGenesislogo} alt="" />

          <span>DAO Genesis</span>
        </a>

        <div className="btns">
          <button>Add CIFI to metamask</button>
          <button>Buy CIFI</button>
        </div>
        <div className="btn-wrap">
          <select>
            <option value="51">XDC</option>
            <option value="97">BSC</option>
            <option value="80002">Matic</option>
          </select>

          <button>1,581 BNB | 0xBBB6A7...6hn9</button>
        </div>
      </div>

      <div className="microeconomy-tool">
        <h2>Home<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

        <div className="detail-row">
          <ul className="detail-list">
            <li>
              <h3>My Assets</h3>
              <img src={AssetsIcon} alt="" />
            </li>
            <li>
              <h3>Transactions</h3>
              <img src={TransactionsIcon} alt="" />
            </li>
            <li>
              <h3>Marketplace</h3>
              <img src={MarketplaceIcon} alt="" />
            </li>
          </ul>
          <div className="donate-token">
            <h3>Donate Governance token</h3>
            <p>Token are to be collected into a treasury which will be governed by the <span>CiFi</span> finance governance.</p>
            <p>They will take all the donations received & convert 20% of all received donations per quarter into USDC to donate to charities on <span>BLOCKFUND</span>.</p>
          </div>
          <div className="btns donate-btn">
            <button>Donate</button>
          </div>
        </div>
      </div>

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${mode ? 'light' : 'dark'} microeconomy-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={microeconomyicon} alt="" />

          <h3>Micro Economy Tool</h3>

          <p>Empower your project with our revolutionary platform, designed to navigate the blockchain world effortlessly. Deploy NFTs, airdrops, tokens, and smart contracts even with limited resources or expertise.</p>
        </div>
      </Modal>
    </div>
  );
}
