import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import goarrow from "../../images/go-arrow.png";
import createnft from "../../images/createnft.png";
import React, { useEffect, useState } from "react";
import { validate } from "wallet-address-validator";
import modalcloseicon from "../../images/close.png";
import uploadicon from "../../images/uploadicon.png";
import infocircle from "../../images/infocircle.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import microeconomylogo from "../../images/microeconomy-icon.png";
import explorenftcollectionsstepinfo from "../../images/explore-nft-collections-step-info.png";
import { setLoader, uploadCollection, setGrootBalance, setBalance } from "../../store/actions/Auth";
import { networkId, XRC721ABI, XRC721Bytecode, Groot, GrootSubscription, GrootSubscriptionAddress, ApiUrl, CIFI } from "../../store/config";


const RegisterYourNonProfit = (props) => {

  const [BToken, setBToken] = useState("XDC");
  const [xrcToken, setxrcToken] = useState("");
  const [tokenUri, settokenUri] = useState("");
  const [tokenName, settokenName] = useState("");
  const [tokenPrice, settokenPrice] = useState("");
  const [tokenSupply, settokenSupply] = useState("");
  const [tokenBanner, settokenBanner] = useState("");
  const [tokenSymbol, settokenSymbol] = useState("");
  const [tokenAddress, settokenAddress] = useState("");
  const [tokenDescription, settokenDescription] = useState("");
  const [tokenWallet, settokenWallet] = useState(localStorage.getItem('publicAddress'));

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  const [stepModal, setStepModal] = useState(true);
  const [model, setModel] = useState(true);

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if(type === 'number'){
      event.preventDefault();
    }
  }
  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');

    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  useEffect(() => {
    if (networkId == 80002 || networkId == 137) {
      setBToken("MATIC");
    } else if (networkId == 97 || networkId == 56) {
      setBToken("BNB");
    } else if (networkId == 51 || networkId == 50) {
      setBToken("XDC");
    }
  }, [networkId]);

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "tokenName") {
      settokenName(e.target.value);
    } else if ([e.target.name] == "tokenSymbol") {
      settokenSymbol(e.target.value);
    } else if ([e.target.name] == "tokenUri") {
      let inputValue = e.target.value;
      if (inputValue && !inputValue.startsWith('https://')) {
        inputValue = 'https://' + inputValue;
        settokenUri(inputValue)
      } else {
        settokenUri(e.target.value);
      }

    } else if ([e.target.name] == "tokenDescription") {
      settokenDescription(e.target.value);
    } else if ([e.target.name] == "tokenSupply") {
      if (parseInt(e.target.value) > 0) {
        settokenSupply(parseInt(e.target.value));
      } else {
        settokenSupply("");
      }
    } else if ([e.target.name] == "tokenBanner") {
      settokenBanner(e.target.files[0]);
    } else if ([e.target.name] == "tokenPrice") {
      if (parseFloat(e.target.value) > 0) {
        settokenPrice(parseFloat(e.target.value));
      } else {
        settokenPrice("");
      }
    } else if ([e.target.name] == "tokenWallet") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        settokenWallet(result);
      } else {
        settokenWallet(e.target.value);
      }
    } else if ([e.target.name] == "xrcToken") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setxrcToken(result);
      } else {
        setxrcToken(e.target.value);
      }
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function getIPFSData(url) {
    return new Promise(async (resolve, reject) => {
      try {
        if (url && !url.endsWith("/")) url = url + "/";
        let data = await fetch(`${ApiUrl}/v3/validateURI`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
          },
          body: JSON.stringify({ url: `${url}1` }), // Convert your data to JSON format if needed
        });
        if (parseInt(data.status) == 200) {
          data = await data.json();
          return resolve(true);
        }
        if (parseInt(data.status) == 400) {
          let data = await fetch(`${ApiUrl}/v3/validateURI`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
            },
            body: JSON.stringify({ url: `${url}1.json` }), // Convert your data to JSON format if needed
          });
          if (parseInt(data.status) == 200) {
            return resolve(true);
          } else if (parseInt(data.status) == 400) {
            let data = await fetch(`${ApiUrl}/v3/validateURI`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json', // Set the content type to JSON if you're sending JSON data
              },
              body: JSON.stringify({ url }), // Convert your data to JSON format if needed
            });
            if (parseInt(data.status) == 200) {
              return resolve(true);
            }
            else return resolve(false);
          }
        }
      } catch (e) {
        return resolve(false);
      }
    });
  }

  async function deploy(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;
      let paymentToken = "";

      if (tokenName == "") {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!tokenName.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!tokenName.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token name must contain alphabets`);
        return;
      }

      if (tokenSymbol == "") {
        EventBus.publish("error", `Please enter token symbol`);
        return;
      }

      if (!tokenSymbol.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token symbol`);
        return;
      }

      if (!tokenSymbol.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token symbol must contain alphabets`);
        return;
      }

      if (tokenSupply == "") {
        EventBus.publish("error", `Please enter token supply`);
        return;
      }

      if (tokenUri == "") {
        EventBus.publish("error", `Please enter base URI`);
        return;
      }

      if (!tokenUri.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter base URI`);
        return;
      }

      if (!tokenUri.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Base URI must contain alphabets`);
        return;
      }

      let checkUrl = await getIPFSData(tokenUri);
      if (checkUrl == false) return EventBus.publish('error', `Invalid base URI`);

      if (tokenPrice == "") {
        EventBus.publish("error", `Please enter token price`);
        return;
      }

      if (tokenWallet == "") {
        EventBus.publish("error", `Please enter token wallet`);
        return;
      }

      if (!tokenWallet.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token wallet`);
        return;
      }

      if (!tokenWallet.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token wallet must contain alphabets`);
        return;
      }

      if (xrcToken == "") {
        setxrcToken("0x0000000000000000000000000000000000000000");
        paymentToken = "0x0000000000000000000000000000000000000000";
      } else {

        if (!xrcToken.replace(/\s/g, '').length) {
          EventBus.publish("error", `Please enter payment Token`);
          return;
        }

        if (!xrcToken.match(/^[a-zA-Z0-9]+$/)) {
          EventBus.publish("error", `Invalid Payment Token Address`);
          return;
        }

        let code = await web3.eth.getCode(xrcToken.toLowerCase());
        if (code === "0x") {
          EventBus.publish("error", `Invalid Payment Token Address!`);
          return;
        } else {
          paymentToken = xrcToken;
        }
      }

      if (tokenDescription == "") {
        EventBus.publish("error", `Please enter token description`);
        return;
      }

      if (!tokenDescription.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter token description`);
        return;
      }

      if (!tokenDescription.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Token description must contain alphabets`);
        return;
      }

      if (tokenBanner == "") {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      if (tokenBanner == undefined) {
        EventBus.publish("error", `Please upload token banner`);
        return;
      }

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      let sub = await GrootSubscription.methods.subscribeXRC721s(from).call();
      if (!sub['status']) {
        props.setLoader({
          message: "Subscription in Progress...",
          status: true,
        });
        let balance = await CIFI.methods.balanceOf(from).call();
        let req = web3.utils.toWei("50", "ether");
        if (parseInt(balance) < parseInt(req)) {
          props.setLoader({
            message: "Subscription Failed...",
            status: false,
          });
          setxrcToken("");
          EventBus.publish("error", `Don't have enough CIFI token to subscribe!`);
          return;
        } else {
          await CIFI.methods.approve(GrootSubscriptionAddress, req).send({ from: deployer });
          await GrootSubscription.methods.nftSubscription(req).send({ from: deployer });
        }
      }

      if (!tokenWallet.match(/^[a-zA-Z0-9]+$/)) {
        EventBus.publish("error", `Invalid Wallet Address`);
        return;
      }

      if (!validate(tokenWallet, "ETH")) {
        EventBus.publish("error", `Please provide valid wallet address`);
        return;
      }

      props.setLoader({
        message: "Deployment in Progress...",
        status: true,
      });

      let price = await web3.utils.toWei(tokenPrice.toString(), "ether");

      let tokenType = "XRC721"

      let contract = new web3.eth.Contract(XRC721ABI);
      let deploy = await contract.deploy({ data: XRC721Bytecode, arguments: [tokenName, tokenSymbol, 0, from, tokenSupply, tokenUri, price, tokenWallet, paymentToken] });

      await deploy.send({ from: deployer })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({
            message: "Contract Deploy...",
            status: false,
          });

          let data = new FormData();
          data.append('tokenName', tokenName);
          data.append('tokenSymbol', tokenSymbol);
          data.append('tokenDescription', tokenDescription);
          data.append('tokenWallet', tokenWallet);
          data.append('tokenAddress', receipt['contractAddress']);
          data.append('tokenSupply', tokenSupply);
          data.append('tokenPrice', web3.utils.fromWei(price.toString(), "ether"));
          data.append('tokenType', tokenType);
          data.append('from', from);
          data.append('banner', tokenBanner);
          data.append('paymentToken', paymentToken);
          data.append('network', parseInt(networkId));
          await props.uploadCollection(data);

          await refreshBalance();

          EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
          waitFor(1500);
          settokenAddress(receipt['contractAddress']);
          settokenName("");
          settokenSymbol("");
          settokenUri("");
          settokenDescription("");
          settokenSupply("");
          settokenBanner("");
          settokenPrice("");
          settokenWallet("");
          setxrcToken("");
          console.log(`************** deploy contract address = `, receipt['contractAddress'])
        });
    } catch (e) {
      console.log(e);
      setxrcToken("");
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Deploy`);
    }
  };

  async function copiedAddress() {
    EventBus.publish("success", "Contract Address Copied");
  }

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {
          (tokenName !== "" || tokenSymbol !== "" || tokenSupply !== "" || tokenBanner !== "" || tokenPrice !== "" || tokenDescription !== "" ||
            tokenUri !== "" || xrcToken !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="form-wrap">
        <div className="inner">
          <h2>Register your non-profit</h2>
          {
            tokenAddress !== "" &&
            <>
            <div className="copy-wrap">
              <p>
                <CopyToClipboard
                  text={tokenAddress}
                  onCopy={copiedAddress}
                >
                   {networkId == 137 ? (
                    <a href={`https://polygonscan.com/address/k${tokenAddress}`} target="_blank">
                      {`NFT Token Address:`}
                      <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                    </a>
                    ) : networkId == 80002 ? (
                      <a href={`https://www.oklink.com/amoy/address/${tokenAddress}`} target="_blank">
                        {`NFT Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 56 ? (
                      <a href={`https://bscscan.com/address/${tokenAddress}`} target="_blank">
                        {`NFT Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 97 ? (
                      <a href={`https://testnet.bscscan.com/address/${tokenAddress}`} target="_blank">
                        {`NFT Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 50 ? (
                      <a href={`https://xdc.blocksscan.io/address/${tokenAddress}`} target="_blank">
                        {`NFT Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 51 ? (
                      <a href={`https://apothem.blocksscan.io/address/${tokenAddress}`} target="_blank">
                        {`NFT Token Address:`}
                        <span> {tokenAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : (
                      <></>
                    )} 
                </CopyToClipboard>
              </p>
             
              </div>
              <p className="mb-4">You can visit (Explore NFTs) page to view your NFT</p>
            </>
          } 

          <form onSubmit={deploy}>
            <div className="form-group">
              <input
                type="text"
                name='nonProfitTitle'
                onChange={handleOnInput}
                placeholder="Non-profit title"
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Your Non-profit Title Here</p>
                </div>
              </div>
            </div>

            <div className="form-group">
               <input
                type="number"
                name='securityNumber'
                placeholder="Owner social security number"
                onWheel={handleOnWheel}
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Owner Social Security Number Here</p>
                </div>
              </div>
            </div>

            <div className="form-group">
              <input
                type="url"
                name='website'
                placeholder="Website"
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Insert Your Website Link Here</p>
                </div>
              </div>
            </div> 

            <div className="form-group">
              <input
                type="url"
                name='facebook'
                placeholder="Facebook"
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Insert Your Facebook Link Here</p>
                </div>
              </div>
            </div>

            <div className="form-group">
              <input
                type="url"
                name='linkedin'
                placeholder="Linkedin"
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Insert Your Linkedin Link Here</p>
                </div>
              </div>
            </div>

            <div className="form-group">
              <input
                type="url"
                name='twitter'
                placeholder="Twitter"
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Insert Your Twitter Link Here</p>
                </div>
              </div>
            </div>

            <div className="form-group filewrap">
                  <span>Business document</span>
                  <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload PDF File</div>
                  <input
                    type="file"
                    name='businessDocument'
                    placeholder="Upload PDF File*"
                  />

                  <div className="info-wrap">
                    <img src={infocircle} alt="" />

                    <div className="info">

                      <p>Upload Your Business Document Here</p>
                    </div>
                  </div>
            </div>

            <button type="submit">
              Submit
            </button>
          </form>

          <p>Note: You will need 1000 Groot Tokens for one time subscription to add staking to your collection.</p>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  uploadCollection,
  setBalance,
  setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, currentMode, isLoader } = Auth;
  return { publicAddress, currentMode, isLoader }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterYourNonProfit);
