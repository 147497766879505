import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import goarrow from "../../images/go-arrow.png";
import { validate } from "wallet-address-validator";
import modalcloseicon from "../../images/close.png";
import uploadicon from "../../images/uploadicon.png";
import infocircle from "../../images/infocircle.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import microeconomylogo from "../../images/microeconomy-icon.png";
import tokengenerationstepinfo from "../../images/token-generation-step-info.png";
import vestingstepinfo from "../../images/vesting-step-info.png";
import { setLoader, deployVestings, setBalance, setGrootBalance, getStaking } from "../../store/actions/Auth";
import { networkId, XRC20ABI, XRC721ABI, Groot, CIFI, GrootSubscription, GrootSubscriptionAddress, XRC20VestingABI, XRC20VestingBytecode } from "../../store/config";
import { useNavigate } from "react-router-dom";

const DeployVesting = (props) => {
  let [count, setCount] = useState(0);
  let [tokenName, settokenName] = useState("");
  let [tokenSymbol, settokenSymbol] = useState("");
  let [tokenSupply, settokenSupply] = useState("");
  let [tokenAddress, settokenAddress] = useState("");
  let [privatesale, setPrivatesale] = useState("");
  let [publicsale, setPublicsale] = useState("");
  let [deployedAddress, setDeployedAddress] = useState("");

  let [formValues, setFormValues] = useState([""]);
  let [titleArray, setTitleArray] = useState([]);
  let [beneficiaryArray, setBeneficiaryArray] = useState([]);
  let [amountArray, setAmountArray] = useState([]);
  let [durationArray, setDurationArray] = useState([]);

  let [tempStakeTitle, setTempStakeTitle] = useState("");
  let [tempStakeAddress, setTempStakeAddress] = useState("");
  let [tempStakeAmount, setTempStakeAmount] = useState("");
  let [tempStakeYears, setTempStakeYears] = useState("");

  let [model, setModel] = useState(true);
  let [stepModal, setStepModal] = useState(true);
  let [successModal, setSuccessModal] = useState(false);

  const navigate = useNavigate()


  const handleOnWheel = (event) => {
    const { type } = event.target;
    if(type === 'number'){
      event.preventDefault();
    }
  }
  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  setTimeout(() => {
    const modalRoot = document.querySelectorAll('.react-responsive-modal-root');
    if (modalRoot) {
      //Add a custom class to the root element

      modalRoot.forEach((element) => {
        const stepsModal = element.querySelector('.steps-modal');
        const confirmation1Modal = element.querySelector('.confirmation-modal');

        if (stepsModal) {
          // Perform your action here
          element.classList.add('custom-modal-root');
        }

        if (confirmation1Modal) {
          // Perform your action here
          element.classList.add('confirmation-modal-root');
        }
      });

    }
    else {
      console.error("Element not found.");
    }

  }, 1000);

  useEffect(() => {
    props.getStaking();
  }, []);

  async function setStakeData() {
    const updatedStakings = await Promise.all(
      props.stakingList.map(async (item) => {
        if (item['tokenAddress'].toLowerCase() == tokenAddress.toLowerCase()) {
          let contract = new web3.eth.Contract(XRC721ABI, item['nftAddress']);
          let price = await contract.methods.price().call();
          let tokenSupply = await contract.methods.tokenSupply().call();
          price = web3.utils.fromWei(price, 'ether');
          let tokens = (tokenSupply * price) / item['stakeAPY'];
          let precision = 0;
          let power = Math.pow(10, precision);
          tokens = parseFloat(tokens);
          tokens = Math.trunc(tokens * power) / power;
          setTempStakeTitle("NFT Staking");
          setTempStakeAddress(item['stakeAddress']);
          setTempStakeAmount(parseFloat(tokens));
          setTempStakeYears(parseInt(1));
          titleArray[0] = "NFT Staking"
          beneficiaryArray[0] = item['stakeAddress']
          amountArray[0] = parseFloat(tokens)
          durationArray[0] = parseInt(1);
          setTitleArray([...titleArray]);
          setBeneficiaryArray([...beneficiaryArray]);
          setAmountArray([...amountArray]);
          setDurationArray([...durationArray]);
          return { ...item };
        }
        return item;
      })
    );
  }

  useEffect(() => {
    if (props.stakingList.length > 0 && tokenAddress !== "" && web3) {
      setStakeData();
    }
  }, [props.stakingList, props.selectDefaultNetwok, tokenAddress, web3]);

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "tokenAddress") {
      if (e.target.value == "") {
        settokenAddress("");
        settokenName("");
        settokenSymbol("");
        settokenSupply("");
        setTempStakeTitle("");
        setTempStakeAddress("");
        setTempStakeAmount("");
        setTempStakeYears("");
        setCount(0);
        setFormValues([""]);
        setTitleArray([]);
        setBeneficiaryArray([]);
        setAmountArray([]);
        setDurationArray([]);
        return;
      }
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        settokenAddress(result);
        await waitFor(1500);
        if (!validate(result, "ETH")) {
          settokenName("");
          settokenSymbol("");
          settokenSupply("");
          EventBus.publish("error", `Please provide valid token address`);
          return;
        }
        var code = await web3.eth.getCode(result.toLowerCase());
        if (code === "0x") {
          settokenName("");
          settokenSymbol("");
          settokenSupply("");
          EventBus.publish("error", `Invalid token address!`);
          return;
        }
        let contract = new web3.eth.Contract(XRC20ABI, result);
        let name = await contract.methods.name().call();
        let symbol = await contract.methods.symbol().call();
        let tokenSupply = await contract.methods.tokenSupply().call();
        tokenSupply = web3.utils.fromWei(tokenSupply, 'ether');
        settokenName(name);
        settokenSymbol(symbol);
        settokenSupply(tokenSupply);
      } else {
        settokenAddress(e.target.value);
        await waitFor(1500);
        if (!validate(e.target.value, "ETH")) {
          settokenName("");
          settokenSymbol("");
          settokenSupply("");
          EventBus.publish("error", `Please provide valid token address`);
          return;
        }
        var code = await web3.eth.getCode(e.target.value.toLowerCase());
        if (code === "0x") {
          settokenName("");
          settokenSymbol("");
          settokenSupply("");
          EventBus.publish("error", `Invalid token address!`);
          return;
        }
        let contract = new web3.eth.Contract(XRC20ABI, e.target.value);
        let name = await contract.methods.name().call();
        let symbol = await contract.methods.symbol().call();
        let tokenSupply = await contract.methods.tokenSupply().call();
        tokenSupply = web3.utils.fromWei(tokenSupply, 'ether');
        settokenName(name);
        settokenSymbol(symbol);
        settokenSupply(tokenSupply);
      }
    } else if ([e.target.name] == "publicsale") {
      setPublicsale(parseFloat(e.target.value));
    } else if ([e.target.name] == "privatesale") {
      setPrivatesale(parseFloat(e.target.value));
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function deployVesting(e) {
    try {
      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      // if (props.kybStatus !== "approved") {
      //   EventBus.publish("error", `Submit KYB verification to access this function!`);
      //   return;
      // }

      if (tokenAddress == "") {
        EventBus.publish("error", `Please enter token name`);
        return;
      }

      if (!validate(tokenAddress, "ETH")) {
        EventBus.publish("error", `Please provide valid token address`);
        return;
      }

      var code = await web3.eth.getCode(tokenAddress.toLowerCase());
      if (code === "0x") {
        EventBus.publish("error", `Invalid token address!`);
        return;
      }

      if (privatesale == "") {
        EventBus.publish("error", `Please enter private sale tokens`);
        return;
      }

      if (privatesale <= 0) {
        EventBus.publish("error", `Please enter private sale tokens`);
        return;
      }

      if (publicsale == "") {
        EventBus.publish("error", `Please enter public sale tokens`);
        return;
      }

      if (publicsale <= 0) {
        EventBus.publish("error", `Please enter public sale tokens`);
        return;
      }

      let amountArray1 = [];

      let durationArray1 = [];

      titleArray.map((items) => {
        if (items == "") {
          EventBus.publish("error", `Vesting title missing!`);
          return;
        }

        if (!items.replace(/\s/g, '').length) {
          EventBus.publish("error", `Vesting title missing!`);
          return;
        }

        if (!items.match(/[a-zA-Z]/)) {
          EventBus.publish("error", `Vesting title must contain alphabets!`);
          return;
        }
      })

      beneficiaryArray.map((items) => {
        if (items == "") {
          EventBus.publish("error", `Vesting address missing!`);
          return;
        }

        if (!items.replace(/\s/g, '').length) {
          EventBus.publish("error", `Vesting address missing!`);
          return;
        }

        if (!validate(items, "ETH")) {
          EventBus.publish("error", `Please provide valid vesting address!`);
          return;
        }
      })

      amountArray.map((items) => {
        if (items == "") {
          EventBus.publish("error", `Vesting amount missing!`);
          return;
        }

        if (parseFloat(items) <= 0) {
          EventBus.publish("error", `Vesting amount cannot be zero!`);
          return;
        }

        count = parseFloat(count) + parseFloat(items)
        setCount(count);

        items = web3.utils.toWei(items.toString(), "ether");

        items = items.toString();

        amountArray1.push(items);
      });


      if ((parseFloat(count) + parseFloat(privatesale) + parseFloat(publicsale)) < parseFloat(tokenSupply)) {
        EventBus.publish("error", `Token allocation is less than total supply!`);
        return;
      }


      if ((parseFloat(count) + parseFloat(privatesale) + parseFloat(publicsale)) > parseFloat(tokenSupply)) {
        EventBus.publish("error", `Token allocation is greater than total supply!`);
        return;
      }

      // Check if the array includes the number 0
      let includesZero = durationArray.includes(0);

      // Check if the array includes the string "0"
      let includesStringZero = durationArray.includes("0");

      if (includesZero === true || includesStringZero === true) {
        EventBus.publish("error", `Vesting duration cannot be zero!`);
        setCount(0);
        return;
      }

      durationArray.map((items) => {
        if (items == "") {
          EventBus.publish("error", `Vesting duration missing!`);
          setCount(0);
          return;
        }

        if (parseInt(items) <= 0) {
          EventBus.publish("error", `Vesting duration cannot be zero!`);
          setCount(0);
          return;
        }

        if (parseInt(items) == NaN) {
          EventBus.publish("error", `Vesting duration cannot be zero!`);
          setCount(0);
          return;
        }

        items = parseInt(items);

        durationArray1.push(items);
      })

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        setCount(0);
        return;
      }

      privatesale = web3.utils.toWei(privatesale.toString(), "ether");

      publicsale = web3.utils.toWei(publicsale.toString(), "ether");

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      props.setLoader({
        message: "Deployment in Progress...",
        status: true,
      });

      let contract = new web3.eth.Contract(XRC20VestingABI);
      let deploy = await contract.deploy({ data: XRC20VestingBytecode, arguments: [tokenAddress, titleArray, beneficiaryArray, amountArray1, durationArray1] });

      await deploy.send({ from: deployer })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({
            message: "Contract Deploy...",
            status: false,
          });

          setDeployedAddress(receipt['contractAddress']);

          await props.deployVestings({ tokenName, tokenSymbol, network: networkId, vestings: titleArray.length, owner: deployer, tokenAddress, vestingAddress: receipt['contractAddress'], presaleAmount: web3.utils.fromWei(privatesale.toString(), "ether"), publicAmount: web3.utils.fromWei(publicsale.toString(), "ether") });

          EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);

          await refreshBalance();

          waitFor(1500);
          setSuccessModal(true);
          settokenName("");
          settokenSymbol("");
          settokenSupply("");
          settokenAddress("");
          setPrivatesale("");
          setPublicsale("");
          setTempStakeTitle("");
          setTempStakeAddress("");
          setTempStakeAmount("");
          setTempStakeYears("");
          setFormValues([""]);
          setTitleArray([""]);
          setBeneficiaryArray([""]);
          setAmountArray([""]);
          setDurationArray([""]);
          console.log(`************** deploy contract address = `, receipt['contractAddress'])
        });
    } catch (e) {
      console.log(e);
      setCount(0);
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Deploy`);
    }
  }

  async function copiedAddress() {
    EventBus.publish("success", "Contract Address Copied");
  }

  async function addFormFields() {
    setFormValues([...formValues, ""]);
  }

  async function handleTitle(i, e) {
    titleArray[i] = e.target.value;
    setTitleArray([...titleArray])
  }

  async function handleBeneficiary(i, e) {
    let input = e.target.value;
    let output = input.substring(0, 3); // checks first three char of address
    if (output == "xdc") {
      let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
      beneficiaryArray[i] = result;
      setBeneficiaryArray([...beneficiaryArray])
    } else {
      beneficiaryArray[i] = e.target.value;
      setBeneficiaryArray([...beneficiaryArray])
    }
  }

  async function handleAmount(i, e) {
    amountArray[i] = e.target.value;
    setAmountArray([...amountArray]);
    setCount(0);
  }

  async function handleDuration(i, e) {
    if (tempStakeYears !== "") {
      if (i == 0) {
        setTempStakeYears(e.target.value);
        if (parseInt(e.target.value) !== 0) {
          tempStakeAmount = tempStakeAmount * e.target.value;
        }
        setTempStakeAmount(tempStakeAmount);
        amountArray[0] = tempStakeAmount;
        setAmountArray([...amountArray]);
      }
      else {
        durationArray[i] = e.target.value;
        setDurationArray([...durationArray])
      }
    } else {
      durationArray[i] = e.target.value;
      setDurationArray([...durationArray])
    }
  }

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {
          (tokenAddress !== "" || titleArray.length > 0 || beneficiaryArray.length > 0 || amountArray.length > 0 || durationArray.length > 0) &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="form-wrap vesting-schedule">
        <div className="inner">
          <h2>Vesting Schedule</h2>

          {
            deployedAddress !== "" &&
            <>
              <div className="copy-wrap">
                <p>
                  <CopyToClipboard
                    text={deployedAddress}
                    onCopy={copiedAddress}
                  >
                    {networkId == 137 ? (
                      <a href={`https://polygonscan.com/address/k${deployedAddress}`} target="_blank">
                        {`Vesting Address:`}
                        <span> {deployedAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 80002 ? (
                      <a href={`https://www.oklink.com/amoy/address/${deployedAddress}`} target="_blank">
                        {`Vesting Address:`}
                        <span> {deployedAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 56 ? (
                      <a href={`https://bscscan.com/address/${deployedAddress}`} target="_blank">
                        {`Vesting Address:`}
                        <span> {deployedAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 97 ? (
                      <a href={`https://testnet.bscscan.com/address/${deployedAddress}`} target="_blank">
                        {`Vesting Address:`}
                        <span> {deployedAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 50 ? (
                      <a href={`https://xdc.blocksscan.io/address/${deployedAddress}`} target="_blank">
                        {`Vesting Address:`}
                        <span> {deployedAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : networkId == 51 ? (
                      <a href={`https://apothem.blocksscan.io/address/${deployedAddress}`} target="_blank">
                        {`Vesting Address:`}
                        <span> {deployedAddress}<img className="go-arrow" src={goarrow} alt="Go Arrow" /></span>
                      </a>
                    ) : (
                      <></>
                    )}
                  </CopyToClipboard>
                </p>

              </div>
              <p className="mb-4">You can visit (Vesting Schedules) page to view your token vesting</p>
            </>
          }

          <form onSubmit={deployVesting}>

            <div className="form-head mb-3">
              <h4>{tokenName == "" ? "Token Name" : tokenName} {tokenSymbol}</h4>

              <span>Total supply :  {tokenSupply == "" ? "0" : tokenSupply}</span>
            </div>

            <div className="form-group">
              <input
                type="text"
                name='tokenAddress'
                value={tokenAddress}
                onChange={handleOnInput}
                maxlength="43"
                placeholder="Token Address*"
              />

              <div className="info-wrap name-notice-btn">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Token Address Here</p>
                </div>
              </div>
            </div>

            <div className="form-group">
              < input
                onWheel={handleOnWheel}
                type="number"
                name='privatesale'
                value={privatesale}
                onChange={handleOnInput}
                placeholder='Private Sale Amount'
              />

              <div className="info-wrap">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Private Sale Tokens Here</p>
                </div>
              </div>
            </div>

            <div className="form-group">
              < input
                onWheel={handleOnWheel}
                type="number"
                name='publicsale'
                value={publicsale}
                onChange={handleOnInput}
                placeholder='Public Sale Amount'
              />

              <div className="info-wrap name-notice-btn">
                <img src={infocircle} alt="" />

                <div className="info">

                  <p>Enter Public Sale Tokens Here</p>
                </div>
              </div>
            </div>

            <br />

            {
              <>
                {formValues.map((element, index) => (
                  <>
                    {
                      tempStakeTitle !== "" ?
                        <div className="field-wrap">
                          <div className="form-group">
                            < input
                              type="text"
                              name='title'
                              defaultValue={index == 0 ? tempStakeTitle : titleArray[index]}
                              maxlength="25"
                              placeholder='Title*'
                              onChange={e => handleTitle(index, e)}
                            />

                            <div className="info-wrap name-notice-btn">
                              <img src={infocircle} alt="" />

                              <div className="info">

                                <p>Enter Title Here</p>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            < input
                              type="text"
                              name='walletaddress'
                              defaultValue={index == 0 ? tempStakeAddress : beneficiaryArray[index]}
                              maxlength="43"
                              placeholder='Wallet Address*'
                              onChange={e => handleBeneficiary(index, e)}
                            />

                            <div className="info-wrap name-notice-btn">
                              <img src={infocircle} alt="" />

                              <div className="info">

                                <p>Enter Your Wallet Address Here</p>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            < input
                              onWheel={handleOnWheel}
                              type="number"
                              name='vestingAmount'
                              defaultValue={index == 0 ? tempStakeAmount : amountArray[index]}
                              placeholder='Vesting Amount'
                              onChange={e => handleAmount(index, e)}
                            />

                            <div className="info-wrap name-notice-btn">
                              <img src={infocircle} alt="" />

                              <div className="info">

                                <p>Enter Vesting Amount Here</p>
                              </div>
                            </div>

                          </div>

                          <div className="form-group">
                            < input
                              onWheel={handleOnWheel}
                              type="number"
                              name='vestingyears'
                              defaultValue={index == 0 ? tempStakeYears : durationArray[index]}
                              placeholder='Vesting in years'
                              onChange={e => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseInt(inputValue) == inputValue) {
                                  handleDuration(index, e)
                                }
                                else {
                                  EventBus.publish("info", "Cannot accept decimal in number of Years")
                                  durationArray[index] = "";
                                  setDurationArray([...durationArray]);
                                }
                              }}
                            />

                            <div className="info-wrap name-notice-btn">
                              <img src={infocircle} alt="" />

                              <div className="info">

                                <p>Enter Vesting Years Here</p>
                              </div>
                            </div>

                          </div>

                          <br />
                        </div>
                        :
                        <div className="field-wrap">
                          <div className="form-group">
                            < input
                              type="text"
                              name='title'
                              value={titleArray[index]}
                              maxlength="25"
                              placeholder='Title*'
                              onChange={e => handleTitle(index, e)}
                            />

                            <div className="info-wrap name-notice-btn">
                              <img src={infocircle} alt="" />

                              <div className="info">

                                <p>Enter Title Here</p>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            < input
                              type="text"
                              name='walletaddress'
                              value={beneficiaryArray[index]}
                              maxlength="43"
                              placeholder='Wallet Address*'
                              onChange={e => handleBeneficiary(index, e)}
                            />

                            <div className="info-wrap name-notice-btn">
                              <img src={infocircle} alt="" />

                              <div className="info">

                                <p>Enter Your Wallet Address Here</p>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            < input
                              onWheel={handleOnWheel}
                              type="number"
                              name='vestingAmount'
                              value={amountArray[index]}
                              placeholder='Vesting Amount'
                              onChange={e => handleAmount(index, e)}
                            />

                            <div className="info-wrap name-notice-btn">
                              <img src={infocircle} alt="" />

                              <div className="info">

                                <p>Enter Vesting Amount Here</p>
                              </div>
                            </div>

                          </div>

                          <div className="form-group">
                            < input
                              onWheel={handleOnWheel}
                              type="number"
                              name='vestingyears'
                              value={durationArray[index]}
                              placeholder='Vesting in years'
                              onChange={e => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || parseInt(inputValue) == inputValue) {
                                  handleDuration(index, e)
                                }
                                else {
                                  EventBus.publish("info", "Cannot accept decimal in number of Years")
                                  durationArray[index] = "";
                                  setDurationArray([...durationArray]);
                                }
                              }}
                            />

                            <div className="info-wrap name-notice-btn">
                              <img src={infocircle} alt="" />

                              <div className="info">

                                <p>Enter Vesting Years Here</p>
                              </div>
                            </div>

                          </div>

                          <br />
                        </div>
                    }
                  </>
                ))}

                <div className="add-more" onClick={(event) => {
                  event.preventDefault();
                  addFormFields();
                }}>
                  + Add more
                </div>
              </>
            }

            <button className="mt-5" type="submit">
              Create Vesting Schedule
            </button>
          </form>
        </div>
      </div>

      <Modal
        open={successModal}
        onClose={() => setSuccessModal(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setSuccessModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <p>Visit your <span>Profile</span> under <span>Micro economy</span> to mint tokens in vesting contract</p>
          </div>

          <img src={tokengenerationstepinfo} alt="" />

        </div>
      </Modal>

      <Modal
        open={stepModal}
        onClose={() => setStepModal(false)}
        classNames={{
          overlay: 'custom-overlay',
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal steps-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setStepModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="steps-head">
            <p>Visit <span>Vesting Schedules</span> under <span>Micro economy</span> to view your deployed vesting.</p>
          </div>

          <img src={vestingstepinfo} alt="" />

        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  deployVestings,
  setBalance,
  setGrootBalance,
  getStaking
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, currentMode, isLoader, stakingList, kycStatus, kybStatus } = Auth;
  return { publicAddress, currentMode, isLoader, stakingList, kycStatus, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeployVesting);
