import moment from "moment";
import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import ConnectWallet from "../ConnectWallet";
import { useParams } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import { validate } from "wallet-address-validator";
import modalcloseicon from "../../images/close.png";
import uploadicon from "../../images/uploadicon.png";
import { toFixed } from "./../../store/numberFormat";
import infocircle from "../../images/infocircle.svg";
import DaoGenesislogo from "../../images/dao-genesis.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import votedfillgreen from "../../images/voted-fill-green.png";
import rejectedfillred from "../../images/rejected-fill-red.png";
import microeconomyicon from "../../images/microeconomy-icon.png";
import { XRC20ABI, networkId, DaoABI, DaoMultiSigABI, DaoMultiSigBytecode, CIFI } from "../../store/config";
import { web3 } from '../../store/web3';
import { setLoader, getProposals, addProposal, getDAO, setBalance, setGrootBalance, sendEmailDao } from "../../store/actions/Auth";


const SubmitProposal = (props, { mode }) => {

  let [dEnd, setdEnd] = useState("");
  let [daoData, setDaoData] = useState({});
  let [bSymbol, setBSymbol] = useState("");
  let [proposalObj, setProposalObj] = useState({});
  let [proposalInfo, setProposalInfo] = useState({});
  let [proposalTitle, setproposalTitle] = useState("");
  let [releaseAddress, setReleaseAddress] = useState("");
  let [liquidityAmount, setLiquidityAmount] = useState("");
  let [proposalDetails, setproposalDetails] = useState([]);
  let [proposalDocument, setproposalDocument] = useState("");
  let [submitLiquidity, setSubmitLiquidity] = useState(false);
  let [minimumAllowOwners, setMinimumAllowOwners] = useState("");
  let [addMultiSignature, setAddMultiSignature] = useState(false);
  let [proposalDescription, setproposalDescription] = useState("");
  let [submitXDCLiquidity, setSubmitXDCLiquidity] = useState(false);
  let [createProposalModal, setCreateProposalModal] = useState(false);
  let [multiSignatureAddress, setMultiSignatureAddress] = useState("");
  let [submitTokenLiquidity, setSubmitTokenLiquidity] = useState(true);
  let [executeProposalModal, setExecuteProposalModal] = useState(false);
  let [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  let [proposalInfoModal, setProposalInfoModal] = useState(false);
  let [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));
  let [modalData, setModalData] = useState({});

  const [model, setModel] = useState(true);

  let { daoAddress } = useParams();

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);

  useEffect(() => {
    props.getDAO();
    props.getProposals({ daoAddress: daoAddress });
  }, []);

  useEffect(() => {
    if (networkId == 80002 || networkId == 137) {
      setBSymbol("MATIC");
    } else if (networkId == 97 || networkId == 56) {
      setBSymbol("BNB");
    } else if (networkId == 51 || networkId == 50) {
      setBSymbol("XDC");
    }
  }, [networkId]);

  useEffect(() => {
    // Update timestamp every second
    const intervalId = setInterval(() => {
      setCurrentTime(Math.floor(Date.now() / 1000));
    }, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  async function setDaoList() {
    const filtered = await Promise.all(
      props.daoList.filter(item => item['daoAddress'].toLowerCase() == daoAddress.toLowerCase()).map(async (item) => {
        return item;
      })
    );
    if (filtered.length > 0) {
      let stakeholders = filtered[0]['stakeholders'].map(item => item.toLowerCase());
      let contract = new web3.eth.Contract(DaoABI, daoAddress);
      let onlyOwner = await contract.methods.proposalOwner().call({ from: props.publicAddress });
      setDaoData({ ...filtered[0], stakeholders, onlyOwner });
    }
  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      if (props.daoList.length > 0) {
        setDaoList();
      }
    } else {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    }
  }, [props.daoList, props.publicAddress, props.selectDefaultNetwok, web3]);

  useEffect(() => {
    if (props.publicAddress && web3) {
      proposalsPopulate();
    } else {
      return;
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    if (props.publicAddress && web3 && props.proposalList) {
      proposalsPopulate();
    } else {
      return;
    }
  }, [props.proposalList, props.publicAddress, web3]);

  async function proposalsPopulate() {
    let { proposalList } = props;
    let array = [];
    let contract = new web3.eth.Contract(DaoABI, daoAddress);
    let result1 = await contract.methods.numProposals().call({ from: props.publicAddress });
    if (parseInt(result1) !== 0) {
      for (let i = 0; i < result1; i++) {
        let result2 = await contract.methods.proposals(i).call({ from: props.publicAddress });
        const updatedCollections = await Promise.all(
          proposalList.filter(item => item['proposalTitle'].toLowerCase() == result2.title.toLowerCase().toLowerCase()).map(async (item) => {
            return {...item, ...result2};
          })
        );
        array.push(...updatedCollections)
      }
      setproposalDetails(array);
    }
  }

  async function handleOnInput(e) {
    const waitFor = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    if ([e.target.name] == "proposalTitle") {
      setproposalTitle(e.target.value);
    } else if ([e.target.name] == "proposalDescription") {
      setproposalDescription(e.target.value);
    } else if ([e.target.name] == "proposalDocument") {
      setproposalDocument(e.target.files[0]);
    } else if ([e.target.name] == "dEnd") {
      setdEnd(e.target.value);
    } else if ([e.target.name] == "minimumAllowOwners") {
      if (parseInt(e.target.value) > 0) {
        setMinimumAllowOwners(parseInt(e.target.value));
      } else {
        setMinimumAllowOwners("");
      }
    } else if ([e.target.name] == "addMultiSignature") {
      setAddMultiSignature(!addMultiSignature);
    } else if ([e.target.name] == "submitLiquidity") {
      setSubmitLiquidity(!submitLiquidity);
    } else if ([e.target.name] == "submitXDCLiquidity") {
      setSubmitXDCLiquidity(!submitXDCLiquidity);
      setSubmitTokenLiquidity(!submitTokenLiquidity);
    } else if ([e.target.name] == "liquidityAmount") {
      if (parseFloat(e.target.value) > 0) {
        setLiquidityAmount(parseFloat(e.target.value));
      } else {
        setLiquidityAmount("");
      }
    } else if ([e.target.name] == "releaseAddress") {
      let input = e.target.value;
      let output = input.substring(0, 3); // checks first three char of address
      if (output == "xdc") {
        let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
        setReleaseAddress(result);
      } else {
        setReleaseAddress(e.target.value);
      }
    } else if ([e.target.name] == "multiSignatureAddress") {
      setMultiSignatureAddress(e.target.value);
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function createProposal(e) {
    try {

      e.preventDefault();

      const waitFor = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let { publicAddress } = props;

      if (proposalTitle == "") {
        EventBus.publish("error", `Please enter proposal title`);
        return;
      }

      if (!proposalTitle.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter proposal title`);
        return;
      }

      if (!proposalTitle.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Proposal title must contain alphabets`);
        return;
      }

      if (proposalDescription == "") {
        EventBus.publish("error", `Please enter proposal description`);
        return;
      }

      if (!proposalDescription.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter proposal description`);
        return;
      }

      if (!proposalDescription.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Proposal description must contain alphabets`);
        return;
      }

      if (proposalDocument == undefined) {
        EventBus.publish("error", `Please upload proposal document again or refresh the page`);
        return;
      }

      if (dEnd == "") {
        EventBus.publish("error", `Please enter proposal deadline`);
        return;
      }

      let deployer = (await web3.currentProvider.enable())[0];

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }


      if (addMultiSignature == true) {
        if (minimumAllowOwners == "") {
          EventBus.publish("error", `Please enter number of minimum approvals required to execute proposal`);
          return;
        }

        if (releaseAddress == "") {
          EventBus.publish("error", `Please enter funds release address`);
          return;
        }

        if (releaseAddress !== "") {
          if (!releaseAddress.replace(/\s/g, '').length) {
            EventBus.publish("error", `Please enter funds release address`);
            return;
          }

          if (!validate(releaseAddress, "ETH")) {
            EventBus.publish("error", `Please provide valid funds release address`);
            return;
          }
        }

        if (Object.keys(daoData).length > 0 && daoData['stakeholders'] && daoData['stakeholders'].length == 0) {
          EventBus.publish("error", `Could not fetch shareholders for multiSignature vault`);
          return;
        }

        if (Object.keys(daoData).length > 0 && daoData['tokenAddress'] && daoData['tokenAddress'] == "") {
          EventBus.publish("error", `Could not fetch crypto token for multiSignature vault`);
          return;
        }

        props.setLoader({
          message: "Vault Deployment in Progress...",
          status: true,
        });

        let contract = new web3.eth.Contract(DaoMultiSigABI);
        let deploy = await contract.deploy({ data: DaoMultiSigBytecode, arguments: [daoData['stakeholders'], minimumAllowOwners, releaseAddress, daoData['tokenAddress'], submitTokenLiquidity] });
        await deploy.send({ from: deployer })
          .on('receipt', async receipt => {
            props.setLoader({
              message: "Vault Deployed...",
              status: false,
            });

            multiSignatureAddress = receipt['contractAddress'];
            setMultiSignatureAddress(receipt['contractAddress']);
            EventBus.publish("success", `Deployed: ${receipt['contractAddress']}`);
            console.log(`************** deploy contract address = `, receipt['contractAddress'])
          });
      }

      let contract = new web3.eth.Contract(DaoABI, daoAddress);
      let deadline = parseInt((new Date(dEnd).getTime()) / 1000);

      props.setLoader({
        message: "Adding Proposal...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: daoAddress,
          gas: 5000000,
          data: contract.methods
            .createProposal(proposalTitle, proposalDescription, deadline)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {

          let data = new FormData();

          data.append('proposalTitle', proposalTitle);
          data.append('proposalDescription', proposalDescription);
          data.append('deadline', deadline);
          data.append('multiSignatureAddress', multiSignatureAddress);
          data.append('releaseAddress', releaseAddress);
          data.append('proposalDocument', proposalDocument);
          data.append('daoAddress', daoAddress);

          await props.addProposal(data);

          if (addMultiSignature == false) {
            props.sendEmailDao({
              daoAddress: daoAddress, emailSubject: `New Proposal Created in ${daoData['title']}`, proposalTitle: "Dear User",
              proposalDescription: `A new proposal ${proposalTitle} has been created within ${daoData['title']}. Proposal details are as following:<br><br/>
              Title: ${proposalTitle}<br><br/>Description: ${proposalDescription}<br><br/>We encourage your active participation in the decision-making process.<br><br/>
              Please head to the DAO platform to review the details and cast your vote. Your input is instrumental in shaping the direction of DAO, and we value your engagement.` });
          } else {
            props.sendEmailDao({
              daoAddress: daoAddress, emailSubject: `New Proposal Created in ${daoData['title']}, Action Required: Sign Transaction for Proposal Execution`, proposalTitle: "Dear User",
              proposalDescription: `A new proposal ${proposalTitle} has been created within ${daoData['title']} that requires your attention. Your signature on the transaction is 
              needed to finalize and execute this proposal. Proposal details are as following:<br><br/>Title: ${proposalTitle}<br><br/>Description: ${proposalDescription}
              <br><br/>We encourage your active participation in the decision-making process.<br><br/>Please head to the DAO platform to review the details and cast your vote. Please be 
              prepared to sign the transaction at its scheduled execution DATE. Your prompt action ensures the smooth progress of proposals within the CiFi DAO ecosystem.` });
          }

          if (submitLiquidity == true) {
            if (submitXDCLiquidity == true) {

              let balanceWei = await web3.eth.getBalance(deployer);
              let liquidityAmountWei = web3.utils.toWei(liquidityAmount.toString(), 'ether');
              if (parseInt(liquidityAmountWei) > parseInt(balanceWei)) {
                return EventBus.publish("error", `Insufficient ${bSymbol} to add liquidity`);
              } else {
                props.setLoader({
                  message: "Adding Liquidity...",
                  status: true,
                });
                await web3.eth
                  .sendTransaction({
                    from: deployer,
                    to: multiSignatureAddress,
                    gas: 5000000,
                    value: liquidityAmountWei
                  })
                  .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                  .on('receipt', async receipt => {
                    props.setLoader({ status: false });
                  });
              }
            } else {
              let contractToken = new web3.eth.Contract(XRC20ABI, daoData['tokenAddress']);
              let balanceWei = await contractToken.methods.balanceOf(deployer).call();
              let symbol = await contractToken.methods.symbol().call();
              let liquidityAmountWei = web3.utils.toWei(liquidityAmount.toString(), 'ether');
              if (parseInt(liquidityAmountWei) > parseInt(balanceWei)) {
                return EventBus.publish("error", `Insufficient ${symbol} to add liquidity`);
              } else {
                props.setLoader({
                  message: "Approving Tokens...",
                  status: true,
                });
                await contractToken.methods.approve(multiSignatureAddress, liquidityAmountWei).send({ from: deployer }).then(console.log)
                props.setLoader({
                  message: "Adding Liquidity...",
                  status: true,
                });
                await contractToken.methods.transfer(multiSignatureAddress, liquidityAmountWei).send({ from: deployer }).then(console.log)
              }
            }
          }

          refreshBalance();
          props.getProposals({ daoAddress: daoAddress });
          proposalsPopulate();
          setDaoList();
          props.setLoader({ status: false });

          EventBus.publish("success", `Proposal Created Successfully!`);
          await waitFor(2500);
          setAddMultiSignature(false);
          setdEnd("");
          setproposalDocument("");
          setproposalTitle("");
          setReleaseAddress("");
          setMinimumAllowOwners("");
          setproposalDescription("");
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To add proposal`);
    }
  }

  async function vote(index) {
    try {

      let { publicAddress } = props;
      let deployer = (await web3.currentProvider.enable())[0];

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let contract = new web3.eth.Contract(DaoABI, daoAddress);

      props.setLoader({
        message: "Voting...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: daoAddress,
          gas: 5000000,
          data: contract.methods
            .voteOnProposal(index, true)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({ status: false });
          proposalsPopulate();
          EventBus.publish("success", `Voted Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Could not vote...",
        status: false,
      });
      EventBus.publish("error", `Could not vote`);
    }
  }

  async function against(index) {
    try {

      let { publicAddress } = props;

      let deployer = (await web3.currentProvider.enable())[0];

      let from = publicAddress;
      let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        from = "0x" + publicAddress.substring(3);
      } else {
        from = publicAddress;
      }

      let contract = new web3.eth.Contract(DaoABI, daoAddress);

      props.setLoader({
        message: "Voting...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          to: daoAddress,
          gas: 5000000,
          data: contract.methods
            .voteOnProposal(index, false)
            .encodeABI(),
        })
        .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
        .on('receipt', async receipt => {
          props.setLoader({ status: false });
          proposalsPopulate();
          EventBus.publish("success", `Voted Successfully!`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Could not vote...",
        status: false,
      });
      EventBus.publish("error", `Could not vote`);
    }
  }

  async function execute(index) {
    try {

      await setDaoList();
      let deployer = (await web3.currentProvider.enable())[0];

      props.setLoader({
        message: "Loading...",
        status: true,
      });

      let includeMultiSig = props.proposalList[index].hasOwnProperty('multiSignatureAddress');

      if (includeMultiSig == true && props.proposalList[index]['multiSignatureAddress'] !== "") {
        let vaultAddress = props.proposalList[index]['multiSignatureAddress'];
        let contract = new web3.eth.Contract(DaoMultiSigABI, vaultAddress);
        let totalGovernors = await contract.methods.totalAllowOwners().call({ from: deployer });
        let signedGovernors = await contract.methods.getCountAllowOwners().call({ from: deployer });
        let hasSigned = await contract.methods.checkHaveApproved().call({ from: deployer });
        let minimumReq = await contract.methods.minimumAllowOwnersForTransactions().call({ from: deployer });
        let balance = await contract.methods.getTokenBalance().call({ from: deployer });
        let isbalanceToken = await contract.methods.isbalanceToken().call({ from: deployer });
        let contract2 = new web3.eth.Contract(XRC20ABI, daoData['tokenAddress']);
        let tokenSymbol = await contract2.methods.symbol().call({ from: deployer });
        balance = web3.utils.fromWei(balance.toString(), "ether");
        balance = parseFloat(balance);
        setProposalObj({ totalGovernors, signedGovernors, hasSigned, minimumReq, balance, isbalanceToken, tokenSymbol, ...props.proposalList[index], ...proposalDetails[index] });
        setModalData({ totalGovernors, signedGovernors, hasSigned, minimumReq, balance, isbalanceToken, tokenSymbol, ...props.proposalList[index], ...proposalDetails[index] });
      } else {
        setProposalObj({ ...props.proposalList[index], ...proposalDetails[index] });
        setModalData({ ...props.proposalList[index], ...proposalDetails[index] });
      }

      props.setLoader({
        message: "Loading...",
        status: false,
      });
      setExecuteProposalModal(true);
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Loading...",
        status: false,
      });
      EventBus.publish("error", `Could not execute`);
    }
  }

  async function signTransaction() {
    try {

      let deployer = (await web3.currentProvider.enable())[0];

      props.setLoader({
        message: "Signing...",
        status: true,
      });

      let vaultAddress = proposalObj['multiSignatureAddress'];
      let contract = new web3.eth.Contract(DaoMultiSigABI, vaultAddress);
      let hasSigned = await contract.methods.checkHaveApproved().call({ from: deployer });
      if (!hasSigned) {
        await web3.eth
          .sendTransaction({
            from: deployer,
            to: vaultAddress,
            gas: 5000000,
            data: contract.methods
              .changeApprovelStatus(true)
              .encodeABI(),
          })
          .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
          .on('receipt', async receipt => {
            props.setLoader({ status: false });
            proposalsPopulate();
            setExecuteProposalModal(false);
            EventBus.publish("success", `Signed Successfully!`);
          });
      }
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Loading...",
        status: false,
      });
      EventBus.publish("error", `Could not execute`);
    }
  }

  async function executeProposal() {
    try {

      let deployer = (await web3.currentProvider.enable())[0];

      if (proposalObj.hasOwnProperty('multiSignatureAddress') == true && proposalObj['multiSignatureAddress'] !== "") {
        let vaultAddress = proposalObj['multiSignatureAddress'];
        let contract = new web3.eth.Contract(DaoMultiSigABI, vaultAddress);
        let signedGovernors = await contract.methods.getCountAllowOwners().call({ from: deployer });
        signedGovernors = parseInt(signedGovernors);
        let minimumReq = await contract.methods.minimumAllowOwnersForTransactions().call({ from: deployer });
        minimumReq = parseInt(minimumReq);
        if (signedGovernors >= minimumReq) {
          props.setLoader({
            message: "Transfering Funds...",
            status: true,
          });
          await web3.eth
            .sendTransaction({
              from: deployer,
              to: vaultAddress,
              gas: 5000000,
              data: contract.methods
                .transferTokensWithAllowOwners()
                .encodeABI(),
            })
            .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
            .on('receipt', async receipt => {
              console.log("******* receipt", receipt);
              props.setLoader({ status: false });


              /////////////////////////////////////////////////////////////////////////////////////////////////

              props.setLoader({
                message: "Executing...",
                status: true,
              });
              let contract1 = new web3.eth.Contract(DaoABI, daoAddress);
              let index = parseInt(modalData['id']) - 1;
              await web3.eth
                .sendTransaction({
                  from: deployer,
                  to: daoAddress,
                  gas: 5000000,
                  data: contract1.methods
                    .executeProposal(index, true)
                    .encodeABI(),
                })
                .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
                .on('receipt', async receipt => {
                  props.setLoader({ status: false });
                  props.sendEmailDao({
                    daoAddress: daoAddress, emailSubject: `Congratulations! Proposal Executed Successfully`, proposalTitle: "Dear User",
                    proposalDescription: `We're pleased to inform you that the recent proposal ${proposalObj['proposalTitle']} in your DAO has been successfully 
                    executed. Your involvement and contribution played a significant role in shaping the outcome.<br><br/>Thank you for actively participating in 
                    the governance of DAO. We appreciate your ongoing dedication to our community.` });
                  proposalsPopulate();
                  setExecuteProposalModal(false);
                  EventBus.publish("success", `Executed Successfully!`);
                });
            });
        } else {
          props.setLoader({
            message: "Executing...",
            status: false,
          });
          EventBus.publish("error", `Minimum required approvals not reached yet!`);
          return;
        }
      } else {
        props.setLoader({
          message: "Executing...",
          status: true,
        });
        let contract1 = new web3.eth.Contract(DaoABI, daoAddress);
        let index = parseInt(modalData['id']) - 1;
        await web3.eth
          .sendTransaction({
            from: deployer,
            to: daoAddress,
            gas: 5000000,
            data: contract1.methods
              .executeProposal(index, true)
              .encodeABI(),
          })
          .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
          .on('receipt', async receipt => {
            props.setLoader({ status: false });
            props.sendEmailDao({
              daoAddress: daoAddress, emailSubject: `Congratulations! Proposal Executed Successfully`, proposalTitle: "Dear User",
              proposalDescription: `We're pleased to inform you that the recent proposal ${proposalObj['proposalTitle']} in your DAO has been successfully 
              executed. Your involvement and contribution played a significant role in shaping the outcome.<br><br/>Thank you for actively participating in 
              the governance of DAO. We appreciate your ongoing dedication to our community.` });
            proposalsPopulate()
            setExecuteProposalModal(false);
            EventBus.publish("success", `Executed Successfully!`);
          });
      }
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Loading...",
        status: false,
      });
      EventBus.publish("error", `Could not execute`);
    }
  }

  async function rejectProposal() {
    try {

      let deployer = (await web3.currentProvider.enable())[0];

      props.setLoader({
        message: "Transfering Funds...",
        status: true,
      });

      if (proposalObj.hasOwnProperty('multiSignatureAddress') == true && proposalObj['multiSignatureAddress'] !== "" && parseFloat(proposalObj['balance']) > 0) {
        let vaultAddress = proposalObj['multiSignatureAddress'];
        let contract = new web3.eth.Contract(DaoMultiSigABI, vaultAddress);
        await web3.eth
          .sendTransaction({
            from: deployer,
            to: vaultAddress,
            gas: 5000000,
            data: contract.methods
              .fallbackTransferTokens()
              .encodeABI(),
          })
          .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
          .on('receipt', async receipt => {
            console.log("******* receipt", receipt);
            props.setLoader({ status: false });


            /////////////////////////////////////////////////////////////////////////////////////////////////

            props.setLoader({
              message: "Executing...",
              status: true,
            });
            let contract1 = new web3.eth.Contract(DaoABI, daoAddress);
            let index = parseInt(modalData['id']) - 1;
            await web3.eth
              .sendTransaction({
                from: deployer,
                to: daoAddress,
                gas: 5000000,
                data: contract1.methods
                  .executeProposal(index, true)
                  .encodeABI(),
              })
              .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
              .on('receipt', async receipt => {
                props.setLoader({ status: false });
                proposalsPopulate();
                setExecuteProposalModal(false);
                EventBus.publish("success", `Executed Successfully!`);
              });
          });
      } else {
        props.setLoader({
          message: "Executing...",
          status: true,
        });
        let contract1 = new web3.eth.Contract(DaoABI, daoAddress);
        let index = parseInt(modalData['id']) - 1;
        await web3.eth
          .sendTransaction({
            from: deployer,
            to: daoAddress,
            gas: 5000000,
            data: contract1.methods
              .executeProposal(index, true)
              .encodeABI(),
          })
          .on('transactionHash', hash => console.log(`************** deploy dao contract hash = ${hash}`))
          .on('receipt', async receipt => {
            props.setLoader({ status: false });
            proposalsPopulate();
            setExecuteProposalModal(false);
            EventBus.publish("success", `Executed Successfully!`);
          });
      }
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Loading...",
        status: false,
      });
      EventBus.publish("error", `Could not execute`);
    }
  }

  async function copiedAddress() {
    EventBus.publish("success", "Vault Address Copied");
  }

  async function proposalInformation(item) {
    setProposalInfo(item);
    setProposalInfoModal(true);
  }


  // console.log("*** proposalDetails :: ", proposalDetails);
  return (
    <div className="microeconomy-wrapper dao-genesis view-all submit-proposal">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          {/* <img src={DaoGenesislogo} alt="" /> */}
          <span>DAO Genesis</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {(dEnd !== "" || proposalTitle !== "" || proposalDetails !== "" || proposalDescription !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="microeconomy-tool">
        <h2>Proposals<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

        <div className="detail-row">
          <div className="filters">
            {
              Object.keys(daoData).length > 0 && daoData['stakeholders'] && daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) ?
                <div className="btns">
                  <button onClick={() => setCreateProposalModal(true)}>Create a proposal</button>
                </div> :
                <></>
            }
          </div>

          <h2>All Proposals</h2>

          <div className="table-wrapper proposals-table">
            <table responsive="md">
              <thead>
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Expiry date</th>
                  <th>Status</th>
                  <th>Vote count</th>
                  <th>Vote</th>
                  {
                    Object.keys(daoData).length > 0 && daoData['stakeholders'] && daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) ?
                      <th>Action</th> :
                      <></>
                  }
                </tr>
              </thead>
              <tbody>
                {
                  proposalDetails?.length > 0 &&
                  proposalDetails?.map((item, index) => (
                    <tr>
                      <td><img onClick={() => proposalInformation(item)} className="info-edit" src={infocircle} alt="" /></td>
                      <td>{item.proposalTitle}</td>
                      <td>{moment(Number(item.deadline) * 1000).format('L hh:mm:ss A')}</td>
                      {
                        (currentTime) < parseInt(item?.deadline)
                          ?
                          <td className="status green">Active</td>
                          :
                          <td className="status">Expired</td>
                      }
                      <td className="vote-count">
                        <p>
                          <img src={votedfillgreen} alt="" />
                          {
                            parseInt(item?.voteCount) > 0 ?
                              toFixed(((parseInt(item?.yayVotes) / parseInt(item?.voteCount)) * 100), 'price')
                              : 0
                          }% voted
                        </p>

                        <p>
                          <img src={rejectedfillred} alt="" />

                          {
                            parseInt(item?.voteCount) > 0 ?
                              toFixed(((parseInt(item?.nayVotes) / parseInt(item?.voteCount)) * 100), 'price')
                              : 0
                          }% rejected
                        </p>
                      </td>
                      <td className="vote-reject">
                        <p className="vote">
                          {
                            (currentTime) < parseInt(item?.deadline) ?
                              <button onClick={() => vote(index)}>Approve</button> :
                              <button disabled>Approve</button>
                          }
                        </p>

                        <p className="reject">
                          {
                            (currentTime) < parseInt(item?.deadline) ?
                              <button onClick={() => against(index)}>Reject</button> :
                              <button disabled>Reject</button>
                          }
                        </p>
                      </td>
                      {
                        (currentTime) > parseInt(item?.deadline) && Object.keys(daoData).length > 0 && daoData['stakeholders'] && daoData['stakeholders'].includes(props.publicAddress.toLowerCase()) ?
                          <td className="action">
                            <button onClick={() => execute(index)} className="btns">Execute</button>
                          </td> :
                          <></>
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={microeconomyicon} alt="" />

          <h3>Micro Economy Tool</h3>

          <p>Empower your project with our revolutionary platform, designed to navigate the blockchain world effortlessly. Deploy NFTs, airdrops, tokens, and smart contracts even with limited resources or expertise.</p>
        </div>
      </Modal>

      <Modal
        open={createProposalModal}
        onClose={() => setCreateProposalModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal create-proposal-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setCreateProposalModal(false)}
          />
        </button>

        <div className="modal-body">
          <div className="form-wrap">
            <div className="head">
              {
                multiSignatureAddress !== "" &&
                <>
                  <p>
                    <CopyToClipboard
                      text={multiSignatureAddress}
                      onCopy={copiedAddress}
                    >
                      <a>{`Multi Signature Vault Address: ${multiSignatureAddress}`}</a>
                    </CopyToClipboard>
                  </p>
                </>
              }
              <h3>New Proposal
              </h3>
            </div>

            <form onSubmit={createProposal}>
              <div className="form-group">
                <input
                  type="text"
                  name='proposalTitle'
                  value={proposalTitle}
                  onChange={handleOnInput}
                  placeholder="Proposal Title*"
                />
              </div>

              <div className="form-group">
                <textarea
                  as="textarea"
                  rows={6}
                  name='proposalDescription'
                  value={proposalDescription}
                  onChange={handleOnInput}
                  placeholder="Proposal detail"
                />
              </div>

              <div className="form-group filewrap">
                <span>{proposalDocument ? (proposalDocument['name'] && proposalDocument['name'].substring(0, 10) + '...') : "Proposal document"}</span>
                <div className="upload-btn"><img src={uploadicon} alt="Upload File Icon" />Upload PDF File</div>
                <input
                  type="file"
                  name='proposalDocument'
                  placeholder={proposalDocument ? (proposalDocument['name'] && proposalDocument['name'].substring(0, 10) + '...') : "Upload PDF File*"}
                  accept=".pdf"
                  onChange={handleOnInput}
                />
              </div>

              <div className="form-group date-wrap">
                <label>Expiry Time</label>
                <input
                  type="datetime-local"
                  placeholder="End date"
                  name="dEnd"
                  value={dEnd}
                  onInput={handleOnInput}
                />
              </div>

              <div className="form-group  checkbox-wrapper">
                <input
                  type="checkbox"
                  id="multi-signature-wallet"
                  checked={addMultiSignature}
                  name="addMultiSignature"
                  value={addMultiSignature}
                  onChange={handleOnInput}
                />
                <label for="multi-signature-wallet">Add multi signature wallet</label>
              </div>

              {
                addMultiSignature == true &&
                <>
                  <div className="form-group">
                    {
                      minimumAllowOwners !== "" ?
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          name='minimumAllowOwners'
                          value={minimumAllowOwners}
                          placeholder={minimumAllowOwners}
                          onChange={handleOnInput}
                        /> :
                        <input
                          onWheel={handleOnWheel}
                          type="number"
                          name='minimumAllowOwners'
                          value={minimumAllowOwners}
                          placeholder="Minimum allowed owners"
                          onChange={handleOnInput}
                        />
                    }
                    <br />
                    <br />
                    {
                      releaseAddress !== "" ?
                        <input
                          type="text"
                          name='releaseAddress'
                          value={releaseAddress}
                          onChange={handleOnInput}
                          placeholder={releaseAddress}
                          maxlength="43"
                        /> :
                        <input
                          type="text"
                          name='releaseAddress'
                          value={releaseAddress}
                          onChange={handleOnInput}
                          placeholder="Funds Release Address*"
                          maxlength="43"
                        />
                    }
                  </div>

                  <div className="form-group  checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="submit-liquidity"
                      checked={submitLiquidity}
                      name="submitLiquidity"
                      value={submitLiquidity}
                      onChange={handleOnInput}
                    />
                    <label for="submit-liquidity">Submit proposal liquidity now</label>
                  </div>

                  {
                    submitLiquidity == true &&
                    <>
                      <div className="form-group  checkbox-wrapper">
                        <input
                          type="checkbox"
                          id="submit-xdc-liquidity"
                          checked={submitXDCLiquidity}
                          name="submitXDCLiquidity"
                          value={submitXDCLiquidity}
                          onChange={handleOnInput}
                        />
                        <label for="submit-xdc-liquidity">Submit proposal liquidity in {bSymbol}</label>
                      </div>
                      <div className="form-group">
                        {
                          liquidityAmount !== "" ?
                            <input
                              onWheel={handleOnWheel}
                              type="number"
                              name='liquidityAmount'
                              value={liquidityAmount}
                              placeholder={liquidityAmount}
                              onChange={handleOnInput}
                            /> :
                            <input
                              onWheel={handleOnWheel}
                              type="number"
                              name='liquidityAmount'
                              value={liquidityAmount}
                              placeholder="Amount to be added in proposal vault"
                              onChange={handleOnInput}
                            />
                        }
                      </div>
                    </>
                  }
                </>
              }


              <div className="btns dao-btn">
                <button type="submit">Submit Proposal</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        open={executeProposalModal}
        onClose={() => setExecuteProposalModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal execute-proposal-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setExecuteProposalModal(false)}
          />
        </button>

        <div className="modal-body">
          {
            Object.keys(modalData).length > 0 &&
            <div className="form-wrap">
              <div className="head">
                <h3>{modalData?.['title']}</h3>

                <p>{modalData?.['description']}</p>
              </div>

              <div className="info-box-wrap">
                <div className="info-box">
                  <h4>Balance</h4>

                  {
                    modalData.hasOwnProperty('balance') == true ?
                      <>
                        {
                          modalData?.['isbalanceToken'] ?
                            <p>
                              {`${modalData?.['balance']} ${modalData?.['tokenSymbol']}`}
                            </p>
                            :
                            <p>
                              {`${modalData?.['balance']} ${bSymbol}`}
                            </p>
                        }
                      </>
                      :
                      <p>N/A</p>
                  }
                </div>

                <div className="info-box">
                  <h4>Expiry</h4>

                  <p>{moment(Number(modalData?.['deadline']) * 1000).format('L')}</p>
                </div>

                <div className="info-box">
                  <h4>No of shareholders</h4>

                  {
                    modalData.hasOwnProperty('totalGovernors') == true ?
                      <p>{modalData?.['totalGovernors']}</p>
                      :
                      <p>{daoData['stakeholders'].length}</p>
                  }
                </div>
              </div>
              {
                modalData.hasOwnProperty('multiSignatureAddress') == true && modalData['multiSignatureAddress'] !== "" ?
                  <form>
                    {
                      modalData['executed'] == false ?
                        parseInt(modalData['yayVotes']) !== 0 || parseInt(modalData['nayVotes']) !== 0 ?
                          modalData.hasOwnProperty('hasSigned') == true && parseInt(modalData['yayVotes']) > parseInt(modalData['nayVotes']) ?

                            modalData['hasSigned'] == false ?
                              <>
                                <button onClick={(event) => {
                                  event.preventDefault();
                                  signTransaction();
                                }}>
                                  Sign transaction
                                </button>
                                <label><span>{modalData?.['signedGovernors']}</span>/<span>{modalData?.['totalGovernors']}</span> shareholders signed it</label>
                              </>
                              :
                              <>
                                <button disabled>
                                  Signed
                                </button>
                                <label><span>{modalData?.['signedGovernors']}</span>/<span>{modalData?.['totalGovernors']}</span> shareholders signed it</label>
                              </>
                            :
                            <></>
                          :
                          <>
                            {
                              daoData['stakeholders'][0].toLowerCase() == props.publicAddress.toLowerCase() && modalData['executed'] == false &&
                              <button onClick={(event) => {
                                event.preventDefault();
                                rejectProposal();
                              }}>Conclude proposal</button>
                            }
                            <br />
                            <br />
                            <button disabled>
                              No votes submitted
                            </button>
                          </>
                        :
                        <button disabled>
                          Decision Made
                        </button>
                    }

                    {
                      parseInt(modalData['yayVotes']) > parseInt(modalData['nayVotes']) && daoData['stakeholders'][0].toLowerCase() == props.publicAddress.toLowerCase() &&
                        parseInt(modalData['signedGovernors']) >= parseInt(modalData['minimumReq']) && modalData['executed'] == false ?
                        <button onClick={(event) => {
                          event.preventDefault();
                          executeProposal();
                        }}>Execute proposal</button>
                        : <></>
                    }

                    {
                      parseInt(modalData['yayVotes']) < parseInt(modalData['nayVotes']) && daoData['stakeholders'][0].toLowerCase() == props.publicAddress.toLowerCase() &&
                        modalData['executed'] == false ?
                        <button onClick={(event) => {
                          event.preventDefault();
                          rejectProposal();
                        }}>Reject proposal</button>
                        : <></>
                    }

                  </form>
                  :
                  <form>
                    {
                      modalData['executed'] == false ?
                        parseInt(modalData['yayVotes']) !== 0 || parseInt(modalData['nayVotes']) !== 0 ?
                          daoData['stakeholders'][0].toLowerCase() == props.publicAddress.toLowerCase() && parseInt(modalData['yayVotes']) > parseInt(modalData['nayVotes']) ?
                            <button onClick={(event) => {
                              event.preventDefault();
                              executeProposal();
                            }}>Execute proposal</button>
                            :
                            <button onClick={(event) => {
                              event.preventDefault();
                              rejectProposal();
                            }}>Reject proposal</button>
                          :
                          <>
                            {
                              daoData['stakeholders'][0].toLowerCase() == props.publicAddress.toLowerCase() && modalData['executed'] == false &&
                              <button onClick={(event) => {
                                event.preventDefault();
                                rejectProposal();
                              }}>Conclude proposal</button>
                            }
                            <br />
                            <br />
                            <button disabled>
                              No votes submitted
                            </button>
                          </>
                        :
                        <button disabled>
                          Decision Made
                        </button>
                    }
                  </form>
              }
            </div>
          }
        </div>
      </Modal>

      <Modal
        open={proposalInfoModal}
        onClose={() => setProposalInfoModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal proposal-info-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setProposalInfoModal(false)}
          />
        </button>

        <div className="modal-body">

          <h3>{proposalInfo['proposalTitle']}</h3>
          <p>{proposalInfo['proposalDescription']}</p>
          {
            proposalInfo['proposalDocument'] && proposalInfo['proposalDocument'] !== "" &&
            <p>
              <a href={proposalInfo['proposalDocument']} target="_blank">View Document</a>
            </p>
          }
          <span>{moment(Number(proposalInfo.deadline) * 1000).format('L hh:mm:ss A')}</span>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  getProposals,
  addProposal,
  getDAO,
  setBalance,
  setGrootBalance,
  sendEmailDao
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, proposalList, daoList, currentMode, isLoader } = Auth;
  return { publicAddress, proposalList, daoList, currentMode, isLoader }
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitProposal);