import React, { useEffect, useState } from "react";
import EventBus from "eventing-bus";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import stakenft from "../../images/stakenft.svg";
import createnft from "../../images/createnft.svg";
import modalcloseicon from "../../images/close.png";
import tokenairdrop from "../../images/airdrop.svg";
import infocircle from "../../images/infocircle.svg";
import explorenft from "../../images/explorenft.svg";
import deploytoken from "../../images/deploytoken.svg";
import stakegeneration from "../../images/stakegeneration.svg";
import microeconomyicon from "../../images/microeconomy-icon.png";
import microeconomylogo from "../../images/microeconomy-icon.png";
import { getKycStatus, getKybStatus, setLoader } from "../../store/actions/Auth";


function Microeconomy(props) {

  const [microeconomyToolModal, setMicroeconomyToolModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.publicAddress) {
      props.setLoader({ message: "Load Microeconomy...", status: true });
      props.getKybStatus({ publicAddress: props.publicAddress });
      props.getKycStatus({ publicAddress: props.publicAddress });
    }
  }, [props.publicAddress]);

  useEffect(() => {
    // let getKYCLocally = localStorage.getItem("kycStatus");
    // let getKYBLocally = localStorage.getItem("kybStatus");
    // if(getKYCLocally && getKYBLocally){
    // if(props.kycStatus == false && props.kybStatus == "submitted") return navigate("/dashboard/microeconomy/verification");
    // else if(props.kycStatus == false && props.kybStatus == "false") return navigate("/dashboard/microeconomy/verification");
    // else if(props.kycStatus == "created" && props.kybStatus == "false") return navigate("/dashboard/microeconomy/verification");
    // else if(props.kycStatus == "approved" && props.kybStatus == "false") return navigate("/dashboard/microeconomy/verification");
    // else if(props.kycStatus == "created" && props.kybStatus == "submitted") return navigate("/dashboard/microeconomy/verification");
    // else if(props.kycStatus == "approved" && props.kybStatus == "submitted") return navigate("/dashboard/microeconomy/verification");
    // }
  }, [props.kycStatus, props.kybStatus]);

  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />

          <span>Micro Economy</span>
        </a>

        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>
      {/* {
        props.publicAddress ?
          props.kybStatus == "approved" && props.kycStatus == "adminApproved" ?
            <div className="microeconomy-tool">
              <h2>Micro Economy Tool<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>
              <div className="inner">
                <div className="box" href="/microeconomy/deploy-your-token">
                  <Link to="/dashboard/microeconomy/deploy-your-token">
                    <img src={deploytoken} alt="" />

                    <h3>Deploy Your Token</h3>
                  </Link>
                </div>

                <div className="box">
                  <Link to="/dashboard/microeconomy/create-nft">
                    <img src={createnft} alt="" />

                    <h3>Create NFT Collection</h3>
                  </Link>
                </div>

                <div className="box">
                  <Link to="/dashboard/microeconomy/stake-generation">
                    <img src={stakegeneration} alt="" />

                    <h3>Stake Generation</h3>
                  </Link>
                </div>

                <div className="box">
                  <Link to="/dashboard/microeconomy/token-airdrop">
                    <img src={tokenairdrop} alt="" />

                    <h3>Token Airdrop Tool</h3>
                  </Link>
                </div>
                <div className="box">
                  <Link to="/dashboard/microeconomy/stake-nft">
                    <img src={stakenft} alt="" />

                    <h3>Stake Your NFT´s</h3>
                  </Link>
                </div>

                <div className="box">
                  <Link to="/dashboard/microeconomy/nft-drops">
                    <img src={explorenft} alt="" />

                    <h3>Explore NFT Collections</h3>
                  </Link>
                </div>
              </div>
            </div>
            :
            props.kybStatus == "approved" && props.kycStatus !== "adminApproved" ?
              <div className="microeconomy-tool">
                <h2>Micro Economy Tool<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

                <div className="inner">
                  <div className="box" href="/microeconomy/deploy-your-token">
                    <Link to="/dashboard/microeconomy/deploy-your-token">
                      <img src={deploytoken} alt="" />

                      <h3>Deploy Your Token</h3>
                    </Link>
                  </div>

                  <div className="box">
                    <Link to="/dashboard/microeconomy/create-nft">
                      <img src={createnft} alt="" />

                      <h3>Create NFT Collection</h3>
                    </Link>
                  </div>

                  <div className="box">
                    <Link to="/dashboard/microeconomy/stake-generation">
                      <img src={stakegeneration} alt="" />

                      <h3>Stake Generation</h3>
                    </Link>
                  </div>

                  <div className="box">
                    <Link to="/dashboard/microeconomy/token-airdrop">
                      <img src={tokenairdrop} alt="" />

                      <h3>Token Airdrop Tool</h3>
                    </Link>
                  </div>
                  <div className="box">
                    <Link to="/dashboard/microeconomy/nft-drops">
                      <img src={explorenft} alt="" />

                      <h3>Explore NFT Collections</h3>
                    </Link>
                  </div>
                </div>
              </div>
              :
              props.kycStatus == "adminApproved" && props.kybStatus !== "approved" &&
              <div className="microeconomy-tool">
                <h2>Micro Economy Tool<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>

                <div className="inner">
                  <div className="box">
                    <Link to="/dashboard/microeconomy/stake-nft">
                      <img src={stakenft} alt="" />

                      <h3>Stake Your NFT´s</h3>
                    </Link>
                  </div>

                  <div className="box">
                    <Link to="/dashboard/microeconomy/nft-drops">
                      <img src={explorenft} alt="" />

                      <h3>Explore NFT Collections</h3>
                    </Link>
                  </div>
                </div>
              </div>
          :
          <div className="form-wrap min">
            <div className="inner">
              <h2 style={{ textAlign: "center" }}>Wallet Not Connected</h2>
            </div>
          </div>
      } */}
      {
        props.publicAddress ?
          <div className="microeconomy-tool">
            <h2>Micro Economy Tool<button onClick={() => setMicroeconomyToolModal(true)}><img src={infocircle} alt="" /></button></h2>
            <div className="inner">
              <div className="box" href="/microeconomy/deploy-your-token">
                <Link to="/dashboard/microeconomy/deploy-your-token">
                  <img src={deploytoken} alt="" />

                  <h3>Deploy Your Token</h3>
                </Link>
              </div>

              <div className="box">
                <Link to="/dashboard/microeconomy/create-nft">
                  <img src={createnft} alt="" />

                  <h3>Create NFT Collection</h3>
                </Link>
              </div>

              <div className="box">
                <Link to="/dashboard/microeconomy/stake-generation">
                  <img src={stakegeneration} alt="" />

                  <h3>Stake Generation</h3>
                </Link>
              </div>

              <div className="box">
                <Link to="/dashboard/microeconomy/token-airdrop">
                  <img src={tokenairdrop} alt="" />

                  <h3>Token Airdrop Tool</h3>
                </Link>
              </div>
              {/* kyc */}
              <div className="box">
                <Link to="/dashboard/microeconomy/stake-nft">
                  <img src={stakenft} alt="" />

                  <h3>Stake Your NFT´s</h3>
                </Link>
              </div>

              <div className="box">
                <Link to="/dashboard/microeconomy/nft-drops">
                  <img src={explorenft} alt="" />

                  <h3>Explore NFT Collections</h3>
                </Link>
              </div>
            </div>
          </div>
          :
          <div className="form-wrap min">
            <div className="inner">
              <h2 style={{ textAlign: "center" }}>Wallet Not Connected</h2>
              {/* <p className="mb-4 left">Verify your Identity to access the features of Microeconomy.</p> */}
            </div>
          </div>
      }

      <Modal
        open={microeconomyToolModal}
        onClose={() => setMicroeconomyToolModal(false)}
        classNames={{
          modal: `${props.mode ? 'light' : 'dark'} microeconomy-modal`
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setMicroeconomyToolModal(false)}
          />
        </button>

        <div className="modal-body">
          <img src={microeconomyicon} alt="" />

          <h3>Micro Economy Tool</h3>

          <p>Empower your project with our revolutionary platform, designed to navigate the blockchain world effortlessly. Deploy NFTs, airdrops, tokens, and smart contracts even with limited resources or expertise.</p>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = { getKycStatus, setLoader, getKybStatus };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, kycStatus, kybStatus } = Auth;
  return { publicAddress, kycStatus, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(Microeconomy);
