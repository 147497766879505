import Swap from "./Swap";
import Sell from "./Sell";
import Stake from "./Stake";
import Borrow from "./Borrow";
import Trades from "./Trades";
import Loader from "../Loader";
import SideBar from "./Sidebar";
import History from "./History";
import Hidding from "./Hidding";
import Modules from './Modules';
import Verification from './Verification';
import RegisterYourBusiness from './RegisterYourBusiness';
import RegisterYourNonProfit from './RegisterYourNonProfit';
import Profile from './Profile';
import Nftdrops from './Nftdrops';
import ExploreToken from "./ExploreToken";
import VestingSchedules from "./VestingSchedules";
import StakeNft from "./StakeNft";
import StakeLP from "./StakeLP";
import Stability from "./Stability";
import CreateNft from "./CreateNft";
import DaoGenesis from './DaoGenesis';
import LaunchaDoa from './LaunchaDoa';
import LaunchDao from './LaunchDao';
import LaunchDaoSteps from './LaunchDaoSteps';
import { connect } from 'react-redux';
import ExploreNft from "./ExploreNft";
import DashboardNew from './Dashboard';
import DeployToken from "./DeployToken";
import ViewPools from './ViewPools';
import CircularityDao from './CircularityDao';
import CircularityDaoStakeNft from './CircularityDaoStakeNft';
import ViewAllDaos from './ViewAllDaos';
import InitialOffer from "./InitialOffer";
import Microeconomy from "./Microeconomy";
import ProjectDetailSteps from "./ProjectDetailSteps";
import StabilityPool1 from "./StabilityPool1";
import StabilityPool2 from "./StabilityPool2";
import NFTAirdrop from "./NftAirdrop";
import TokenAirdrop from "./TokenAirdrop";
import DeployVesting from "./DeployVesting";
import { useEffect, useState } from "react";
import SubmitProposal from './SubmitProposal';
import StakeGeneration from "./StakeGeneration";
import LPStakeGeneration from "./LPStakeGeneration";
import { setMode, setLoader, getKybStatus, getKycStatus } from "../../store/actions/Auth";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { CIFI, networkId, CiFiAccessNFTAddress, CiFiAccessNFTContract, CiFiAccessNFTABI, AdminWhitelist, AdminWhitelistAddress, AdminWhitelistABI } from "../../store/config";
import { web3 } from '../../store/web3';
// import {usePrompt} from "../use-prompt";

function Dashboard(props) {
  let { isLoader } = props;
  const [mode, setMode] = useState(false);
  const [isActive, setIsActive] = useState(true);
  let [isNFT, setIsNFT] = useState();
  let [isWhitelist, setIsWhitelist] = useState(false);
  // usePrompt("Are you sure ypou want to move other page.", props);
  // usePrompt(confirmationModal ? "Are you sure ypou want to move other page." : false, props);

  const getNFTs = async () => {
    if (CiFiAccessNFTABI && CiFiAccessNFTAddress) {
      props.setLoader({ message: "Load Microeconomy...", status: true });
      let newCiFiAccessNFTContract = new web3.eth.Contract(CiFiAccessNFTABI, CiFiAccessNFTAddress);
      if (newCiFiAccessNFTContract) {
        let tokenBalance = await newCiFiAccessNFTContract.methods.balanceOf(props.publicAddress).call({ from: props.publicAddress });
        // tokenBalance =  1; //tesing purpose
        setIsNFT(tokenBalance);
        if (Number(tokenBalance) >= 1) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Load Microeconomy...", status: false });
      }
    }

    if (AdminWhitelistABI && AdminWhitelistAddress) {
      props.setLoader({ message: "Loading...", status: true });
      let newAdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);
      if (newAdminWhitelist) {
        let status = await newAdminWhitelist.methods.isWhitelist(props.publicAddress).call({ from: props.publicAddress });
        // status =  true; //tesing purpose
        setIsWhitelist(status);
        if (status == true) {
          props.getKybStatus({ publicAddress: props.publicAddress })
          props.getKycStatus({ publicAddress: props.publicAddress })
        }
        else props.setLoader({ message: "Loading...", status: false });
      }
    }

  }

  useEffect(() => {
    if (props.publicAddress && web3) {
      getNFTs();
    }
  }, [props.publicAddress, web3]);

  useEffect(() => {
    if (mode) {
      props.setMode(true);
    }
    else {
      props.setMode(false);
    }
  }, [mode]);



  return (
    <div className={`${mode ? 'light' : 'dark'} main-body relative bg-no-repeat`}>
      {isLoader && <Loader />}
      <div className=" px-0 py-0 customScroll">
        <div className='flex-col items-start sm:flex-row sm:flex'>
          <SideBar isActive={isActive} setIsActive={setIsActive} mode={mode} setMode={setMode} kycStatus={props.kycStatus} kybStatus={props.kybStatus} isWhitelist={isWhitelist} />
          <div className={isActive ? "right-main-content" : "right-main-closed"}>
            {/* {(isWhitelist == true) || (props.kycStatus == "adminApproved" && props.kybStatus == "approved") ?
              <Routes>
                <Route path="" element={<Microeconomy mode={mode} />} />
                <Route path="swap" element={<Swap mode={mode} />} />
                <Route path="view-pools" element={<ViewPools mode={mode} />} />
                <Route path="circularity-dao" element={<CircularityDao mode={mode} />} />
                <Route path="circularity-dao/stake-nft" element={<CircularityDaoStakeNft mode={mode} />} />
                <Route path="dao-genesis/launch-dao" element={<LaunchDao mode={mode} />} />
                <Route path="dao-genesis/launch-dao/steps" element={<LaunchDaoSteps mode={mode} />} />
                <Route path="profile" element={<Profile mode={mode} />} />
                <Route path="microeconomy" element={<Microeconomy mode={mode} />} />
                <Route path="microeconomy/verification" element={<Verification />} />
                <Route path="microeconomy/deploy-your-token" element={<DeployToken />} />
                <Route path="microeconomy/create-nft" element={<CreateNft mode={mode} />} />
                <Route path="microeconomy/stake-generation" element={<StakeGeneration mode={mode} />} />
                <Route path="microeconomy/nft-airdrop" element={<NFTAirdrop mode={mode} />} />
                <Route path="microeconomy/token-airdrop" element={<TokenAirdrop mode={mode} />} />
                <Route path="microeconomy/deploy-vesting" element={<DeployVesting mode={mode} />} />
                <Route path="microeconomy/initial-offer" element={<InitialOffer mode={mode} />} />
                <Route path="microeconomy/nft-drops" element={<Nftdrops mode={mode} />} />
                <Route path="microeconomy/vesting-schedules" element={<VestingSchedules mode={mode} />} />
                <Route path="microeconomy/profile" element={<Profile mode={mode} />} />
                <Route path="microeconomy/explore-nft/:collectionAddress" element={<ExploreNft mode={mode} />} />
                <Route path="microeconomy/stake-nft" element={<StakeNft mode={mode} />} />
                <Route path="microeconomy/sell/:collectionAddress/:tokenId" element={<Sell mode={mode} />} />
                <Route path="dao-genesis" element={<DaoGenesis mode={mode} />} />
                <Route path="dao-genesis/modules" element={<Modules mode={mode} />} />
                <Route path="dao-genesis/launch-daos" element={<LaunchaDoa mode={mode} />} />
                <Route path="dao-genesis/view-daos" element={<ViewAllDaos mode={mode} />} />
                <Route path="dao-genesis/submit-proposal/:daoAddress" element={<SubmitProposal mode={mode} />} />
                <Route path="hidding" element={<Hidding mode={mode} />} />
                <Route path="*" element={<Navigate to="/dashboard/microeconomy/verification" />} />

              </Routes>
              :
              props.kycStatus == "adminApproved" && props.kybStatus !== "approved" ?
                <Routes>
                  <Route path="" element={<Microeconomy mode={mode} />} />
                  <Route path="swap" element={<Swap mode={mode} />} />
                  <Route path="view-pools" element={<ViewPools mode={mode} />} />
                  <Route path="circularity-dao" element={<CircularityDao mode={mode} />} />
                  <Route path="circularity-dao/stake-nft" element={<CircularityDaoStakeNft mode={mode} />} />
                  <Route path="dao-genesis/launch-dao" element={<LaunchDao mode={mode} />} />
                  <Route path="dao-genesis/launch-dao/steps" element={<LaunchDaoSteps mode={mode} />} />
                  <Route path="profile" element={<Profile mode={mode} />} />
                  <Route path="microeconomy" element={<Microeconomy mode={mode} />} />
                  <Route path="microeconomy/verification" element={<Verification />} />
                  <Route path="microeconomy/initial-offer" element={<InitialOffer mode={mode} />} />
                  <Route path="microeconomy/nft-drops" element={<Nftdrops mode={mode} />} />
                  <Route path="microeconomy/vesting-schedules" element={<VestingSchedules mode={mode} />} />
                  <Route path="microeconomy/profile" element={<Profile mode={mode} />} />
                  <Route path="microeconomy/explore-nft/:collectionAddress" element={<ExploreNft mode={mode} />} />
                  <Route path="microeconomy/stake-nft" element={<StakeNft mode={mode} />} />
                  <Route path="microeconomy/sell/:collectionAddress/:tokenId" element={<Sell mode={mode} />} />
                  <Route path="dao-genesis/view-daos" element={<ViewAllDaos mode={mode} />} />
                  <Route path="dao-genesis/submit-proposal/:daoAddress" element={<SubmitProposal mode={mode} />} />
                  <Route path="hidding" element={<Hidding mode={mode} />} />
                  <Route path="*" element={<Navigate to="/dashboard/microeconomy/verification" />} />
                </Routes>
                :
                props.kybStatus == "approved" && props.kycStatus !== "adminApproved" ?
                  <Routes>
                    <Route path="" element={<Microeconomy mode={mode} />} />
                    <Route path="swap" element={<Swap mode={mode} />} />
                    <Route path="view-pools" element={<ViewPools mode={mode} />} />
                    <Route path="circularity-dao" element={<CircularityDao mode={mode} />} />
                    <Route path="circularity-dao/stake-nft" element={<CircularityDaoStakeNft mode={mode} />} />
                    <Route path="dao-genesis/launch-dao" element={<LaunchDao mode={mode} />} />
                    <Route path="dao-genesis/launch-dao/steps" element={<LaunchDaoSteps mode={mode} />} />
                    <Route path="profile" element={<Profile mode={mode} />} />
                    <Route path="microeconomy" element={<Microeconomy mode={mode} />} />
                    <Route path="microeconomy/verification" element={<Verification />} />
                    <Route path="microeconomy/deploy-your-token" element={<DeployToken />} />
                    <Route path="microeconomy/create-nft" element={<CreateNft mode={mode} />} />
                    <Route path="microeconomy/stake-generation" element={<StakeGeneration mode={mode} />} />
                    <Route path="microeconomy/nft-airdrop" element={<NFTAirdrop mode={mode} />} />
                    <Route path="microeconomy/token-airdrop" element={<TokenAirdrop mode={mode} />} />
                    <Route path="microeconomy/deploy-vesting" element={<DeployVesting mode={mode} />} />
                    <Route path="microeconomy/initial-offer" element={<InitialOffer mode={mode} />} />
                    <Route path="microeconomy/nft-drops" element={<Nftdrops mode={mode} />} />
                    <Route path="microeconomy/vesting-schedules" element={<VestingSchedules mode={mode} />} />
                    <Route path="microeconomy/profile" element={<Profile mode={mode} />} />
                    <Route path="microeconomy/explore-nft/:collectionAddress" element={<ExploreNft mode={mode} />} />
                    <Route path="dao-genesis" element={<DaoGenesis mode={mode} />} />
                    <Route path="dao-genesis/modules" element={<Modules mode={mode} />} />
                    <Route path="dao-genesis/launch-daos" element={<LaunchaDoa mode={mode} />} />
                    <Route path="dao-genesis/view-daos" element={<ViewAllDaos mode={mode} />} />
                    <Route path="dao-genesis/submit-proposal/:daoAddress" element={<SubmitProposal mode={mode} />} />
                    <Route path="hidding" element={<Hidding mode={mode} />} />
                    <Route path="*" element={<Navigate to="/dashboard/microeconomy/verification" />} />
                  </Routes>
                  : */}
            <Routes>
              <Route path="" element={<Microeconomy mode={mode} />} />
              <Route path="swap" element={<Swap mode={mode} />} />
              <Route path="view-pools" element={<ViewPools mode={mode} />} />
              <Route path="circularity-dao" element={<CircularityDao mode={mode} />} />
              <Route path="circularity-dao/stake-nft" element={<CircularityDaoStakeNft mode={mode} />} />
              <Route path="dao-genesis/launch-dao" element={<LaunchDao mode={mode} />} />
              <Route path="dao-genesis/launch-dao/steps" element={<LaunchDaoSteps mode={mode} />} />
              <Route path="profile" element={<Profile mode={mode} />} />
              <Route path="microeconomy" element={<Microeconomy mode={mode} />} />
              <Route path="microeconomy/verification" element={<Verification />} />
              <Route path="microeconomy/deploy-your-token" element={<DeployToken />} />
              <Route path="microeconomy/create-nft" element={<CreateNft mode={mode} />} />
              <Route path="microeconomy/stake-generation" element={<StakeGeneration mode={mode} />} />
              <Route path="microeconomy/lp-stake-generation" element={<LPStakeGeneration mode={mode} />} />
              <Route path="microeconomy/nft-airdrop" element={<NFTAirdrop mode={mode} />} />
              <Route path="microeconomy/token-airdrop" element={<TokenAirdrop mode={mode} />} />
              <Route path="microeconomy/deploy-vesting" element={<DeployVesting mode={mode} />} />
              <Route path="microeconomy/initial-offer" element={<InitialOffer mode={mode} />} />
              <Route path="microeconomy/nft-drops" element={<Nftdrops mode={mode} />} />
              <Route path="microeconomy/explore-token" element={<ExploreToken mode={mode} />} />
              <Route path="microeconomy/vesting-schedules" element={<VestingSchedules mode={mode} />} />
              <Route path="microeconomy/profile" element={<Profile mode={mode} />} />
              <Route path="microeconomy/project-detail-steps/:tokenAddress" element={<ProjectDetailSteps mode={mode} />} />
              <Route path="microeconomy/explore-nft/:collectionAddress" element={<ExploreNft mode={mode} />} />
              <Route path="microeconomy/stake-nft" element={<StakeNft mode={mode} />} />
              <Route path="microeconomy/stake-lp" element={<StakeLP mode={mode} />} />
              <Route path="microeconomy/sell/:collectionAddress/:tokenId" element={<Sell mode={mode} />} />
              <Route path="dao-genesis" element={<DaoGenesis mode={mode} />} />
              <Route path="dao-genesis/modules" element={<Modules mode={mode} />} />
              <Route path="dao-genesis/launch-daos" element={<LaunchaDoa mode={mode} />} />
              <Route path="dao-genesis/view-daos" element={<ViewAllDaos mode={mode} />} />
              <Route path="dao-genesis/submit-proposal/:daoAddress" element={<SubmitProposal mode={mode} />} />
              <Route path="liquidity-mining-1" element={<StabilityPool1 mode={mode} />} />
              <Route path="liquidity-mining-2" element={<StabilityPool2 mode={mode} />} />
              <Route path="hidding" element={<Hidding mode={mode} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {/* } */}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  setMode,
  setLoader,
  getKybStatus,
  getKycStatus,
}

const mapStateToProps = ({ Auth }) => {
  let { auth, isLoader, publicAddress, kycStatus, kybStatus } = Auth;
  return { auth, isLoader, publicAddress, kycStatus, kybStatus };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);