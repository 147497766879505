import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import React, { useState, useEffect } from "react";
import liquidityminingicon from "../../images/liquidity-mining.png";
import arrowrightbtn from "../../images/arrow-right.svg";
import rewardcollected from "../../images/reward-pool.png";
import investedpool from "../../images/invested-pool.png";
import lockedpool from "../../images/locked-pool.png";
import { networkId, LiquidityPoolContract1,LiquidityPoolAddress1,SPNFTAddress,SPNFTContract, message } from "../../store/config";
import { setLoader, setSPNFT } from "../../store/actions/Auth";
import Chart from "react-apexcharts";


const StabilityPool = (props, { mode }) => {

  const [model, setModel] = useState(true);
  const [compoundedAmount, setCompoundedAmount] = useState(0);
  const [years, setYears] = useState(0);
  const [totalYears, setTotalYears] = useState([]);
  const [series, setSeries] = useState([]);
  const [chain, setChain] = useState("");


  const [totalXDCInvestment, setTotalXDCInvestment] = useState(0);
  const [totalRewardsCollected, setTotalRewardsCollected] = useState(0);
  const [totalCIFIAmount, setTotalCIFIAmount] = useState(0);
  const [canWithdraw, setCanWithdraw] = useState(false);

  const [XDCAmount, setXDCTokens] = useState(0);

  const handleOnWheel = (event) => {
    const { type } = event.target;
    if (type === 'number') {
      event.preventDefault();
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', handleOnWheel, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleOnWheel);
    };
  }, []);
  
  const options = {
    chart: {
      id: "area"
    },

    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: totalYears,
      // categories: ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6", "Year 7", "Year 8", "Year 9", "Year 10"],

      axisBorder: {
        color: '#282C35',
        height: 2,
        width: '100%'
      },

      labels: {
        style: {
          colors: '#A9A9A9',
          fontSize: '12px',
          fontFamily: 'Quicksand-Medium',
          fontWeight: 400,
        },
      }
    },

    yaxis:{
      categories: [100, 300, 900, 1200, 1500],

      labels: {
        style: {
          colors: '#A9A9A9',
          fontSize: '12px',
          fontFamily: 'Quicksand-Medium',
          fontWeight: 400,
        },

        formatter: function (value) {
          return "Cifi " + value;
        }
      }
    },
 
    fill: {
      colors: ['#5bc3b9'],
      opacity: 1,
      width:8,
      type: 'gradient',

      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.7,
        opacityTo: 0.7,
        stops: [0, 100]
      }
    },

    stroke:{
      colors:['#5bc3b9'],
      fill:['#5bc3b9']
    },

    grid: {
      show: true,
      borderColor: '#282C35',
      strokeDashArray: 6,
      xaxis: {
          lines: {
              show: false
          }
      },    
    },

    plotOptions: {
      bar: {
        columnWidth: '8',
      },
    }
  };

  const getLiquidityPoolData = async () =>{
    let getChain = localStorage.getItem("chain");
    if(getChain == 80002 || getChain == 137) setChain("MATIC")
    if(getChain == 50 || getChain == 51) setChain("XDC")
    // let totalXDCInvestment = await LiquidityPoolContract1.methods.totalXDCInvestment().call();
    let totalXDCInvestment = await web3.eth.getBalance(LiquidityPoolAddress1);
    let totalRewardsCollected = await LiquidityPoolContract1.methods.totalRewardsCollected().call();
    let totalCIFIAmount = await LiquidityPoolContract1.methods.totalCIFIAmount().call();
    // let totalRewardsTokens = await LiquidityPoolContract1.methods.totalRewardsTokens(5000000000000000000).call();
    // console.log("************** totalRewardsTokens",totalRewardsTokens);
    totalXDCInvestment = await web3.utils.fromWei(totalXDCInvestment.toString(), 'ether');
    totalRewardsCollected = await web3.utils.fromWei(totalRewardsCollected.toString(), 'ether');
    totalCIFIAmount = await web3.utils.fromWei(totalCIFIAmount.toString(), 'ether');
    
    let deployer = (await web3.currentProvider.enable())[0];
    let isClaim = await LiquidityPoolContract1.methods.UserPoolData(deployer).call();
    if(isClaim.userAddress.toLowerCase() !== deployer.toLowerCase()) setCanWithdraw(false)
    else setCanWithdraw(true);
    
    setTotalXDCInvestment(totalXDCInvestment);
    setTotalRewardsCollected(totalRewardsCollected);
    setTotalCIFIAmount(totalCIFIAmount);
  }

  useEffect(()=>{
    if(web3 && props.publicAddress){
      // props.setLoader({message:"Load liquidity pool...",status:true});
      getLiquidityPoolData();
    }
  },[web3,props.publicAddress])

  const addLiquidity = async () => {
    try {
      
      if(!props.publicAddress) return  EventBus.publish("error", "Please connect wallet");
      let XDC = await web3.utils.toWei(XDCAmount.toString(), 'ether');
      let deployer = (await web3.currentProvider.enable())[0];
      if(XDC <= 0) return  EventBus.publish("error", "Amount is less then 0");
      
      let balance = await web3.eth.getBalance(deployer);

      if(Number(balance) < Number(XDC)) return  EventBus.publish("error", "Insufficient Amount");

      props.setLoader({message:"Adding Amount in Stability   Pool...",status:true});
      
      await web3.eth
      .sendTransaction({
        from: deployer,
        value: XDC,
        to: LiquidityPoolAddress1,
        gas: 5000000,
        data: LiquidityPoolContract1.methods
          .depositXDC(XDC)
          .encodeABI(),
      })
      .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
      .on('receipt', async receipt => {
        XDC = await web3.utils.fromWei(XDC.toString(), 'ether');
        let tokenId = await SPNFTContract.methods.walletOfOwner(deployer).call();
        tokenId = tokenId[0];
        // props.setSPNFT({ publicAddress: deployer, nftId: tokenId, tokenAddress: LiquidityPoolAddress1.toLowerCase(), totalAmount: XDC });
        getLiquidityPoolData();
        EventBus.publish("success", "Deposit Liquidity Successful!");
        props.setLoader({
          message: "Mint Not Completed...",
          status: false,
        });
      });
    } catch (error) {
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      EventBus.publish("error", error.message);
    }
  }

  const claimTokens = async () => {
    try {
      
      if(!props.publicAddress) return  EventBus.publish("error", "Please connect wallet");
      let deployer = (await web3.currentProvider.enable())[0];
      let isClaim = await LiquidityPoolContract1.methods.isClaim().call({from:deployer});;
      if(parseInt(isClaim) < 30) return EventBus.publish("error", `You can claim after 30 days`);
      props.setLoader({message:"Claim Rewards...",status:true});
      
      await web3.eth
      .sendTransaction({
        from: deployer,
        value: 0,
        to: LiquidityPoolAddress1,
        gas: 5000000,
        data: LiquidityPoolContract1.methods
          .claimRewards()
          .encodeABI(),
      })
      .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
      .on('receipt', async receipt => {
        getLiquidityPoolData();
        props.setLoader({message:"Claim Rewards...",status:false});
        EventBus.publish("success", "Claim rewards successful!");
      });
    } catch (error) {
      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });
      EventBus.publish("error", error.message);
    }
  }

  const Withdraw = async () => {
    try {
      
      if(!props.publicAddress) return  EventBus.publish("error", "Please connect wallet");
      let deployer = (await web3.currentProvider.enable())[0];
      let isClaim = await LiquidityPoolContract1.methods.UserPoolData(deployer).call();
      if(isClaim.userAddress.toLowerCase() !== deployer.toLowerCase()) return EventBus.publish("error", `You have already withdraw`);
      props.setLoader({message:"Withdraw...",status:true});
      
      await web3.eth
      .sendTransaction({
        from: deployer,
        value: 0,
        to: LiquidityPoolAddress1,
        gas: 5000000,
        data: LiquidityPoolContract1.methods
          .withdrawRewards()
          .encodeABI(),
      })
      .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
      .on('receipt', async receipt => {
        getLiquidityPoolData();
        props.setLoader({message:"Withdraw...",status:false});
        EventBus.publish("success", "Withdraw successful!");
      });
    } catch (error) {

      props.setLoader({
        message: "Mint Not Completed...",
        status: false,
      });

      EventBus.publish("error", error.message);
    }
  }

  const unCompound = async (e) => {
    e.preventDefault()
    if(Number(compoundedAmount) <= 0) return EventBus.publish("error", `Amount must be grather then 1`);
    if(Number(years) <= 0) return EventBus.publish("error", `Months must be grather then 1`);
    setSeries([])
    setTotalYears([])
    let dataNew = [];
    let yearsCount = []
    let totalRewards = (compoundedAmount/1000)/30;
    // let calculateMonths = years * 12
    for (let index = 1; index <= years; index++) {
      let yearsDays = 30*index
      let newAmount = totalRewards*yearsDays
      dataNew.push(newAmount.toFixed(3))
      yearsCount.push(`Month ${index}`)
    }
    setSeries(dataNew)
    setTotalYears(yearsCount)
  }

  return (
    <div className="microeconomy-wrapper dao-genesis launch-dao">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={liquidityminingicon} alt="" />
          <span>Liquidity Mining</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        {/* {
          (title !== "" || description !== "" || tokenAddress !== "") &&
          <Prompt when={model}
            message="Leaving page will result in losing details entered!"
            beforeUnload={true}
          />
        } */}
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="stability-boxes-wrap">
        <div className="box">
          <div className="icon-wrap">
            <img src={rewardcollected} alt="" />
          </div>

          <div className="info">
            <span>Total Reward Collected</span>

            <p>{Number(totalRewardsCollected).toFixed(3)} CIFI</p>
          </div>
        </div>

        <div className="box">
          <div className="icon-wrap">
            <img src={investedpool} alt="" />
          </div>

          <div className="info">
            <span>Total Invested Amount</span>

            <p>{Number(totalXDCInvestment).toFixed(3)} {chain}</p>
          </div>
        </div>

        <div className="box">
          <div className="icon-wrap">
            <img src={lockedpool} alt="" />
          </div>

          <div className="info">
            <span>Total CIFI In Pool</span>

            <p>{Number(totalCIFIAmount).toFixed(3)} CIFI</p>
          </div>
        </div>
      </div>

      <div className="liq-cal-wrap">
        <div className="steps-form-wrapper stability-pool">
            <div className="mb-8 mt-4">
            <h3>Add liquidity in stability pool</h3>

            <p>Contribute your XDC tokens to earn CIFI tokens through an un-compounded rewards mechanism. You can enjoy steady and long-term sustainable growth from your assets.</p>
            </div>
            <div className="form-wrap">
                <div className="form-group">
                  <input
                    type="number"
                    name='amount'
                    placeholder={`Enter amount in ${chain}`}
                    value={XDCAmount}
                    onChange={e=>{
                      if(Number(e.target.value) <= -1) setXDCTokens(1);
                      else setXDCTokens(e.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                  <button className="common-btn" onClick={addLiquidity}>Add Liquidity <img src={arrowrightbtn} alt="" /></button>
                </div>
                {canWithdraw &&
                <>
                  <div className="form-group">
                    <button className="common-btn" onClick={claimTokens}>Claim Rewards <img src={arrowrightbtn} alt="" /></button>
                  </div>

                  <div className="form-group">
                    <button className="common-btn" onClick={Withdraw}>Withdraw <img src={arrowrightbtn} alt="" /></button>
                  </div>
                </>
                }
            </div>
        </div>

        <div className="form-wrap">
            <form>
              <h2>Calculator</h2>

              <div className="field-wrap">
                <div className="form-group">
                  <input
                    type="number"
                    placeholder={`Invested Amount ${chain}`}
                    // value={compoundedAmount}
                    onChange={e=>{
                      setCompoundedAmount(e.target.value)
                    }}
                    // onWheel={handleOnWheel}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    placeholder="Months"
                    // value={years}
                    onChange={e=>{
                      setYears(e.target.value)
                    }}
                    // onWheel={handleOnWheel}
                  />
                </div>
              </div>

              {/* <div className="form-group">
                <button type="submit">
                  Compounded Return
                </button>
              </div> */}

              <div className="form-group">
                <button onClick={(e)=>unCompound(e)}>
                  Un-Compounded
                </button>
              </div>
            </form>
        </div>
      </div>
      
      <div className="calculation-chart">
        {/* <div className="toggle-wrap">
            <label>
              Compounded return
              <input type="checkbox" id="backup_set_toggle" />
              <span className="check"></span>
            </label>
        </div> */}
      <Chart options={options} series={[{name:"",data:series}]} type="area" height={250} />
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  setSPNFT
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, isLoader } = Auth;
  return { publicAddress, isLoader }
};

export default connect(mapStateToProps, mapDispatchToProps)(StabilityPool);