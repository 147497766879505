import moment from "moment";
import Prompt from "../use-prompt";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import copy from "../../images/copy.png";
import { web3 } from "../../store/web3";
import ConnectWallet from "../ConnectWallet";
import { Modal } from "react-responsive-modal";
import goarrow from "../../images/go-arrow.png";
import React, { useState, useEffect } from 'react';
import alternate from "../../images/alternate.jpg";
import { toFixed } from "../../store/numberFormat";
import modalcloseicon from "../../images/close.png";
import tickcircle from "../../images/tickcircle.png";
import infocircle from "../../images/infocircle.svg";
import explorenft from "../../images/explorenft.png";
import nationmonkey from "../../images/nation-monkey.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import mintstepinfo from "../../images/mint-step-info.png";
import collectionprofile from "../../images/collection-profile.png";
import microeconomylogo from "../../images/microeconomy-icon.png";
import { networkId, XRC20VestingABI, Groot, CIFI } from "../../store/config";
import collectionimagestepinfo from "../../images/collection-image-step-info.png";
import { setLoader, getVesting, setBalance, setGrootBalance } from "../../store/actions/Auth";


const VestingSchedules = (props, { mode }) => {

  const [txHash, setTxHash] = useState("");
  const [from, setFrom] = useState("");
  const [vestings, setVestings] = useState([]);
  const [data, setData] = useState({});

  const [vestingDetails, setVestingDetailsModal] = useState(false);
  const [stepModal1, setStepModal1] = useState(true);
  const [stepModal2, setStepModal2] = useState(false);
  const [model, setModel] = useState(true);

  const navigate = useNavigate()



  setTimeout(() => {
    const modalRoot = document.querySelector('.react-responsive-modal-root');

    if (modalRoot) {
      //Add a custom class to the root element
      const stepsModal = modalRoot.querySelector('.steps-modal');

      if (stepsModal) {
        // Perform your action here
        modalRoot.classList.add('custom-modal-root');
      }

    } else {
      console.error("Element not found.");
    }
  }, 1000);

  useEffect(() => {
    props.getVesting();
    if (props.publicAddress == null || props.publicAddress == undefined) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    } else {
      let address = props.publicAddress;
      let output = props.publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
      if (output == "xdc") {
        address = "0x" + props.publicAddress.substring(3);
        setFrom(address);
      } else {
        address = props.publicAddress;
        setFrom(address);
      }
    }
  }, [props.publicAddress]);

  async function setVestingData() {
    const updatedVestings = await Promise.all(
      props.vestinglist.filter(a=>parseInt(a.network) == parseInt(props.selectDefaultNetwok)).map(async (item) => {
        if (parseInt(item['network']) == parseInt(props.selectDefaultNetwok)) {
          return { ...item };
        }
        return item;
      })
    );
    setVestings(updatedVestings);
  }

  useEffect(() => {
    if (props.publicAddress  && web3) {
      if (props.vestinglist.length > 0) {
        setVestingData();
      }
    } else {
      return;
    }
  }, [props.vestinglist, props.selectDefaultNetwok, props.publicAddress, web3]);

  async function setInstance(item) {
    if (XRC20VestingABI.length == 0) {
      EventBus.publish("error", `Please connect your wallet!`);
      return;
    } else {
      setVestingDetailsModal(true);
      if (parseInt(item['network']) == parseInt(props.selectDefaultNetwok)) {
        let vestingSchedule = [];
        let contract = new web3.eth.Contract(XRC20VestingABI, item['vestingAddress']);
        let balance = await contract.methods.getTokenBalance().call();
        let vestingLength = item['vestings'] - 1;
        for (let i = 0; i <= vestingLength; i++) {
          let vestingInstance = await contract.methods.vestingSchedules(i).call();
          vestingSchedule.push(vestingInstance);
        }
        balance = web3.utils.fromWei(balance.toString(), 'ether');
        // item['presaleAmount'] = web3.utils.fromWei(item['presaleAmount'].toString(), 'ether');
        // item['publicAmount'] = web3.utils.fromWei(item['publicAmount'].toString(), 'ether');
        item['presaleAmount'] = item['presaleAmount'];
        item['publicAmount'] = item['publicAmount'];
        item['vestingSchedule'] = vestingSchedule;
        item['balance'] = balance;
        setData(item);
      }
    }
  }

  async function refreshBalance() {
    let address = (await web3.currentProvider.enable())[0];
    let balance = await web3.eth.getBalance(address); //Will give value in.
    let tokenBalance = await CIFI.methods.balanceOf(address).call({ from: address });
    tokenBalance = await web3.utils.fromWei(tokenBalance.toString(), "ether");
    balance = await web3.utils.fromWei(balance.toString(), "ether");
    let precision = 3;
    let power = Math.pow(10, precision);
    balance = parseFloat(balance);
    balance = Math.trunc(balance * power) / power;
    props.setBalance(balance);
    tokenBalance = parseFloat(tokenBalance);
    tokenBalance = Math.trunc(tokenBalance * power) / power;
    props.setGrootBalance(tokenBalance);
  }

  async function releaseTokens(idx) {

    try {

      let { publicAddress } = props;

      if (publicAddress == null || publicAddress == undefined) {
        EventBus.publish("error", `Please connect your wallet!`);
        return;
      }

      if (publicAddress.toLowerCase() !== data['owner'].toLowerCase()) {
        EventBus.publish("error", `Only owner can perform this action!`);
        return;
      }

      // if (props.kybStatus !== "approved") {
      //   EventBus.publish("error", `Submit KYB verification to access this function!`);
      //   return;
      // }

      let contract = new web3.eth.Contract(XRC20VestingABI, data['vestingAddress']);

      let deployer = (await web3.currentProvider.enable())[0];

      const balanceWei = await web3.eth.getBalance(deployer);
      const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
      if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

      props.setLoader({
        message: "Token release in Progress...",
        status: true,
      });

      await web3.eth
        .sendTransaction({
          from: deployer,
          value: 0,
          to: data['vestingAddress'],
          gas: 5000000,
          data: contract.methods
            .releaseTokens(idx)
            .encodeABI(),
        })
        .on('transactionHash', hash => {
          setTxHash(hash);
          console.log(`************** deploy contract hash = ${hash}`)
        })
        .on('receipt', async receipt => {
          props.setLoader({
            message: "Token release in Progress...",
            status: false,
          });
          await refreshBalance();
          setVestingDetailsModal(false);
          EventBus.publish("success", `Tokens released successfully`);
        });
    } catch (e) {
      console.log(e);
      props.setLoader({
        message: "Release Not Completed...",
        status: false,
      });
      setVestingDetailsModal(false);
      setData({});
      EventBus.publish("error", `Token release failed!`);
    }
  }

  async function copiedAddress() {
    EventBus.publish("success", "Transaction hash copied");
  }

  const handleCopyText = (item) => {
    navigator.clipboard.writeText(item)
      .then(() => EventBus.publish("success", "Beneficiary address copied"))
      .catch((error) => console.log('Copy failed:', error));
  };

  const handleImageError = (idx) => {
    // Set the failed image to the fallback image
    vestings[idx]['tokenBanner'] = alternate;
    setVestings([...vestings]);
  };


  return (
    <div className="microeconomy-wrapper">
      <div className="microeconomy-head">
        <a href="#" className="logo-wrap">
          <img src={microeconomylogo} alt="" />
          <span>Micro Economy</span>
        </a>
        {props.isLoader.status == true &&
          <Prompt when={model}
            message="Transaction in progress, leaving page may result in transaction failure!"
            beforeUnload={true}
          />
        }
        <a className="buy-vip-nft" target="_blank" href="https://ds71bwxydlvgx.cloudfront.net/">Buy Cifi NFTs</a>
        <ConnectWallet />
      </div>

      <div className="nft-collection">
        <div className="head">
          <h2>Vesting Schedules</h2>
        </div>

        <div className="collection-wrap">
          {
            vestings.length > 0 &&
            vestings.map((item, idx) => (
              <div className="box">
                <Link className="">
                  <img key={idx} src={item['tokenBanner'] ? item['tokenBanner'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                </Link>

                <div className="content">
                  <div className="info">
                    <div className="profile-info">
                      {/* <img src={nationmonkey} alt="" /> */}

                      <div>
                        <h4>{item['tokenName']}</h4>

                        <p>{item['tokenSymbol']}</p>
                      </div>
                    </div>

                    <button className="common-btn" onClick={() => setInstance(item)}>Details</button>
                  </div>

                  {/* <p className="collection-bottom-info mt-2">
                    <span>Vesting Balance</span>

                    <span>{item['balance']} {item['tokenSymbol']}</span>
                  </p> */}
                </div>
              </div>
            ))
          }
        </div>
      </div>

      <Modal
        open={vestingDetails}
        onClose={() => setVestingDetailsModal(false)}
        classNames={{
          modal: `${props.currentMode ? 'light' : 'dark'} microeconomy-modal vesting-modal`,
        }}
        center
      >
        <button className="absolute right-4 top-6">
          <img
            src={modalcloseicon}
            onClick={() => setVestingDetailsModal(false)}
          />
        </button>

        {
          Object.keys(data).length > 0 &&
          <div className="modal-body">
            <h3>Vesting Details</h3>

            {
              txHash !== "" &&
              <>
                <p>
                  <CopyToClipboard
                    text={txHash}
                    onCopy={copiedAddress}
                  >
                    <a>{`Vesting Address: ${txHash}`}</a>
                  </CopyToClipboard>
                </p>
              </>
            }

            <div className="vesting-details mt-7">
              <div className="info">
                <p>{data['tokenName']}</p>

                <span>{data['tokenSymbol']}</span>
              </div>

              <div className="info">
                <p>Vesting balance</p>

                <span>{data['balance']} {data['tokenSymbol']}</span>
              </div>

              <div className="info">
                <p>Presale amount</p>

                <span>{data['presaleAmount']} {data['tokenSymbol']}</span>
              </div>

              <div className="info">
                <p>Public sale amount</p>

                <span>{data['publicAmount']} {data['tokenSymbol']}</span>
              </div>
              <br />

              {
                data['vestingSchedule'].map((item, idx) => (
                  <>
                    <div className="info">
                      <p>Vesting title</p>

                      <span>{item['title']}</span>
                    </div>

                    <div className="info">
                      <p>Vesting address</p>

                      <span><img src={copy} onClick={() => handleCopyText(item['beneficiary'])} />{item['beneficiary'].substring(0, 5) + '...' + item['beneficiary'].substring(37, item['beneficiary'].length)}</span>
                    </div>

                    <div className="info">
                      <p>Vesting amount</p>

                      <span>{web3.utils.fromWei(item['amount'].toString(), 'ether')}</span>
                    </div>

                    <div className="info">
                      <p>Claimed amount</p>

                      <span>{toFixed(web3.utils.fromWei(item['tokensClaimed'].toString(), 'ether'), 'price')}</span>
                    </div>

                    <div className="info">
                      <p>Monthly vesting amount</p>

                      <span>{toFixed((parseInt(web3.utils.fromWei(item['amount'].toString(), 'ether') / parseInt(item['duration'])) / 12), 'price')}</span>
                    </div>

                    <div className="info">
                      <p>Last vesting time</p>

                      <span>{moment.utc(parseInt(item['lastClaim']) * 1000).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                    </div>

                    {
                      parseInt(Date.now() / 1000) > (parseInt(item['lastClaim']) + 86400) &&
                      props.publicAddress.toLowerCase() == data['owner'].toLowerCase() &&
                      <button className="vesting-btn" onClick={() => releaseTokens(idx)}>Release Vesting</button>
                    }

                    <br />
                  </>
                ))
              }

            </div>
          </div>
        }

      </Modal>
    </div >
  );
}

const mapDispatchToProps = {
  setLoader,
  getVesting,
  setBalance,
  setGrootBalance
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, vestinglist, selectDefaultNetwok, currentMode, isLoader,kycStatus, kybStatus } = Auth;
  return { publicAddress, vestinglist, selectDefaultNetwok, currentMode, isLoader,kycStatus, kybStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(VestingSchedules);